import { createRouter, createWebHistory } from 'vue-router'
import axios from 'axios'
import { apiGet } from './api'
import jwt_decode from 'jwt-decode'

import NotFound from './components/NotFound'
import Login from './components/Login'
import ForgotPassword from './components/ForgotPassword'
import ResetPassword from './components/ResetPassword'

import Top from './components/Top'
import PlaceDetail from './components/PlaceDetail/PlaceDetail'
import PlaceSetting from './components/PlaceDetail/PlaceSetting'
import PlaceAlertEmailSetting from './components/PlaceDetail/AlertEmailSetting'
import DeviceConfigBaseUnit from './components/DeviceConfig/BaseUnit'
import DeviceConfigExtensionUnit from './components/DeviceConfig/ExtensionUnit'
import LogDownload from './components/LogDownload/LogDownload'

import ScheduleTop from './components/Schedule/Top'
import ScheduleSetting from './components/Schedule/Setting'
import ScheduleCopy from './components/Schedule/Copy'

import EditAccount from './components/EditAccount/EditAccount'
import EditAccountAlertEmailSetting from './components/EditAccount/AlertEmailSetting'
import EditBaseUnit from './components/EditBaseUnit'
import EditCompany from './components/EditCompany'
import EditFacility from './components/EditFacility'

import AdminTop from './components/Admin/AdminTop'
import CompanyMaster from './components/Admin/CompanyMaster'
import EditCompanyMaster from './components/Admin/EditCompanyMaster'
import Help from './components/Help'
import Inquiry from './components/Inquiry'
import TermOfUse from './components/TermOfUse'
import PrivacyPolicy from './components/PrivacyPolicy'

const router = createRouter({
  history: createWebHistory(),
  routes: [
    { path: '/', redirect: '/top' },
    { path: '/:notFound(.*)', component: NotFound },
    { name: 'Login', path: '/login', component: Login },
    { name: 'ForgotPassword', path: '/forgot_password', component: ForgotPassword },
    { name: 'ResetPassword', path: '/reset_password', component: ResetPassword },

    { name: 'Top', path: '/top', component: Top, beforeEnter: requireAdmin},
    { name: 'PlaceDetail',  path: '/place_detail', component: PlaceDetail, beforeEnter: requireAuth},
    { name: 'PlaceSetting',  path: '/place_setting', component: PlaceSetting, beforeEnter: requireAdmin},
    { name: 'PlaceAlertEmailSetting',  path: '/place_setting/alert_email', component: PlaceAlertEmailSetting, beforeEnter: requireAdmin},
    { name: 'DeviceConfigBaseUnit', path: '/device_config/base_unit', component: DeviceConfigBaseUnit, beforeEnter: requireAdmin},
    { name: 'DeviceConfigExtensionUnit', path: '/device_config/extension_unit', component: DeviceConfigExtensionUnit, beforeEnter: requireAdmin},
    { name: 'LogDownload', path: '/log_download', component: LogDownload, beforeEnter: requireAuth},

    { name: 'ScheduleTop', path: '/schedule/top', component: ScheduleTop, beforeEnter: requireAdmin},
    { name: 'ScheduleSetting', path: '/schedule/setting', component: ScheduleSetting, beforeEnter: requireAdmin},
    { name: 'ScheduleCopy', path: '/schedule/copy', component: ScheduleCopy, beforeEnter: requireAdmin},

    { name: 'EditAccount', path: '/edit_account', component: EditAccount, beforeEnter: requireAdmin},
    { name: 'EditAccountAlertEmailSetting', path: '/edit_account/alert_email', component: EditAccountAlertEmailSetting, beforeEnter: requireAdmin},
    { name: 'EditBaseUnit', path: '/edit_base_unit', component: EditBaseUnit, beforeEnter: requireAdmin},
    { name: 'EditCompany', path: '/edit_company', component: EditCompany, beforeEnter: requireSuperAdmin},
    { name: 'EditFacility', path: '/edit_facility', component: EditFacility, beforeEnter: requireSuperAdmin},

    { name: 'AdminTop', path: '/admin/', component: AdminTop, beforeEnter: requireMaster},
    { name: 'CompanyMaster', path: '/admin/company_master', component: CompanyMaster, beforeEnter: requireMaster},
    { name: 'EditCompanyMaster', path: '/admin/edit_company', component: EditCompanyMaster, beforeEnter: requireMaster},
    { name: 'Help', path: '/help', component: Help},
    { name: 'Inquiry', path: '/inquiry', component: Inquiry},
    { name: 'TermOfUse', path: '/term_of_use', component: TermOfUse},
    { name: 'PrivacyPolicy', path: '/privacy_policy', component: PrivacyPolicy}
  ],
})

async function requireAuth(to, from, next) {
  const { $cookies } = router.app.config.globalProperties
  let accessToken = $cookies.get('AccessToken')
  let refreshToken = $cookies.get('RefreshToken')

  try {
    if (accessToken === null || refreshToken === null) {
      return redirectToLogin(to, next)
    }
    accessToken = await _tokenRefreshIfNeeded($cookies)

    const verifyTokenResponse = await axios.post('/api/auth/verify_token', {
      access_token: accessToken,
    })

    if (verifyTokenResponse.data.success) {
      const userDetailResponse = await apiGet(`/api/user_detail/me`, $cookies)
      router.app.userDetail = userDetailResponse.data
      next()
    } else {
      return redirectToLogin(to, next)
    }
  } catch(err) {
    console.log(err)
    return redirectToLogin(to, next)
  }
}

async function requireAdmin(to, from, next) {
  const { $cookies } = router.app.config.globalProperties
  let accessToken = $cookies.get('AccessToken')
  let refreshToken = $cookies.get('RefreshToken')

  try {
    if (accessToken === null || refreshToken === null) {
      return redirectToLogin(to, next)
    }
    accessToken = await _tokenRefreshIfNeeded($cookies)

    const verifyTokenResponse = await axios.post('/api/auth/verify_token', {
      access_token: accessToken,
    })
    if (!verifyTokenResponse.data.success){
      return redirectToLogin(to, next)
    }

    const userDetailResponse = await apiGet(`/api/user_detail/me`, $cookies)
    if (userDetailResponse.data.is_admin) {
      router.app.userDetail = userDetailResponse.data
      return next();
    } else {
      return redirectToUserTop(next)
    }
  } catch {
    return redirectToLogin(to, next)
  }
}

async function requireSuperAdmin(to, from, next) {
  const { $cookies } = router.app.config.globalProperties
  let accessToken = $cookies.get('AccessToken')
  let refreshToken = $cookies.get('RefreshToken')

  try {
    if (accessToken === null || refreshToken === null) {
      return redirectToLogin(to, next)
    }
    accessToken = await _tokenRefreshIfNeeded($cookies)

    const verifyTokenResponse = await axios.post('/api/auth/verify_token', {
      access_token: accessToken,
    })
    if (!verifyTokenResponse.data.success){
      return redirectToLogin(to, next)
    }

    const userDetailResponse = await apiGet(`/api/user_detail/me`, $cookies)
    if (userDetailResponse.data.is_admin || userDetailResponse.data.is_super_admin) {
      router.app.userDetail = userDetailResponse.data
      return next();
    } else {
      return redirectToUserTop(next)
    }
  } catch {
    return redirectToLogin(to, next)
  }
}

async function requireMaster(to, from, next) {
  const { $cookies } = router.app.config.globalProperties
  let accessToken = $cookies.get('AccessToken')
  let refreshToken = $cookies.get('RefreshToken')

  try {
    if (accessToken === null || refreshToken === null) {
      return redirectToLogin(to, next)
    }
    accessToken = await _tokenRefreshIfNeeded($cookies)

    const verifyTokenResponse = await axios.post('/api/auth/verify_token', {
      access_token: accessToken,
    })
    if (!verifyTokenResponse.data.success){
      return redirectToLogin(to, next)
    }

    const userDetailResponse = await apiGet(`/api/user_detail/me`, $cookies)
    if (userDetailResponse.data.is_master) {
      router.app.userDetail = userDetailResponse.data
      return next();
    } else {
      return redirectToUserTop(next)
    }
  } catch {
    return redirectToLogin(to, next)
  }
}

function redirectToLogin(to, next) {
  next({
    path: '/login',
    query: { redirect: to.fullPath }
  })
}

function redirectToUserTop(next) {
  next({
    path: '/top'
  })
}

async function _tokenRefreshIfNeeded(cookies) {
  let accessToken = cookies.get('AccessToken')
  let refreshToken = cookies.get('RefreshToken')

  const decoded = jwt_decode(accessToken, {complete: true})
  const exp = decoded.exp * 1000
  const now = new Date().getTime()
  if (now > exp) {
    const refreshResponse = await axios.post('/api/auth/refresh', {
      access_token: accessToken,
      refresh_token: refreshToken
    })
    accessToken = refreshResponse.data.access_token
    refreshToken = refreshResponse.data.refresh_token
    cookies.config(60 * 60 * 24 * 30,'')
    cookies.set('AccessToken', accessToken)
    cookies.set('RefreshToken', refreshToken)
  }
  return accessToken
}

export default router;
