<template>
  <header>
  <div class="hcont">
    <h1><a href="/">WaveSense</a></h1>
    <em>
      <div v-if="userDetail">
        <span><a href="/">TOP</a></span>
        <a v-on:click="moveToEditAccount">{{ userDetail.name }}</a> でログイン中
      </div>
    </em>
    <input type="checkbox" id="drawer">
    <label for="drawer" class="open"><span></span></label>
    <label for="drawer" class="close"></label>
    <nav class="menu">
      <h2>menu</h2>
      <ul>
        <li><a href="/">会社・施設一覧に戻る</a></li>
        <li><a href="/edit_company">会社名編集</a></li>
        <li><a href="/edit_account">アカウント</a></li>
        <li><a href="/help">ヘルプ</a></li>
        <li><a href="/privacy_policy">プライバシポリシー</a></li>
        <li><a href="/term_of_use">利用規約</a></li>
        <li><a href="https://securelink-inc.co.jp/index.php">会社情報</a></li>
        <li><a href="/inquiry">お問い合わせ</a></li>
        <li><a v-on:click="logout">ログアウト</a></li>
      </ul>
    </nav>
  </div>
</header>
</template>

<script>
export default {
  name: 'Header',
  data() {
    return {
      userDetail: this.$router.app.userDetail
    }
  },
  methods: {
    logout: function() {
      this.$router.app.$cookies.remove('AccessToken')
      this.$router.app.$cookies.remove('RefreshToken')
      location.href = '/login'
    },
    moveToEditAccount: function() {
      this.$router.push(`/edit_account`)
    }
  }
}
</script>
