<template>
  <main>
    <Header />
    <div class="cont">
      <div class="BU-choice">
        <select v-model="selectingBaseUnitId">
          <option
            v-for="baseUnit in baseUnitsOfFacility"
            :key="baseUnit.id"
            :value="baseUnit.id"
          >{{ baseUnit.place_name }}</option>
        </select>&nbsp;
        <button v-on:click="moveToBaseUnit">この場所に移動</button>
      </div>
      <div class="facility">
        <h2>施設名:<span v-if="facilityName">{{ facilityName }}</span></h2>
        <p><button v-on:click="moveToPlaceSetting">各種設定</button></p>
        <p><button v-on:click="moveToLogDownload">ログダウンロード</button></p>
      </div>
      <div class="mode-state" v-if="customModeNameList">
        <div class="tit">
          <p><span>現在のモード:</span>{{ customModeNameList[currentMode] }}</p>
        </div>
        <div class="mode-select">
          <h3>モード切り替え</h3>
          <section>
            <button :class="getActiveModeClass(deviceConfig.mode.atHome)" v-on:click="setMode(deviceConfig.mode.atHome)">{{ customModeNameList[0] }}</button>
            <button :class="getActiveModeClass(deviceConfig.mode.monitoring)" v-on:click="setMode(deviceConfig.mode.monitoring)">{{ customModeNameList[1] }}</button>
            <button :class="getActiveModeClass(deviceConfig.mode.goOut)" v-on:click="setMode(deviceConfig.mode.goOut)">{{ customModeNameList[2] }}</button>
          </section>
        </div>
      </div>

      <div class="alert-timer" v-if="timeUntilAlert !== null"><p>セキュリティー開始まであと<span>{{ timeUntilAlert }}</span>秒<br>内部がセキュリティー状態になるので、速やかに退出してください。</p></div>

      <div class="config-link">
        <em v-on:click="moveToSchedule">スケジュール</em>
        <em v-on:click="moveToDeviceConfig">デバイス設定</em>
      </div>

      <div class="present">
        <div class="function">
          <button v-on:click="setShowDetail(true)">現状を確認</button>
        </div>
        <div class="state" >
          <div :class="getModeClass()">
            <p v-for="extensionUnit in extensionUnits" :key="extensionUnit.id" :class="getStateClass(extensionUnit.activity_level, extensionUnit.no_activity_alert)">
              <span>{{ extensionUnit.room_name }}</span>
            </p>
          </div>
        </div>
        <strong>
          <RoomDetail v-if="showDetail" :baseUnitId="parseInt(baseUnitId)" :extensionUnits="extensionUnits" @closeDetail="setShowDetail(false)"/>
        </strong>
      </div>
    </div>
  </main>
  <Footer />
</template>

<script>
import Header from '../Header'
import Footer from '../Footer'
import { deviceConfig } from '../../constants'
import { apiGet, apiPost } from '../../api'
import RoomDetail from './RoomDetail'

export default {
  name: 'PlaceDetail',
  components: {
    Header,
    Footer,
    RoomDetail
  },
  data () {
    return {
      baseUnitId: this.$route.query.base_unit_id,
      currentMode: null,
      currentModeString: null,
      deviceConfig: deviceConfig,
      extensionUnits: [],
      showDetail: false,
      facilityName: null,
      baseUnitsOfFacility: null,
      selectingBaseUnitId: this.$route.query.base_unit_id,
      timeUntilAlert: null,
      exitDelay: null,
      alertCountDownTimer: null,
      customModeNameList: null,
    }
  },
  watch: {
    '$route': function(){
        this.baseUnitId = this.$route.query.base_unit_id
        this.selectingBaseUnitId = this.$route.query.base_unit_id
        this.loadData()
    }
  },
  mounted() {
    this.loadData()
  },
  methods: {
    loadData: function() {
      this.getBaseUnit()
      this.getMode()
      this.getExtensionUnits()
      this.getPlaceSetting()
    },
    getBaseUnit: async function() {
      try {
        const response = await apiGet(`/api/base_unit?id=${this.baseUnitId}`, this.$router.app.$cookies)
        this.facilityName = response.data.facility_name
        this.baseUnitsOfFacility = response.data.base_units_of_facility
      } catch(err) {
        console.error(err)
      }
    },
    getExtensionUnits: async function() {
      try {
        const response = await apiGet(`/api/extension_unit?base_unit_id=${this.baseUnitId}`, this.$router.app.$cookies)
        this.extensionUnits = response.data
      } catch (err) {
        console.error(err)
      }
    },
    getPlaceSetting: async function() {
      try {
        const response = await apiGet(`/api/place_setting?base_unit_id=${this.baseUnitId}`, this.$router.app.$cookies)
        this.customModeNameList = [
          response.data.mode_name_0 ? response.data.mode_name_0 : 'フリー',
          response.data.mode_name_1 ? response.data.mode_name_1 : 'ケア',
          response.data.mode_name_2 ? response.data.mode_name_2 : 'セット',
        ]
      } catch(err) {
        this.customModeNameList = ['フリー', 'ケア', 'セット']
      }
    },
    getMode: async function() {
      try {
        const response = await apiGet(`/api/device_config?base_unit_id=${this.baseUnitId}`, this.$router.app.$cookies)
        const config = response.data
        this.currentMode = config.operationInfo.mode
        this.exitDelay = config.operationInfo.exitDelay
      } catch(err) {
        this.currentMode = -1
        console.error(err)
      }
    },
    setMode: async function(mode) {
      try {
        await apiPost('/api/device_config/mode', {
          base_unit_id: this.baseUnitId,
          mode: mode
        }, this.$router.app.$cookies)
        this.currentMode = mode
        clearInterval(this.alertCountDownTimer)
        if (mode === deviceConfig.mode.goOut && this.exitDelay !== null) {
          this.timeUntilAlert = this.exitDelay
          this.alertCountDownTimer = setInterval(() => {
            this.timeUntilAlert -= 1
            if (this.timeUntilAlert < 1) {
              clearInterval(this.alertCountDownTimer)
            }
          }, 1000)
        } else {
          this.timeUntilAlert = null
        }

        setTimeout(() => {
          this.loadData()
        }, 1000)
      } catch(err) {
        console.error(err)
      }
    },
    setShowDetail: function(showDetail) {
      this.showDetail = showDetail
    },
    moveToBaseUnit: function() {
      this.$router.push(`/place_detail?base_unit_id=${this.selectingBaseUnitId}`)
    },
    moveToPlaceSetting: function() {
      this.$router.push(`/place_setting?base_unit_id=${this.baseUnitId}`)
    },
    moveToSchedule: function() {
      this.$router.push(`/schedule/top?base_unit_id=${this.baseUnitId}`)
    },
    moveToDeviceConfig: function() {
      this.$router.push(`/device_config/base_unit?id=${this.baseUnitId}`)
    },
    moveToLogDownload: function() {
      this.$router.push(`/log_download?base_unit_id=${this.baseUnitId}`)
    },
    getStateClass: function(activityLevel, noActivityAlert=false) {
      var result = this.getActiveLevelClass(activityLevel)
      if (noActivityAlert) {
        result += " no-activity-alert"
      }
      return result
    },
    getActiveLevelClass: function(activityLevel) {
      if (activityLevel === null) {
        return 'activity-level-null'
      } else if (activityLevel > 80) {
        return 'activity-level-very-high'
      } else if (activityLevel > 60) {
        return 'activity-level-high'
      } else if (activityLevel > 40) {
        return 'activity-level-medium'
      } else if (activityLevel > 30) {
        return 'activity-level-low'
      } else {
        return 'activity-level-very-low'
      }
    },
    getActiveModeClass: function(mode) {
      return this.currentMode === mode ? 'active' : ''
    },
    getModeClass: function() {
      return this.currentMode == deviceConfig.mode.atHome ? 'mode-at-home'
        : this.currentMode == deviceConfig.mode.monitoring ? 'mode-monitoring'
        : this.currentMode == deviceConfig.mode.goOut ? 'mode-go-out'
        : 'mode-unknown'
    },
  }
}
</script>

<style scoped>
  @media all and (max-width: 580px) {
    main {
      padding: 5vw 0;
    }
    main h2 {
      color: rgb(180,120,60);
      font-size: 4.4vw;
      font-weight: bold !important;
      text-align: center;
      padding-bottom: 1em;
    }
    main .mode-state .mode-select,
    main .mode-state .function {
      font-size: 3.8vw;
      text-align: center;
      padding: 1.5em 0;
    }
    main .mode-state p {
      font-size: 3.8vw;
      text-align: center;
    }
    main .mode-state p span {
      font-size: 3.5vw;
    }
    main .mode-state .mode-select button {
      display: inline-block;
      text-align: center;
      width: 25vw;
      margin: 0 2vw;
    }
    main .mode-state .mode-select button.active {
      background: rgb(220, 240, 255);
      border: rgb(125, 150, 200) solid 1px;
      cursor: default;
    }
    main .cont .facility {
      display: flex;
      flex-flow: row wrap;
      justify-content: flex-start;
      align-items: center;
      border-bottom: rgb(180,120,60) solid 1px;
    }
    main .cont .facility p {
      padding: 0 1em 2em;
    }
    main .cont .facility p button {
      background: rgb(255,255,255);
      color: rgb(70, 150, 255);
      cursor: pointer;
      transition: all  0.3s ease;
      opacity: 1;
      padding: 0;
    }
    main .cont .facility p button:hover {
      color: rgb(0, 80, 190);
    }
    main .cont .mode-state .tit {
      display: flex;
      flex-flow: row wrap;
      justify-content: center;
    }
    main .cont .mode-state .tit p {
      color: rgb(0, 80, 190);
    }
    main .cont .mode-state .tit p span {
      color: #2c3e50;
    }
    main .cont .mode-state .mode-select {
      padding-bottom: 40px;
    }
    main .cont .mode-state .mode-select h3 {
      text-align: center;
      font-size: 18px;
      font-weight: normal;
      padding-bottom: .6em;
    }
    main .cont .mode-state .mode-select section {
      display: flex;
      flex-flow: row wrap;
      justify-content: center;
    }
    main .cont .mode-state .mode-select section button {
      word-break: break-all;
    }
    main .config-link {
      text-align: center;
      padding: 0 0 2em;
    }
    main .config-link em {
      display: inline-block;
      font-style: normal;
      font-size: 3.8vw;
      padding: .5em 1em;
      color: rgb(70, 150, 255);
      cursor: pointer;
      transition: all  0.3s ease;
    }
    main .present .function {
      text-align: center;
      padding-bottom: 20px;
    }
    main .present .function button {
      opacity: .7;
      background: rgb(195, 65, 145);
      border-radius: 3em;
      color: rgb(255,255,255);
    }
    main .present .state div {
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
      font-size: 3.8vw;
    }
    main .present .state div::after {
      content: '';
      display: block;
      width: 30vw;
    }
    main .alert-timer {
      padding: 0 0 4vw;
    }
    main .alert-timer p {
      font-size: 4.2vw;
      font-weight: bold;
      color: rgb(255,120,70);
      text-align: center;
    }
    main .alert-timer p span {
      font-size: 4.8vw;
      font-weight: bold;
    }
    /* モードアイコン制御 */
    .present .state .mode-at-home p,
    .present .state .mode-monitoring p,
    .present .state .mode-go-out p {
      position: relative;
      width: 30vw;
      margin-bottom: 1em;
      padding: 0 0 2em;
      text-align: center;
      background: rgb(255,255,255);
    }
    .present .state .mode-at-home p span,
    .present .state .mode-monitoring p span,
    .present .state .mode-go-out p span {
      display: block;
      padding-left: 2em;
      word-break: break-all;
      text-align: left;
    }
    .present .state .mode-at-home p::before,
    .present .state .mode-monitoring p::before,
    .present .state .mode-go-out p::before {
      position: absolute;
      display: block;
      width: calc(100% - 1em);
      height: 100%;
      bottom: .5em;
      left: 0;
      display: flex;
      padding-left: 2em;
      align-items: flex-end;
      font-size: 3.6vw;
    }
    .present .state .mode-monitoring p.activity-level-very-low {
      color: rgb(255,120,70);
      font-weight: bold;
    }
    .present .state .mode-monitoring p.activity-level-very-low::before {
      content: '検知レベル1';
      color: rgb(255,120,70);
      background: url(@/assets/img/icon-alert.svg) left .3em center no-repeat;
      background-size: 1.4em;
    }
    .present .state .mode-at-home p.activity-level-very-low::before {
      content: '検知レベル1';
      background: url(@/assets/img/icon-act-l01.svg) left .3em center no-repeat;
      background-size: 1.4em;
    }
    .present .state .mode-at-home p.activity-level-low::before,
    .present .state .mode-monitoring p.activity-level-low::before {
      content: '検知レベル2';
      background: url(@/assets/img/icon-act-l02.svg) left .3em center no-repeat;
      background-size: 1.4em;
    }
    .present .state .mode-at-home p.activity-level-medium::before,
    .present .state .mode-monitoring p.activity-level-medium::before {
      content: '検知レベル3';
      background: url(@/assets/img/icon-act-l03.svg) left .3em center no-repeat;
      background-size: 1.4em;
    }
    .present .state .mode-at-home p.activity-level-high::before,
    .present .state .mode-monitoring p.activity-level-high::before {
      content: '検知レベル4';
      background: url(@/assets/img/icon-act-l04.svg) left .3em center no-repeat;
      background-size: 1.4em;
    }
    .present .state .mode-at-home p.activity-level-very-high::before,
    .present .state .mode-monitoring p.activity-level-very-high::before {
      content: '検知レベル5';
      background: url(@/assets/img/icon-act-l05.svg) left .3em center no-repeat;
      background-size: 1.4em;
    }
    .present .state .mode-monitoring p.no-activity-alert::before {
      content: '要確認！';
      color: rgb(255,120,70);
      font-weight: bold;
      background: url(@/assets/img/icon-alert-red.svg) left .3em center no-repeat;
      background-size: 1.4em;
    }
    .present .state .mode-at-home p.activity-level-null::before,
    .present .state .mode-monitoring p.activity-level-null::before {
      content: '信号なし';
    }
    .present .state .mode-go-out p.activity-level-very-low::before {
      content: '検知なし';
      background: url(@/assets/img/icon-safe.svg) left 5px center no-repeat;
      background-size: 30px;
    }
    .present .state .mode-go-out p.activity-level-low::before,
    .present .state .mode-go-out p.activity-level-medium::before,
    .present .state .mode-go-out p.activity-level-high::before,
    .present .state .mode-go-out p.activity-level-very-high::before {
      content: '異常検知';
      color: rgb(255,120,70);
      font-weight: bold;
      background: url(@/assets/img/icon-alert.svg) left 5px center no-repeat;
      background-size: 30px;
    }
    .present .state .mode-monitoring p.activity-level-very-low,
    .present .state .mode-go-out p.activity-level-very-low,
    .present .state .mode-go-out p.activity-level-low,
    .present .state .mode-go-out p.activity-level-medium,
    .present .state .mode-go-out p.activity-level-high,
    .present .state .mode-go-out p.activity-level-very-high {
      padding: 0 0 2em;
    }
  }
  @media all and (min-width: 580px) {
    main {
    }
    main h2 {
      color: rgb(180,120,60);
      font-size: 24px;
      font-weight: bold !important;
      text-align: center;
      padding-bottom: 1em;
    }
    main .mode-state .mode-select,
    main .mode-state .function {
      text-align: center;
      padding: 20px 0;
    }
    main .mode-state p {
      font-size: 18px;
      text-align: center;
    }
    main .mode-state p span {
      font-size: 16px;
    }
    main .mode-state .mode-select section {
      display: flex;
      flex-flow: row wrap;
      justify-content: center;
    }
    main .mode-state .mode-select button {
      display: inline-block;
      text-align: center;
      width: 11em;
      min-height: 3em;
      margin: 0 10px;
      word-break: break-all;
    }
    main .mode-state .mode-select button.active {
      background: rgb(60, 125, 220);
      cursor: default;
    }
    main .cont .facility {
      display: flex;
      flex-flow: row wrap;
      justify-content: flex-start;
      align-items: baseline;
      border-bottom: rgb(180,120,60) solid 1px;
      margin-bottom: 25px;
    }
    main .cont .facility p {
      padding: 0 25px .5em;
    }
    main .cont .facility p button {
      background: rgb(255,255,255);
      color: rgb(70, 150, 255);
      cursor: pointer;
      transition: all  0.3s ease;
      opacity: 1;
      padding: 0;
    }
    main .cont .facility p button:hover {
      color: rgb(0, 80, 190);
    }
    main .cont .mode-state .tit {
      display: flex;
      flex-flow: row wrap;
      justify-content: center;
    }
    main .cont .mode-state .tit p {
      color: rgb(0, 80, 190);
    }
    main .cont .mode-state .tit p span {
      color: #2c3e50;
    }
    main .cont .mode-state .mode-select {
      padding-bottom: 40px;
    }
    main .cont .mode-state .mode-select h3 {
      text-align: center;
      font-size: 18px;
      font-weight: normal;
      padding-bottom: .6em;
    }
    main .config-link {
      text-align: center;
      padding: 0 0 20px;
    }
    main .config-link em {
      display: inline-block;
      font-style: normal;
      font-size: 1.6rem;
      margin: 0 1.5em 1em;
      padding: .5em 1em;
      color: rgb(70, 150, 255);
      cursor: pointer;
      transition: all  0.3s ease;
    }
    main .config-link em:hover {
      color: rgb(0, 80, 190);
    }

    main .present .function {
      text-align: center;
      padding-bottom: 20px;
    }
    main .present .function button {
      opacity: .7;
      background: rgb(195, 65, 145);
      border-radius: 3em;
      color: rgb(255,255,255);
    }
    main .present .function button:hover {
      opacity: 1;
    }
    main .present .state div {
      display: flex;
      flex-flow: row wrap;
      justify-content: center;
      font-size: 16px;
    }
    main .alert-timer {
      padding: 0 0 15px;
    }
    main .alert-timer p {
      font-size: 2rem;
      font-weight: bold;
      color: rgb(255,120,70);
      text-align: center;
    }
    main .alert-timer p span {
      font-size: 2.4rem;
      font-weight: bold;
    }
    /* モードアイコン制御 */
    .present .state div p {
      border-left: rgb(180,180,180) solid 1px;
      position: relative;
      width: 11em;
      margin: 5px;
      padding: 0 .2em 1.5em;
      text-align: center;
      box-sizing: border-box;
    }
    .present .state div p:last-child {
      border-right: rgb(180,180,180) solid 1px;
    }
    .present .state .mode-at-home p,
    .present .state .mode-monitoring p,
    .present .state .mode-go-out p {
    }
    .present .state .mode-at-home p span,
    .present .state .mode-monitoring p span,
    .present .state .mode-go-out p span {
      word-break: break-all;
      display: block;
      padding-left: 2.5em;
      text-align: left;
    }
    .present .state .mode-at-home p::before,
    .present .state .mode-monitoring p::before,
    .present .state .mode-go-out p::before {
      position: absolute;
      display: block;
      width: calc(100% - 40px);
      height: 100%;
      bottom: 0;
      left: 0;
      display: flex;
      padding-left: 40px;
      align-items: flex-end;
    }
    .present .state .mode-monitoring p.activity-level-very-low {
      color: rgb(255,120,70);
      font-weight: bold;
    }
    .present .state .mode-monitoring p.activity-level-very-low::before {
      content: '検知レベル1';
      color: rgb(255,120,70);
      font-weight: bold;
      background: url(@/assets/img/icon-alert.svg) left 5px center no-repeat;
      background-size: 30px;
    }
    .present .state .mode-at-home p.activity-level-very-low::before {
      content: '検知レベル1';
      background: url(@/assets/img/icon-act-l01.svg) left 5px center no-repeat;
      background-size: 30px;
    }
    .present .state .mode-at-home p.activity-level-low::before,
    .present .state .mode-monitoring p.activity-level-low::before {
      content: '検知レベル2';
      background: url(@/assets/img/icon-act-l02.svg) left 5px center no-repeat;
      background-size: 30px;
    }
    .present .state .mode-at-home p.activity-level-medium::before,
    .present .state .mode-monitoring p.activity-level-medium::before {
      content: '検知レベル3';
      background: url(@/assets/img/icon-act-l03.svg) left 5px center no-repeat;
      background-size: 30px;
    }
    .present .state .mode-at-home p.activity-level-high::before,
    .present .state .mode-monitoring p.activity-level-high::before {
      content: '検知レベル4';
      background: url(@/assets/img/icon-act-l04.svg) left 5px center no-repeat;
      background-size: 30px;
    }
    .present .state .mode-at-home p.activity-level-very-high::before,
    .present .state .mode-monitoring p.activity-level-very-high::before {
      content: '検知レベル5';
      background: url(@/assets/img/icon-act-l05.svg) left 5px center no-repeat;
      background-size: 30px;
    }
    .present .state .mode-monitoring p.no-activity-alert::before {
      content: '要確認！';
      color: rgb(255,120,70);
      font-weight: bold;
      background: url(@/assets/img/icon-alert-red.svg) left 5px center no-repeat;
      background-size: 30px;
    }
    .present .state .mode-at-home p.activity-level-null::before,
    .present .state .mode-monitoring p.activity-level-null::before {
      content: '信号なし';
    }
    .present .state .mode-go-out p.activity-level-very-low::before {
      content: '検知なし';
      background: url(@/assets/img/icon-safe.svg) left 5px center no-repeat;
      background-size: 30px;
    }
    .present .state .mode-go-out p.activity-level-low::before,
    .present .state .mode-go-out p.activity-level-medium::before,
    .present .state .mode-go-out p.activity-level-high::before,
    .present .state .mode-go-out p.activity-level-very-high::before {
      content: '異常検知';
      color: rgb(255,120,70);
      font-weight: bold;
      background: url(@/assets/img/icon-alert.svg) left 5px center no-repeat;
      background-size: 30px;
    }
  }
</style>
