<template>
  <main>
    <Header />
    <div class="cont">
      <div class="page-tit">
        <h1>アカウント</h1>
        <p class="nav-links">
          <span class="link" v-on:click="backToTop">会社・施設一覧に戻る</span>
        </p>
      </div>
      <div class="notes">
        <dl>
          <dt>登録日時</dt>
          <dd>
            <p v-if="userCreatedAtString">{{ userCreatedAtString }}</p>
          </dd>
        </dl>
        <dl>
          <dt>登録者名（変更不可）</dt>
          <dd>
            <p v-if="userDetail">{{ userDetail.name }}</p>
          </dd>
        </dl>
        <em>変更不可のものは<a href="/inquiry">お問い合わせ</a>より、ご変更の依頼をお願いします。</em>
      </div>

      <div class="set-email">
        <h2>メール通知設定</h2>
        <dl class="radios">
          <dt>メール通知</dt>
          <dd>
            <label for="input1">ON</label>
            <input id="input1" type="radio" value="on" v-model="mailNotification" />
            <input id="input2" type="radio" value="off" v-model="mailNotification" />
            <label for="input2">OFF</label>
            <span id="slider"></span>
          </dd>
          <dd>
            <p class="save">
              <button v-on:click="saveUserDetail">保存する</button>
            </p>
          </dd>
        </dl>

        <p class="alert" v-if="saveSuccess === true">保存が完了しました</p>
        <p class="alert" v-if="saveSuccess === false">保存が失敗しました</p>

        <div class="meil-list">
          <p>
            <span>アラート送信先メールアドレス</span>
            <span class="linktxt" v-on:click="editAlertEmailAddresses">追加・編集</span>
          </p>
          <em>各親機からアラートを飛ばす事ができるメールアドレスを追加できます</em>
          <table>
            <thead>
              <th>メールアドレス</th>
              <th>名前</th>
            </thead>
            <tbody v-if="userDetail">
              <tr v-for="alertMailAddress in userDetail.alert_mail_addresses" :key="alertMailAddress.id">
                <td>{{ alertMailAddress.email }}</td>
                <td>{{ alertMailAddress.name }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </main>
  <Footer />
</template>

<script>
import Header from '../Header'
import Footer from '../Footer'
import { apiGet, apiPost } from '../../api'

export default {
  name: 'EditAccount',
  components: {
    Header,
    Footer
  },
  data: function() {
    return {
      userDetail: null,
      userCreatedAtString: null,
      editingCompany: null,
      editingCompanyName: '',
      inputCompanyName: '',
      mailNotification: '',
      alertMailAddresses: [],
      saveSuccess: null
    }
  },
  mounted() {
    this.loadUserDetail()
  },
  methods: {
    loadUserDetail: async function() {
      const response = await apiGet('/api/user_detail/me', this.$router.app.$cookies)
      this.userDetail = response.data
      this.userCreatedAtString = new Date(this.userDetail.created_at).toLocaleString()
      this.mailNotification = this.userDetail.is_alert_email_active ? 'on' : 'off'
    },
    saveUserDetail: async function() {
      this.userDetail.is_alert_email_active = this.mailNotification === 'off' ? false : true
      const response = await apiPost(`/api/user_detail/me`, this.userDetail, this.$router.app.$cookies)
      this.saveSuccess = response.data
    },
    backToTop: function() {
      this.$router.push(`/top`)
    },
    editAlertEmailAddresses: function() {
      this.$router.push(`/edit_account/alert_email`)
    }
  }
}
</script>

<style scoped>
  @media all and (max-width: 580px) {
    .cont .notes dl {
      display: flex;
      flex-flow: row wrap;
      padding-block: .6em;
    }
    .cont .notes dl dt {
      font-size: 3.8vw;
      width: 12em;
    }
    .cont .notes em {
      display: block;
      font-weight: normal;
      font-style: normal;
      font-size: 3.8vw;
      padding-bottom: 2em;
    }
    .cont input {
      font-size: 3.8vw;
      padding: .4em .2em;
    }
    .cont div p {
      font-size: 3.8vw;
    }
    .cont .exp {
      padding: 1.6em 0;
    }
    .cont .box label {
      font-size: 3.8vw;
      padding-right: .6em;
    }
    .cont table {
      font-size: 3.8vw;
      width: 100%;
    }
    .cont table th {
      font-weight: normal;
      border: rgb(130,180,255) solid 1px;
      background: rgb(215,240,255);
      text-align: center;
      padding: .5em;
      width: 22%;
    }
    .cont table th:first-child {
      width: 50%;
    }
    .cont table th:nth-last-of-type(1),
    .cont table th:nth-last-of-type(2) {
      width: 15%;
    }
    .cont table td {
      border: rgb(130,180,255) solid 1px;
      padding: .5em;
    }
    .cont table td:nth-last-of-type(1),
    .cont table td:nth-last-of-type(2) {
      text-align: center;
    }
    .cont .radios {
      display: flex;
      flex-flow: row wrap;
      align-items: center;
      padding: 1em 0;
    }
    .cont .radios dt {
      font-size: 3.8vw;
      width: 6em;
    }
    .cont .radios dd {
      position: relative;
      font-size: 3.8vw;
    }
    .cont .radios dd input[type="radio"]{
      position:absolute;
      right:1000%;
    }
    .cont .radios dd label{
      display: inline-block;
      text-align: center;
      width: 3em;
      padding: .5em;
      margin: .5em;
      background:rgba(130,180,255,.2);
      border-radius: 2em;
      cursor:pointer;
      position: relative;
      z-index: 10;
    }
    .cont .radios #slider{
      position:absolute;
      left:0%;
      top:0;
      width: 3em;
      height: .5em;
      padding-bottom:10%;
      margin: 1em 0 0 1em;
      background: rgb(130,180,255);
      transition:transform 1s;
      border-radius:2em;
      animation-timing-function: ease-in-out;
      animation-duration:.3s;
      animation-fill-mode: forwards;
      transition: 0.2s left .05s ease-in-out;
      z-index: 1;
    }
    #input1:checked  ~ #slider{ animation-name: input1; left:0; }
    #input2:checked  ~ #slider{ animation-name: input2; left:50%; }

    @keyframes input1{ 30%, 70% { transform:scale(0.5); } }
    @keyframes input2{ 30%, 70% { transform:scale(0.5); } }

    .cont dl dd .save {
      padding: 0 1em;
    }
    .cont dl dd .save button {
      font-size: 3.8vw;
      padding: .5em 1em;
    }

    .cont .meil-list {
      padding: 10vw 0
    }
    .cont .meil-list em {
      display: block;
      font-size: 3.8vw;
      font-style: normal;
      padding: .5em 0 1em;
    }
    .cont .meil-list table {
      background: rgb(255,255,255);
    }
    .cont .meil-list table th:nth-of-type(1) {
      width: 60%;
    }
    .cont .meil-list table th:nth-of-type(2) {
      width: auto;
    }
  }
  @media all and (min-width: 580px) {
    .cont .notes dl {
      display: flex;
      flex-flow: row wrap;
      padding-block: .6em;
    }
    .cont .notes dl dt {
      font-size: 1.6rem;
      width: 12em;
    }
    .cont .notes em {
      display: block;
      font-weight: normal;
      font-style: normal;
      font-size: 1.6rem;
      padding-bottom: 2em;
    }
    .cont input {
      font-size: 1.6rem;
      padding: .4em .2em;
    }
    .cont div p {
      font-size: 1.6rem;
    }
    .cont .exp {
      padding: 1.6em 0;
    }
    .cont .box label {
      font-size: 1.6rem;
      padding-right: .6em;
    }
    .cont table {
      font-size: 1.6rem;
      width: 100%;
    }
    .cont table th {
      font-weight: normal;
      border: rgb(130,180,255) solid 1px;
      background: rgb(215,240,255);
      text-align: center;
      padding: .5em;
      width: 22%;
    }
    .cont table th:first-child {
      width: 50%;
    }
    .cont table th:nth-last-of-type(1),
    .cont table th:nth-last-of-type(2) {
      width: 15%;
    }
    .cont table td {
      border: rgb(130,180,255) solid 1px;
      padding: .5em;
    }
    .cont table td:nth-last-of-type(1),
    .cont table td:nth-last-of-type(2) {
      text-align: center;
    }
    .cont .radios {
      display: flex;
      flex-flow: row wrap;
      align-items: center;
      padding: 1em 0;
    }
    .cont .radios dt {
      font-size: 1.6rem;
      width: 6em;
    }
    .cont .radios dd {
      position: relative;
      font-size: 1.6rem;
    }
    .cont .radios dd input[type="radio"]{
      position:absolute;
      right:1000%;
    }
    .cont .radios dd label{
      display: inline-block;
      text-align: center;
      width: 3em;
      padding: .5em;
      margin: .5em;
      background:rgba(130,180,255,.2);
      border-radius: 2em;
      cursor:pointer;
      position: relative;
      z-index: 10;
    }
    .cont .radios #slider{
      position:absolute;
      left:0%;
      top:0;
      width: 3em;
      height: .5em;
      padding-bottom:10%;
      margin: 1em 0 0 1em;
      background: rgb(130,180,255);
      transition:transform 1s;
      border-radius:2em;
      animation-timing-function: ease-in-out;
      animation-duration:.3s;
      animation-fill-mode: forwards;
      transition: 0.2s left .05s ease-in-out;
      z-index: 1;
    }
    #input1:checked  ~ #slider{ animation-name: input1; left:0; }
    #input2:checked  ~ #slider{ animation-name: input2; left:50%; }

    @keyframes input1{ 30%, 70% { transform:scale(0.5); } }
    @keyframes input2{ 30%, 70% { transform:scale(0.5); } }

    .cont dl dd .save {
      text-align: center;
      padding: 0 20px;
    }

    .cont .meil-list {
      padding: 20px 0;
    }
    .cont .meil-list p span {
      padding-right: 1em;
    }
    .cont .meil-list em {
      display: block;
      font-size: 1.6rem;
      font-style: normal;
      padding: .5em 0 1em;
    }
    .cont .meil-list table th:nth-of-type(1) {
      width: 60%;
    }
    .cont .meil-list table th:nth-of-type(2) {
      width: auto;
    }
  }
</style>
