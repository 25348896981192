<template>
  <main>
    <Header />
    <div class="cont">
      <div class="BU-choice">
        <select v-model="selectingBaseUnitId">
          <option
            v-for="baseUnit in baseUnitsOfFacility"
            :key="baseUnit.id"
            :value="baseUnit.id"
          >{{ baseUnit.place_name }}</option>
        </select>&nbsp;
        <button v-on:click="moveToDashBoard(selectingBaseUnitId)">この場所に移動</button>
      </div>
      <div class="page-tit">
        <h1>各種設定 : <span v-if="facilityName">{{ facilityName }}</span></h1>
        <p class="nav-links">
          <span class="link" v-on:click="moveToDashBoard(baseUnitId)">ダッシュボードに戻る</span>
        </p>
      </div>

      <div class="set mode">
        <strong>モード設定</strong>
        <dl>
          <dt>モード1（通知オフ、サイレンオフ）</dt>
          <dd>
            <input type="text" v-model="customModeName.atHome">
          </dd>
          <dt>モード2（通知オン、サイレンオフ）</dt>
          <dd>
            <input type="text" v-model="customModeName.monitoring">
          </dd>
          <dt>モード3（通知オン、サイレンオン）</dt>
          <dd>
            <input type="text" v-model="customModeName.goOut">
          </dd>
        </dl>
      </div>

      <div class="set graph">
        <strong>表示設定（グラフに表示されるテキスト）</strong>
        <dl>
          <dt>レベル1</dt>
          <dd>
            <input type="number" v-model="customActivityLevelThreshold1" min="0" max="100">
            <span>*少し動きがあります</span>
          </dd>
          <dt>レベル2</dt>
          <dd>
            <input type="number" v-model="customActivityLevelThreshold2" min="0" max="100">
            <span>*動きがあります</span>
          </dd>
          <dt>レベル3</dt>
          <dd>
            <input type="number" v-model="customActivityLevelThreshold3" min="0" max="100">
            <span>*大きな動きがあります</span>
          </dd>
        </dl>
      </div>

      <div class="set delay">
        <strong>検知するまでの猶予時間</strong>
        <p>帰宅時
          <span>
            <select v-model="entryDelay">
              <option :value="30">30秒</option>
              <option :value="45">45秒</option>
              <option :value="60">60秒</option>
            </select>
          </span>
        </p>
        <p>外出時
          <span>
            <select v-model="exitDelay">
              <option :value="30">30秒</option>
              <option :value="45">45秒</option>
              <option :value="60">60秒</option>
            </select>
          </span>
        </p>
      </div>

      <div class="set frequency">
        <strong>通知頻度</strong>
        <select v-model="notificationInterval">
          <option :value="5">5分</option>
          <option :value="10">10分</option>
          <option :value="15">15分</option>
          <option :value="30">30分</option>
          <option :value="60">60分</option>
        </select><br>
      </div>

      <div class="set schedule">
        <strong >スケジュール機能の有効化</strong>
        <p>
          <label><input type="radio" :value="1" v-model="isScheduleActive" />ON</label>
          <span><label><input type="radio" :value="0" v-model="isScheduleActive" />OFF</label></span>
        </p>
      </div>

      <div class="set alertmail">
        <strong >アラートメールの有効化</strong>
        <p>
          <label><input type="radio" :value="1" v-model="isAlertEmailActive" />ON</label>
          <span><label><input type="radio" :value="0" v-model="isAlertEmailActive" />OFF</label></span>
        </p>
      </div>

      <div class="set-btn">
        <p class="alert" v-if="failureMessage">{{ failureMessage }}</p>
        <button v-on:click="savePlaceSetting">設定を保存する</button>
        <p class="alert" v-if="successMessage">{{ successMessage }}</p>
      </div>

      <div class="meil-list">
        <p>
          アラート送信先メールアドレス&nbsp;
          <span class="linktxt" v-on:click="editAlertEmailAddresses">追加・編集</span>
        </p>
        <em>各親機からアラートを飛ばす事ができるメールアドレスを追加できます</em>
        <table>
          <thead>
            <th>メールアドレス</th>
            <th>名前</th>
          </thead>
          <tbody v-if="alertEmailAddresses">
            <tr v-for="alertEmailAddress in alertEmailAddresses" :key="alertEmailAddress.id">
              <td>{{ alertEmailAddress.email }}</td>
              <td>{{ alertEmailAddress.name }}</td>
            </tr>
          </tbody>
        </table>
      </div>

    </div>
  </main>
  <Footer />
</template>

<script>
import Header from '../Header'
import Footer from '../Footer'
import { apiGet, apiPost } from '../../api'
import { deviceConfig } from '../../constants'

export default {
  name: 'PlaceSetting',
  components: {
    Header,
    Footer,
  },
  data () {
    return {
      baseUnitId: this.$route.query.base_unit_id,
      baseUnitsOfFacility: null,
      facilityName: null,
      selectingBaseUnitId: this.$route.query.base_unit_id,
      placeSettingId: null,
      customModeName: {
        atHome: null,
        monitoring: null,
        goOut: null
      },
      customActivityLevelThreshold1: null,
      customActivityLevelThreshold2: null,
      customActivityLevelThreshold3: null,
      entryDelay: null,
      exitDelay: null,
      notificationInterval: null,
      isScheduleActive: null,
      isAlertEmailActive: null,
      alertEmailAddresses: null,
      successMessage: null,
      failureMessage: null
    }
  },
  mounted() {
    this.loadData()
  },
  methods: {
    loadData: async function() {
      this.getBaseUnit()
      this.getPlaceSetting()
    },
    getBaseUnit: async function() {
      try {
        const response = await apiGet(`/api/base_unit?id=${this.baseUnitId}`, this.$router.app.$cookies)
        this.baseUnitDetail = response.data
        this.facilityName = response.data.facility_name
        this.baseUnitsOfFacility = response.data.base_units_of_facility
      } catch(err) {
        console.error(err)
      }
    },
    getPlaceSetting: async function() {
      try {
        const response = await apiGet(`/api/place_setting?base_unit_id=${this.baseUnitId}`, this.$router.app.$cookies)

        this.customModeName.atHome = typeof(response.data.mode_name_0) !== 'undefined' ? response.data.mode_name_0 : deviceConfig.modeName.atHome
        this.customModeName.monitoring = typeof(response.data.mode_name_1) !== 'undefined' ? response.data.mode_name_1 : deviceConfig.modeName.monitoring
        this.customModeName.goOut = typeof(response.data.mode_name_2) !== 'undefined' ? response.data.mode_name_2 : deviceConfig.modeName.goOut

        this.customActivityLevelThreshold1 = typeof(response.data.activity_level_threshold_1) !== 'undefined' ? response.data.activity_level_threshold_1 : 20
        this.customActivityLevelThreshold2 = typeof(response.data.activity_level_threshold_2) !== 'undefined' ? response.data.activity_level_threshold_2 : 40
        this.customActivityLevelThreshold3 = typeof(response.data.activity_level_threshold_3) !== 'undefined' ? response.data.activity_level_threshold_3 : 70

        this.entryDelay = typeof(response.data.entry_delay) !== 'undefined' ? response.data.entry_delay : 30
        this.exitDelay = typeof(response.data.exit_delay) !== 'undefined' ? response.data.exit_delay : 30
        this.notificationInterval = typeof(response.data.notification_interval) !== 'undefined' ? response.data.notification_interval : 15
        if (typeof(response.data.is_schedule_active) === 'boolean') {
          this.isScheduleActive = response.data.is_schedule_active ? 1 : 0
        } else {
          this.isScheduleActive = 1
        }

        this.isAlertEmailActive = response.data.is_alert_email_active
        this.alertEmailAddresses = response.data.alert_email_addresses
      } catch(err) {
        console.error(err)
      }
    },
    savePlaceSetting: async function() {
      if (!this.checkInput()) {
        this.failureMessage = "活動レベルは1,2,3の順に大きくなるようにしてください。"
        return
      }
      const payload = {
        base_unit_id: this.baseUnitId,
        mode_name_0: this.customModeName.atHome,
        mode_name_1: this.customModeName.monitoring,
        mode_name_2: this.customModeName.goOut,
        activity_level_threshold_1: parseInt(this.customActivityLevelThreshold1),
        activity_level_threshold_2: parseInt(this.customActivityLevelThreshold2),
        activity_level_threshold_3: parseInt(this.customActivityLevelThreshold3),
        entry_delay: parseInt(this.entryDelay),
        exit_delay: parseInt(this.exitDelay),
        notification_interval: parseInt(this.notificationInterval),
        is_schedule_active: this.isScheduleActive === 1,
        is_alert_email_active: this.isAlertEmailActive === 1
      }
      const response = await apiPost(`/api/place_setting`, payload, this.$router.app.$cookies)
      if (response.data) {
        this.successMessage = "保存しました。"
        this.failureMessage = null
      } else {
        this.successMessage = null
        this.failureMessage = "保存に失敗しました。"
      }
    },
    moveToDashBoard: function(baseUnitId) {
      this.$router.push(`/place_detail?base_unit_id=${baseUnitId}`)
    },
    checkInput: function() {
      return this.customActivityLevelThreshold1 < this.customActivityLevelThreshold2
        && this.customActivityLevelThreshold2 < this.customActivityLevelThreshold3
    },
    editAlertEmailAddresses: function() {
      this.$router.push(`/place_setting/alert_email?base_unit_id=${this.baseUnitId}`)
    }
  }
}
</script>

<style scoped>
  @media all and (max-width: 580px) {
    .set {
      padding-bottom: 8vw;
      font-size: 3.8vw;
    }
    .set input,
    .set select {
      font-size: 3.8vw;
    }
    .set input[type="text"] {
      width: 100%;
    }
    .set strong {
      display: block;
      color: rgb(180,120,60);
      font-size: 4.2vw;
      font-weight: normal;
      padding-bottom: .5em;
    }
    .set p {
      padding-bottom: 1em;
    }
    .set p:last-child {
      padding-bottom: 0;
    }
    .set dl dt {
      padding-bottom: .2em;
    }
    .set dl dd {
      padding-bottom: 1em;
    }
    .set span {
      padding-left: 1em;
    }
    .set-btn {
      padding: 0 0 10vw;
      font-size: 3.8vw;
      text-align: center;
    }
    .set-btn .alert {
      padding: 1em 0;
    }
    .graph dl dd input {
      width: 30vw;
    }
    .meil-list {
      padding-bottom: 8vw;
      font-size: 3.8vw;
    }
    .meil-list em {
      display: block;
      font-style: normal;
      padding: .5em 0 1em;
    }
    .meil-list table {
      width: 100%;
      border-collapse: collapse;
      border: rgb(130,180,255) solid 1px;
    }
    .meil-list table th {
      border: rgb(130,180,255) solid 1px;
      background: rgb(215,240,255);
      font-weight: normal;
      padding: .5em;
    }
    .meil-list table td {
      border: rgb(130,180,255) solid 1px;
      background: rgb(255,255,255);
      padding: .5em;
    }
  }
  @media all and (min-width: 580px) {
    .set {
      width: 700px;
      margin: auto;
      padding-bottom: 30px;
      font-size: 1.6rem;
    }
    .set input,
    .set select {
      font-size: 1.6rem;
    }
    .set input[type="text"] {
      width: 100%;
    }
    .set strong {
      display: block;
      color: rgb(180,120,60);
      font-size: 1.8rem;
      font-weight: normal;
      padding-bottom: .5em;
    }
    .set p {
      padding-bottom: 1em;
    }
    .set p:last-child {
      padding-bottom: 0;
    }
    .set dl dt {
      padding-bottom: .2em;
    }
    .set dl dd {
      padding-bottom: 1em;
    }
    .set span {
      padding-left: 1em;
    }
    .set label {
      cursor: pointer;
    }
    .set-btn {
      padding: 0 0 50px;
      font-size: 1.6rem;
      text-align: center;
    }
    .set-btn .alert {
      padding: 1em;
    }
    .meil-list {
      width: 700px;
      margin: auto;
      padding-bottom: 50px;
      font-size: 1.6rem;
    }
    .meil-list em {
      display: block;
      font-style: normal;
      padding: .5em 0 1em;
    }
    .meil-list table {
      width: 100%;
      border-collapse: collapse;
      border: rgb(130,180,255) solid 1px;
    }
    .meil-list table th {
      border: rgb(130,180,255) solid 1px;
      background: rgb(215,240,255);
      font-weight: normal;
      padding: .5em;
    }
    .meil-list table td {
      border: rgb(130,180,255) solid 1px;
      padding: .5em;
    }
  }
</style>
