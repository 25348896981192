<template>
  <main>
    <Header />
    <div class="cont">
      <div class="BU-choice">
        <select v-model="selectingBaseUnitId">
          <option
            v-for="baseUnit in baseUnitsOfFacility"
            :key="baseUnit.id"
            :value="baseUnit.id"
          >{{ baseUnit.place_name }}</option>
        </select>&nbsp;
        <button v-on:click="moveToDashBoard(selectingBaseUnitId)">この場所に移動</button>
      </div>
      <div class="page-tit">
        <h1>子機設定 : <span v-if="facilityName">{{ facilityName }}</span></h1>
        <p class="nav-links">
           <span class="link" v-on:click="moveToBaseUnit(extensionUnitDetail.base_unit_id)">デバイス設定に戻る</span>
        </p>
      </div>

      <div class="unit-setting" v-if="extensionUnitDetail">
        <p>
          <span><em>子機の場所（＊必須）</em></span>
          <input type="text" v-model="extensionUnitDetail.room_name">
        </p>
        <p>
          <em>サイレンのボリューム</em><br/>
          <VueSlider class="slider" v-model="sliderValues.alermVolume" :min="0" :max="10"/>
        </p>
        <p>
          <em>LEDの明るさ</em><br/>
          <VueSlider class="slider" v-model="sliderValues.ledBrightness" :min="0" :max="10"/>
        </p>
        <p>
          <em>シリアルナンバー</em>
          {{ extensionUnitDetail.device_id }}
        </p>

        <p class="save">
         <button v-on:click="saveConfig">子機情報を保存する</button>
        </p>
        <p class="alert" v-if="saveSuccess === true">保存が完了しました</p>
        <p class="alert" v-if="saveSuccess === false">保存が失敗しました</p>
      </div>

    </div>
  </main>
  <Footer />
</template>

<script>
import Header from '../Header'
import Footer from '../Footer'
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/default.css'
import { apiGet, apiPost } from '../../api'

export default {
  name: 'DeviceConfigBaseUnit',
  components: {
    Header,
    Footer,
    VueSlider,
  },
  data () {
    return {
      extensionUnitId: this.$route.query.id,
      extensionUnitDetail: null,
      facilityName: null,
      baseUnitsOfFacility: null,
      selectingBaseUnitId: null,
      sliderValues: {
        alermVolume: 0,
        ledBrightness: 0,
      },
      saveSuccess: null
    }
  },
  watch: {
    '$route': function(){
        this.extensionUnitId = this.$route.query.id
        this.loadData()
    }
  },
  mounted() {
    this.loadData()
  },
  methods: {
    loadData: async function() {
      await this.getExtensionUnit()
      this.getBaseUnit()
    },
    getBaseUnit: async function() {
      try {
        const response = await apiGet(`/api/base_unit?id=${this.extensionUnitDetail.base_unit_id}`, this.$router.app.$cookies)
        this.baseUnitDetail = response.data
        this.facilityName = response.data.facility_name
        this.baseUnitsOfFacility = response.data.base_units_of_facility
        this.selectingBaseUnitId = this.extensionUnitDetail.base_unit_id
      } catch(err) {
        console.error(err)
      }
    },
    getExtensionUnit: async function() {
      try {
        const response = await apiGet(`/api/extension_unit?id=${this.extensionUnitId}`, this.$router.app.$cookies)
        this.extensionUnitDetail = response.data
        this.sliderValues.alermVolume = Math.floor(this.extensionUnitDetail.alerm_volume / 10)
        this.sliderValues.ledBrightness = Math.floor(this.extensionUnitDetail.led_brightness / 10)
      } catch (err) {
        console.error(err)
      }
    },
    saveConfig: async function() {
      try {
        const payload = {
          id: this.extensionUnitDetail.id,
          room_name: this.extensionUnitDetail.room_name,
          alerm_volume: this.sliderValues.alermVolume * 10,
          led_brightness: this.sliderValues.ledBrightness * 10
        }
        const response = await apiPost(`/api/extension_unit`, payload, this.$router.app.$cookies)
        if (response.data) {
          this.saveSuccess = true
        } else {
          this.saveSuccess = false
        }
      } catch (err) {
        console.error(err)
      }
    },
    moveToDashBoard: function(baseUnitId) {
      this.$router.push(`/place_detail?base_unit_id=${baseUnitId}`)
    },
    moveToBaseUnit: function(baseUnitId) {
      this.$router.push(`/device_config/base_unit?id=${baseUnitId}`)
    },
  }
}
</script>

<style scoped>
  @media all and (max-width: 580px) {
    .cont {
      font-size: 3.8vw;
    }
    .cont .unit-setting {
      padding-bottom: 2em;
    }
    .cont .unit-setting em {
      font-style: normal;
      font-weight: normal;
      color: rgb(180,120,60);
    }
    .cont .unit-setting {
      margin: 0 auto;
    }
    .cont .unit-setting p {
      padding-bottom: 1em;
    }
    .cont .save {
      padding: 2em 0 1em;
      text-align: center;
    }
  }
  @media all and (min-width: 580px) {
    .cont {
      font-size: 1.6rem;
    }
    .cont .unit-setting em {
      font-style: normal;
      font-weight: normal;
      color: rgb(180,120,60);
    }
    .cont .unit-setting {
      width: 700px;
      margin: 0 auto;
    }
    .cont .unit-setting p {
      padding-bottom: 1em;
    }
    .cont .save {
      padding: 2em 0 1em;
      text-align: center;
    }
  }
  .slider {
    width: 100% !important;
  }
</style>
