<template>
  <main>
    <Header />
    <div class="cont">
      <div class="page-tit">
        <h1>アラート送信先メールアドレス</h1>
        <p class="nav-links">
          <span class="link" v-on:click="backToEditAccount">アカウントに戻る</span>
        </p>
      </div>

      <table>
        <thead>
          <th>メールアドレス</th>
          <th>名前</th>
          <th></th>
          <th></th>
        </thead>
        <tbody>
          <tr v-for="alertEmailAddress in alertEmailAddresses" :key="alertEmailAddress.id" :class="getEditingClass(alertEmailAddress.id)">
            <td>{{ alertEmailAddress.email }}</td>
            <td>{{ alertEmailAddress.name }}</td>
            <td v-on:click="editAlertEmailAddress(alertEmailAddress)"><span class="linktxt">編集</span></td>
            <td v-on:click="deleteAlertEmailAddress(alertEmailAddress)"><span class="linktxt">削除</span></td>
          </tr>
        </tbody>
      </table>

      <div class="box" v-if="editingAlertEmailAddress === null">
        <p>アラートでメールを飛ばす先を追加します。</p>
        <div class="entry">
          <p>
            <span>追加メールアドレス（必須）</span>
            <input v-model="inputEmail" type="text" placeholder="追加メールアドレス" />
          </p>
          <p>
            <span>追加名（必須）</span>
            <input v-model="inputName" type="text" placeholder="追加名（必須）" />&nbsp;
            <button v-on:click="addAlertEmailAddress">メールを追加</button>
          </p>
        </div>
      </div>
      <div class="box" v-else>
        <p>アラートでメールを飛ばす先を追加・編集できます。
        <span><a v-on:click="stopEditing">編集モードをやめる</a></span></p>
        <div class="entry">
          <p>
            <span>追加メールアドレス（必須）</span>
            <input v-model="editingAlertEmailAddress.email" type="text" placeholder="追加メールアドレス" />
          </p>
          <p>
            <span>追加名（必須）</span>
            <input v-model="editingAlertEmailAddress.name" type="text" placeholder="追加名（必須）" />&nbsp;
            <button v-on:click="saveAlertEmailAddress">編集内容を保存</button>
          </p>
        </div>
      </div>

      <p class="alert" v-if="saveSuccess === true">保存が完了しました</p>
      <p class="alert" v-if="failureMessage">{{ failureMessage }}</p>

    </div>
  </main>
  <Footer />
</template>

<script>
import Header from '../Header'
import Footer from '../Footer'
import { apiGet, apiPost } from '../../api'

export default {
  name: 'EditAccountAlertEmailSetting',
  components: {
    Header,
    Footer
  },
  data: function() {
    return {
      alertEmailAddresses: [],
      editingAlertEmailAddress: null,
      inputEmail: '',
      inputName: '',
      saveSuccess: null,
      failureMessage: null
    }
  },
  mounted() {
    this.loadUserDetail()
  },
  methods: {
    loadUserDetail: async function() {
      try {
        const response = await apiGet('/api/user_detail/me', this.$router.app.$cookies)
        this.alertEmailAddresses = response.data.alert_mail_addresses
      } catch (err) {
        console.error(err)
      }
    },
    addAlertEmailAddress: async function() {
      if(!this.checkInput(this.inputEmail, this.inputName)) {
        return
      }

      const filtered = this.alertEmailAddresses.filter(current => {
        return current.email === this.inputEmail
      })
      if (filtered.length > 0) {
        this.failureMessage = '同じメールアドレスがすでに登録されています。'
        return
      }

      const payload = {
        email: this.inputEmail,
        name: this.inputName
      }
      try {
        const response = await apiPost(`/api/user_detail/alert_email_address/create`, payload, this.$router.app.$cookies)
        if (response.data) {
          this.saveSuccess = true
          this.failureMessage = null
          this.loadUserDetail()
        } else {
          this.failureMessage = '保存に失敗しました。'
        }
      } catch(err) {
        console.error(err)
      }
    },
    editAlertEmailAddress: function(alertEmailAddress) {
      this.editingAlertEmailAddress = structuredClone(alertEmailAddress)
    },
    stopEditing: function() {
      this.editingAlertEmailAddress = null
    },
    saveAlertEmailAddress: async function() {
      if(!this.checkInput(this.editingAlertEmailAddress.email, this.editingAlertEmailAddress.name)) {
        return
      }

      const payload = {
        id: this.editingAlertEmailAddress.id,
        email: this.editingAlertEmailAddress.email,
        name: this.editingAlertEmailAddress.name,
      }
      try {
        const response = await apiPost(`/api/user_detail/alert_email_address/update`, payload, this.$router.app.$cookies)
        if (response.data) {
          this.saveSuccess = true
          this.failureMessage = null
          this.loadUserDetail()
        } else {
          this.failureMessage = '保存に失敗しました。'
        }
      } catch(err) {
        console.error(err)
      }
    },
    deleteAlertEmailAddress: async function(alertEmailAddress) {
      const payload = {
        id: alertEmailAddress.id
      }
      try {
        const response = await apiPost(`/api/user_detail/alert_email_address/delete`, payload, this.$router.app.$cookies)
        if (response.data) {
          this.saveSuccess = true
          this.failureMessage = null
          this.loadUserDetail()
        } else {
          this.failureMessage = '削除に失敗しました。'
        }
      } catch(err) {
        console.error(err)
      }
    },
    backToEditAccount: function() {
      this.$router.push(`/edit_account`)
    },
    getEditingClass: function(addressId) {
      if (this.editingAlertEmailAddress === null) {
        return ''
      }
      return this.editingAlertEmailAddress.id === addressId ? 'editing' : ''
    },
    checkInput: function(email, name) {
      const emailRegex = /^[a-zA-Z0-9_+-]+(\.[a-zA-Z0-9_+-]+)*@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/
      if (!emailRegex.test(email)) {
        this.failureMessage = '正しいメールアドレスを入力してください。'
        return false
      }
      if(name === '') {
        this.failureMessage = '名前を入力してください。'
        return false
      }

      this.failureMessage = null
      return true
    }
  }
}
</script>

<style scoped>
.editing {
  background-color: rgb(240, 200, 200);
}
.linktxt {
  color: rgb(50,140,255);
  transition: all  0.3s ease;
}
.linktxt:hover {
  color: rgb(25, 90, 170);
}
@media all and (max-width: 580px) {
  .cont input {
    font-size: 3.8vw;
    padding: .2em;
  }
  .cont div p {
    font-size: 3.8vw;
    padding-bottom: 1em;
  }
  .cont .box p span {
    display: block;
    font-size: 3.8vw;
    padding-right: .6em;
  }
  .cont table {
    font-size: 1.6rem;
    width: 100%;
    background: rgb(255,255,255);
  }
  .cont table th {
    font-weight: normal;
    border: rgb(130,180,255) solid 1px;
    background: rgb(215,240,255);
    text-align: center;
    padding: .5em;
    width: 22%;
  }
  .cont table th:first-child {
    width: auto;
  }
  .cont table th:nth-last-of-type(2),
  .cont table th:last-child {
    width: 18%;
  }
  .cont table td {
    border: rgb(130,180,255) solid 1px;
    padding: .5em;
  }
  .cont table td:nth-last-of-type(2),
  .cont table td:last-child {
    text-align: center;
    padding: .5em 0;
  }
  .alert {
    font-size: 4vw;
    padding-bottom: 1em;
  }
}
@media all and (min-width: 580px) {
  .cont input {
    font-size: 1.6rem;
    padding: .4em .2em;
    width: 450px;
  }
  .cont div p {
    font-size: 1.6rem;
    padding-bottom: .5em;
  }
  .cont .box p span {
    display: inline-block;
    font-size: 1.6rem;
    padding-right: .6em;
    width: 14em;
  }
  .cont table {
    font-size: 1.6rem;
    width: 100%;
    margin: 20px 0 40px;
  }
  .cont table th {
    font-weight: normal;
    border: rgb(130,180,255) solid 1px;
    background: rgb(215,240,255);
    text-align: center;
    padding: .5em;
    width: 22%;
  }
  .cont table th:first-child {
    width: auto;
  }
  .cont table th:nth-last-of-type(2),
  .cont table th:last-child {
    width: 15%;
  }
  .cont table td {
    border: rgb(130,180,255) solid 1px;
    padding: .5em;
  }
  .cont table td:nth-last-of-type(2),
  .cont table td:last-child {
    text-align: center;
  }
  .alert {
    font-size: 1.6rem;
    padding-bottom: 1em;
  }
}
</style>
