<template>
  <Header />
    <main>
      <div class="cont">
        <BackButton/>
        <h1>利用規約</h1>
        <h2>Wave Sense サービス利用規約</h2>
        <dl>
          <dt>第1条 本規約の目的</dt>
          <dd>
            <p>本規約は、セキュアリンク株式会社（以下、弊社）が弊社担当者や代表メール、弊社ユーザーインターフェイスやオンラインショップを通じた商品の購入、弊社が提供するセキュリティサービス「Wave Sense」の定期購入およびその利用について、その諸条件を定めるものです。利用者は、弊社の商品または「Wave Sense」サービスを購入し、また「Wave Sense」サービスを利用することをもって、本規約に同意したものとみなされます。<br>
            また、本サービスである、弊社ユーザーインターフェイスで得られた、すべてのデータは開示することはせず、弊社がシステムの研究開発や改善、商品開発や販売、それに類する活動のみに利用することを承諾するものとする。</p>
          </dd>
          <dt>第2条 本規約の適用範囲および変更</dt>
          <dd>
            <p>本規約は、弊社担当者や代表メール、弊社ユーザーインターフェイスやオンラインショップを通じた弊社の商品の販売ならびに「Wave Sense」サービスの提供およびその利用に関し、弊社および第3条で定義する利用者に適用されるものとします。<br>
            弊社は、法令の改正、社会情勢の変化その他の事情により、本規約を変更する必要が生じた場合には、随時、本規約を変更することができます。この場合、弊社は、ウェブサイトへの掲載等の方法により、本規約を変更する旨、変更後の本規約の内容および変更の効力発生日を利用者に通知します。</p>
          </dd>
          <dt>第3条 利用者</dt>
          <dd>
            <p>「利用者」とは、以下に定める方をいいます。
              弊社担当者や代表メールを通じて弊社の商品を購入される方やユーザーインターフェイスやオンラインショップを通じて「Wave Sense」サービスを利用される方とします。<br>
              弊社が「Wave Sense」サービスのために提供するアプリケーション（以下、本アプリ）の利用等により、「Wave Sense」サービスの利用を行う方（「Wave Sense」サービスを弊社指定の代理店経由で購入され、本アプリを通じて利用するエンドユーザーも含みます）。</p>
          </dd>
          <dt>第4条 利用者登録</dt>
          <dd>
            <p>利用者は、弊社担当者や代表メール、弊社ユーザーインターフェイスやオンラインショップを通じて、弊社の商品または「Wave Sense」サービスを購入される場合、弊社担当者もしくは利用者登録申請を行うものとします。なお、弊社の商品または「Wave Sense」サービスを弊社指定の代理店経由で購入される場合は、利用者登録は代理店もしくは利用者にて登録を行うものとします。<br>
            利用者登録申請者が過去に本規約違反などにより利用者登録の抹消処分を受けていることが判明した場合、第7条各号記載の事由があると判断した場合、利用者登録申請者の申請内容に虚偽の事項が含まれている場合または、その他利用者登録申請を承認することが不適当であると弊社が合理的に判断する場合には、当該利用者登録申請を承認しない場合があります。<br>
            利用者登録は日本国内に住所を有する方で、かつ日本国内で「Wave Sense」サービスを利用する方に限ります。<br>
            利用者登録申請者が未成年の場合、法定代理人の同意を得て利用者登録申請を行うものとします。<br>
            利用者は、弊社ユーザーインターフェイスを利用する PC、携帯電話、スマートフォン等の端末機器ならびにメールアドレスやパスワードを自らの責任で、管理するものとします。弊社は、これらの管理が不十分であったこと、登録情報が不正確もしくは虚偽であったこと、または弊社に虚偽の情報を取得させたことに関連した利用者の損害に対して責任を負いません。<br>
            利用者は、住所、氏名、電話番号またはその他登録事項に変更が生じた場合には、弊社が別途指定する方法により届け出るものとします。<br>
            弊社は、利用者登録時に登録したメールアドレスに対して、利用者向けサービスのご案内を行うことがあります。</p>
          </dd>
          <dt>第5条 商品の購入</dt>
          <dd>
            <p>利用者は、弊社担当者や代表メール、弊社ユーザーインターフェイスやオンラインショップを通じて、「Wave Sense 端末セット」その他の弊社の商品を購入することができます。<br>
            利用者が、商品の購入を希望する場合は、弊社が別途定めるご利用ガイド等の定めその他弊社が指定する方法に従って商品の購入を申し込むものとします。<br>
            弊社が利用者に対して、前項の申し込みを承諾する旨の通知を利用者宛に発信した時点で利用者と弊社との間に当該商品に関する売買契約が成立するものとします。<br>
            弊社担当者や代表メール、弊社ユーザーインターフェイスやオンラインショップを通じた商品の販売、配送は、日本国内に限るものとします。</p>
          </dd>
          <dt>第6条 「Wave Sense」サービスの定期購入</dt>
          <dd>
            <p>利用者は、弊社担当者や代表メールや弊社ユーザーインターフェイスを通じて、「Wave Sense」サービスを定期購入することができます。<br>
            利用者が、定期購入を希望する場合は、弊社が別途定めるご利用ガイド等の定めその他弊社が指定する方法に従って「Wave Sense」サービスの契約を申し込むものとします。<br>
            弊社が利用者に対して、前項の申し込みを承諾する旨の通知を利用者宛に発信した時点で利用者と弊社との間に当該サービスに関する契約が成立するものとします。<br>
            「Wave Sense」サービスの利用料金および契約期間は、「Wave Sense」契約書に記載のとおりとします。<br>
            お申込みされた当初の契約期間が満了した後は、契約期間は1年毎に自動更新され、その後も契約期間満了日前までに弊社が別途定める方法による継続停止手続きを行わない限り、さらに1年毎に自動的に延長されます。但し、個別契約において別途定めた場合は、当該定めによるものとします。なお、弊社の指定代理店経由で「Wave Sense」サービスを購入された利用者は、当該サービスの契約期間の更新および継続停止について、当該代理店にお問い合わせいただきますようお願いいたします。<br>
            弊社は、世界情勢や経済事情、物価の変動、または「Wave Sense」のサービス内容の変更、拡張等によって利用料金等を変更する必要が生じた場合には、利用料金を改定することができるものとします。利用料金を変更する場合は、事前に利用者に通知するものとします。</p>
          </dd>
          <dt>第7条 「Wave Sense」サービスの提供・利用</dt>
          <dd>
            <p>利用者は、「Wave Sense」サービスを、弊社が指定する Wi-Fi 端末、弊社（または弊社の指定する代理店）が提供するモバイル端末（Android 端末、iOS 端末）用アプリケーションおよび、弊社が提供するクラウドシステムを通じてのみ利用するものとします。<br>
            「Wave Sense」サービスのカスタマーサポート、問い合わせは全て以下のメールアドレスを経由して行うものとし、弊社が指定する営業時間内で対応いたします。<br>
            お問い合わせ先：info@securelink-inc.co.jp<br>
            利用者が「Wave Sense」サービスを利用するのに必要なモバイル端末や OS 等のソフトウェアなどの利用、設置に関する費用、「Wave Sense」サービスを利用するために要した電話料金、LANなどの利用料および申請料金などは、利用者ご自身が負担するものとします。<br>
            「Wave Sense」サービスおよび「Wave Sense」サービスに必要なアプリケーションの利用は日本国内に限るものとします。利用者の日本国外での利用に関する不利益、損害に関しては一切関知、保証しません。</p>
          </dd>
          <dd>第8条 「Wave Sense」サービスの提供停止、利用者登録の抹消</dd>
          <dd>
            <p>弊社は、利用者が以下で定める各号に該当する場合、利用者に事前通知することなく「Wave Sense」サービスの提供の停止または利用者登録の抹消を行うことができるものとします。 過去に本規約違反などにより利用者登録の抹消処分を受けていることが判明した場合 弊社の商品または「Wave Sense」サービスに関する代金支払の遅延その他債務不履行があった場合 第10条に定める(禁止事項)に該当する行為を行った場合 未成年者が法定代理人の同意を得ずに利用者登録し、または、弊社ユーザーインターフェイスもしくは「Wave Sense」サービスの利用を行った場合<br>
            第18条に定める反社会的勢力に該当する場合<br>
            その他本規約に違反した場合</p>
          </dd>
          <dt>第9条 「Wave Sense」サービスの解約および退会</dt>
          <dd>
            <p>利用者は、「Wave Sense」サービスの解約または弊社担当者や代表メールや弊社ユーザーインターフェイスからの退会を希望する場合には、弊社が指定する方法に従い、サービス解約申請または退会申請を行うものとします。<br>
            「Wave Sense」サービスを解約された場合でも、弊社ユーザーインターフェイスの利用者登録は抹消されません。利用者登録を抹消するためには、併せて退会の手続きが必要になりますので、ご留意ください。<br>
            弊社は、利用者の解約申請・退会申請を受領した時点で、速やかに解約・退会の処理を行うものとします。また、利用者の契約満了に満たない中途確約は、別途違約金や保証金徴収や撤去料金が請求される。詳細は弊社担当者や代理店担当者にお問い合わせください。<br>
            弊社担当者や代表メールや弊社ユーザーインターフェイスを通じて、「Wave Sense」サービスを購入された利用者は、弊社担当者や代表メールや弊社ユーザーインターフェイスからの退会の手続が完了した時点で全てのサービスを受けることができなくなります。<br>
            弊社は利用者の退会後も、一定期間の間、適用法令に従い利用者の情報を保持する場合があります。<br>
            退会、解約の申請方法は 弊社担当者や代表メールもしくは、お問い合わせフォームより、お問い合わせください。<br>
            お問い合わせ先：info@securelink-inc.co.jp</p>
          </dd>
          <dt>第10条 禁止事項</dt>
          <dd>
            <p>利用者は以下の行為を行ってはならないものとします。</p>
            <ul>
              <li>利用者登録の際に虚偽の登録内容を申請する行為</li>
              <li>弊社ユーザーインターフェイスおよび「Wave Sense」サービスの運営を妨げる行為、その他、弊社ユーザーインターフェイスやオンラインショップおよび「Wave Sense」サービスに支障をきたす恐れのある行為</li>
              <li>クレジットカードを不正使用して弊社ユーザーインターフェイスやオンラインショッおよび「Wave Sense」サービスを利用する行為</li>
              <li>メールアドレスおよびパスワードを不正に使用する行為</li>
              <li>転売、再販売、その他営利を目的として弊社担当者や代表メール、弊社ユーザーインターフェイスやオンラインショップおよび「Wave Sense」サービスにより商品を購入する行為</li>
              <li>弊社が配布したクーポン等を、有償・無償を問わず第三者に譲渡する行為</li>
              <li>「Wave Sense」サービスのソースコードの修正、逆アセンブル、逆コンパイル、二次的著作物の作成、リバースエンジニアリング、またはその他の方法で「Wave Sense」サービスのソースコードへのアクセスを試みること</li>
              <li>「Wave Sense」サービスを提供するためのサーバー、ネットワークの運営を妨害する行為</li>
              <li>他の利用者、第三者もしくは弊社に迷惑、不利益もしくは損害を与える行為、またはそれらの恐れのある行為</li>
              <li>公序良俗に反する行為その他法令に違反する行為、またはそれらの恐れのある行為</li>
              <li>複数の会員登録を不正に申請または保有する行為</li>
              <li>その他、弊社が不適当と合理的に判断する行為</li>
            </ul>
          </dd>
          <dt>第11条 知的財産権</dt>
          <dd>
            <p>弊社ユーザーインターフェイスやオンラインショップおよび「Wave Sense」サービスにおいて使用されている文言、画像およびデザイン等に関する著作権または商標権、その他全ての知的財産権は、弊社または弊社にその利用を許諾した著作権者等正当な権利者に帰属するものであり、利用者は複製、譲渡、貸与、翻訳、改変、転載、公衆への送信（公衆への送信を可能とすることを含みます。）、転送、配布、出版、営業のための使用その他これらの権利を侵害する行為を行わないものとします。<br>
            本条の規定に違反して権利者あるいは第三者との間で問題が生じた場合、弊社の責めに帰すべき事由によるときを除き、利用者は自己の責任と費用においてその問題を解決するとともに、弊社に何の迷惑または損害を与えないものとします。</p>
          </dd>
          <dt>第12条 ID およびパスワードの管理</dt>
          <dd>
            <p>利用者は、自らの責任において、利用者自身で設定した ID（メールアドレス）およびパスワードを管理するものとします。<br>
            弊社は、弊社の責めに帰すべき事由によるときを除き、利用者による ID（メールアドレス）およびパスワードの不適切な管理、使用上の過誤、第三者による使用などに起因する損害について一切の責任を負わないものとします。<br>
            利用者は、ID（メールアドレス）およびパスワードが第三者によって不正に使用されていることが判明した場合には、直ちに弊社に連絡するものとします。<br>
            利用者は、弊社担当者や代表メール、弊社ユーザーインターフェイスやオンラインショップの利用の際に行うクレジットカード番号の送信行為などに伴う漏洩などの危険性を認識し、自己の責任の下にこれを行うものとします。</p>
          </dd>
          <dt>第13条 利用者情報</dt>
          <dd>
            <p>弊社担当者や代表メール、弊社ユーザーインターフェイスやオンラインショップの利用に際し、利用者に関する以下の情報（以下「利用者情報」といいます）を取得します。弊社は、利用者情報を、プライバシーポリシーに基づき取り扱います。</p>
            <ol>
              <li>第4条に基づき提供された会員登録情報</li>
              <li>利用者の識別情報、サイト内の行動履歴情報およびコンピュータに関する情報と接続情報利用者は、以下に定める方法により、自己の個人情報の更新・利用停止を行うことができます。</li>
            </ol>
          </dd>
          <dt>第14条 購入のキャンセル、返品、返金</dt>
          <dd>
            <p>弊社担当者や代表メールや弊社ユーザーインターフェイス・オンラインショップを通じた商品の購入および「Wave Sense」サービスの初回の定期購入については、購入日から30日間は理由の如何に問わず、返金、返品を保証いたします。次項に定める場合を除き、返品に係る送料は利用者にご負担いただきます。<br>
            弊社担当者や代表メール、ユーザーインターフェイスやオンラインショップを通じて購入された商品について、購入日から30日以内に不具合が認められた場合、利用者は、端末の無償交換もしくは返品のいずれかを選択することができます。不具合を見つけた場合は、My Hex Shop お問い合わせ窓口および、Wi-Fiセンシングお問い合わせまでご連絡ください。 <br>
            お問い合わせ先：info@securelink-inc.co.jp<br>
            弊社担当者や代表メール、ユーザーインターフェイスやオンラインショップを通じて購入された商品の保証期間は、1年間です。ご購入後30日経過後、1年以内に商品に不具合が認められた場合は、利用者の負担によりご返送いただくことにより、商品の無償交換を行うものとします。</p>
          </dd>
          <dt>第15条 契約の解除その他必要な措置</dt>
          <dd>
            <p>次の各号にあたる事由が生じた場合には、弊社は商品の売買契約および「Wave Sense」サービスの提供に関する契約を解除することができるものとします。</p>
            <ol>
              <li>利用者が本規約に違反した場合</li>
              <li>利用者指定のクレジットカード会社からカード与信不履行の旨の連絡があった場合</li>
              <li>利用者の支払い能力が危うくなったと認めうる事情が判明した場合</li>
              <li>商品が品切れとなり、容易にお届けできない場合</li>
              <li>届先不明・長期不在、その他の理由によりお届けできない場合</li>
              <li>長期不在、指定された受け取り場所での一定期間商品が受取られない場合</li>
              <li>未成年者が法定代理人の承認を得ずに購入していた場合</li>
              <li>弊社がサービスを継続することが不可能となった場合弊社は、利用者、弊社または第三者のセキュリティを確保する必要が生じた場合、「Wave Sense」サービスへの遮断、「Wave Sense」サービスの全部もしくは一部の提供の停止もしくは中止、ID（メールアドレス）およびパスワードの登録、会員登録の抹消、またはその他の適切な措置をとることができるものとします。</li>
            </ol>
          </dd>
          <dt>第16条 サービスの停止、終了</dt>
          <dd>
            <p>弊社は、次の各号に該当する場合には、利用者に事前に通知を行うことなく弊社担当者や代表メール、ユーザーインターフェイスやオンラインショップおよび「Wave Sense」サービスの全部あるいは一部の提供を停止することができ、当該事由に起因して利用者または第三者に損害が発生した場合、一切の責任を負わないものとします。</p>
            <ol>
              <li>システムの定期保守および緊急保守のために必要な場合</li>
              <li>火災、停電、天災地変等の非常事態によりサービスの提供が不能となった場合</li>
              <li>戦争、内乱、暴動、騒擾、労働争議、第三者による妨害行為などによりシステムの運用が困難になった場合</li>
              <li>コンピューターシステムの不良および第三者からの不正アクセス、コンピューターウイルスの感染等により本サービスの提供が不能となった場合</li>
              <li>法律、法令等に基づく措置により本サービスの提供が不能となった場合</li>
              <li>その他、止むを得ずシステムの停止が必要と弊社が判断した場合弊社が、弊社オンラインショップおよび「Wave Sense」サービスの提供を終了する場合には、3ヶ月前にメールその他弊社が定める適切な方法により、告知するものとします。</li>
            </ol>
          </dd>
          <dt>第17条 その他免責事項</dt>
          <dd>
            <p>弊社は、利用者に対して通知義務を負う場合は、利用者があらかじめ登録しているメールアドレスへ通知をすることにより、また、商品のお届けについては、商品、および初回定期購入の際、利用者が指示した送付先に商品を配送、登録しているメールアドレスに通知することにより、その義務を果たしたものとします。<br>
            弊社が、弊社担当者や代表メール、ユーザーインターフェイスやオンラインショップで販売するWave Sense端末セットその他の機器は、「Wave Sense」サービス目的での利用を唯一の目的として販売されるものです。利用者が、これ以外の目的で機器を利用されることにより生じた不利益、損害に関して、弊社は一切関知、保証しません。<br>
            弊社は、利用者が本サービスを利用する際に使用する機器、回線について、一切の動作保証をせず、これらの環境等に基づき生じた損害について、賠償する義務を一切負わないものとします。<br>
            弊社は、法律上の請求原因の如何を問わず、「Wave Sense」サービスの利用に関して弊社の責めに帰すべからざる事由から利用者に生じた損害、損失、不利益等について責任を負わないものとします。<br>
            「Wave Sense」サービスは、空間感知テクノロジーにより、モバイル端末による住まいの様子確認を可能にするものであり、盗難、第三者による侵入からの完全なセキュリティ効果や、設置場所におけるすべての動きを検知する機能を保証するものではありません。<br>
            「Wave Sense」サービスの提供はベストエフォートとし、弊社は、「Wave Sense」端末、クラウドおよびモバイルアプリケーションの動作およびサービスの継続について何ら保証しないものとします。弊社は、「Wave Sense」サービスの変更、提供中止、停止、故障等により、利用者が「Wave Sense」サービスをご利用できなかったことにより発生した一切の損害について、いかなる責任も負わないものとします。<br>
            利用者が、「Wave Sense」サービスをご利用することで、第三者に対して損害等を与えた場合には、当該利用者は自己の責任と費用において解決し、弊社には一切迷惑をかけないものとします。<br>
            弊社は、利用者のパスワードの変更等を行う場合、弊社指定の方法で本人確認を行うこととし、当該方法に過誤がない限りにおいて免責されるものとします。<br>
            本規約の免責条項が適用されない等の理由で弊社が利用者または第三者に対して責任を負うべき場合、弊社に故意または重過失がある場合を除き、それらの損害賠償額はその件数を問わず、いかなる場合でも利用者が「Wave Sense」サービスを利用するにあたって弊社に支払った料金を上限額とすることを、弊社および利用者はあらかじめ合意するものとします。</p>
          </dd>
          <dt>第18条 反社会的勢力の排除</dt>
          <dd>
            <p>利用者は、弊社に対し、以下の各号の事項を確約します。</p>
            <ol>
              <li>自らが、暴力団、暴力団関係企業、総会屋もしくはこれらに準ずる者または構成員（以下「反社会的勢力」といいます。）でないこと。</li>
              <li>反社会的勢力に自己の名義を利用させ、または反社会的勢力の利益に供するために弊社ユーザーインターフェイスやオンラインショップおよび「Wave Sense」サービスを利用するものでないこと。</li>
              <li>自らまたは第三者を利用して、弊社に対して脅迫的な言動または暴力を用いる行為や偽計または威力を用いて弊社の業務を妨害しまたは信用を毀損する行為を行わないこと。弊社は、利用者が前項に違反した場合、当該利用者に何らかの通知または催告なく、会員登録抹消または契約の解除その他本規約に定める措置を講じることができるものとします。</li>
            </ol>
            <p>前項の措置により発生した一切の損害について、弊社は損害賠償、補償その他の責任を負いません。また、利用者は、第1項に違反したことにより弊社に生じた損害等を賠償するものとします。</p>
          </dd>
          <dt>第19条 準拠法および合意管轄</dt>
          <dd>
            <p>本規約、弊社ユーザーインターフェイスやオンラインショップおよび「Wave Sense」サービスに関する準拠法は、日本法とします。また、弊社ユーザーインターフェイスやオンラインショップおよび「Wave Sense」サービスの利用に関して生じる紛争（裁判所の調停手続きを含みます。）については、大阪地方裁判所を第一審の専属管轄裁判所とします。</p>
          </dd>
        </dl>
      </div>
    </main>
  <Footer />
</template>

<script>
import Header from './Header'
import Footer from './Footer'

export default {
  name: 'TermOfUse',
  components: {
    Header,
    Footer
  },
}
</script>

<style scoped>
@media all and (max-width: 480px) {
  .cont h2 {
    font-size: 4.4vw;
    padding-bottom: .5em;
  }
  .cont > p {
    font-size: 3.8vw;
    padding-bottom: 2em;
  }
  .cont dl {
    font-size: 3.8vw;
  }
  .cont dl dt {
    color: rgb(70, 125, 210);
  }
  .cont dl dd {
    padding: 0 0 1em 1em;
  }
  .cont dl dd ul li {
    position: relative;
    padding-left: 1em;
  }
  .cont dl dd ul li::before {
    content: '・';
    position: absolute;
    top: 0;
    left: 0;
  }
  .cont dl dd ol {
    counter-reset: number 0;
  }
  .cont dl dd ol li {
    position: relative;
    padding-left: 2.5em;
  }
  .cont dl dd ol li::before {
    counter-increment: number 1;
    content: '（' counter(number) '）';
    position: absolute;
    top: 0;
    left: 0;
  }
}
@media all and (min-width: 480px) {
  .cont h2 {
    font-size: 1.2vw;
    padding-bottom: .5em;
  }
  .cont > p {
    font-size: 1vw;
    padding-bottom: 2em;
  }
  .cont dl {
    font-size: 1vw;
  }
  .cont dl dt {
    color: rgb(70, 125, 210);
  }
  .cont dl dd {
    padding: 0 0 1em 1em;
  }
  .cont dl dd p {
    text-align: left;
  }
  .cont dl dd ul li::before {
    content: '・';
  }
  .cont dl dd ol {
    counter-reset: number 0;
  }
  .cont dl dd ol li::before {
    counter-increment: number 1;
    content: '（' counter(number) '）';
  }
}
</style>
