<template>
  <main>
    <Header />
    <div class="cont">
      <div class="BU-choice">
        <select v-model="selectingBaseUnitId">
          <option
            v-for="baseUnit in baseUnitsOfFacility"
            :key="baseUnit.id"
            :value="baseUnit.id"
          >{{ baseUnit.place_name }}</option>
        </select>&nbsp;
        <button v-on:click="moveToDashBoard(selectingBaseUnitId)">この場所に移動</button>
      </div>
      <div class="page-tit">
        <h1>デバイス設定 : <span v-if="facilityName">{{ facilityName }}</span></h1>
        <p class="nav-links">
          <span class="link" v-on:click="moveToDashBoard(baseUnitId)">ダッシュボードに戻る</span>
        </p>
      </div>
      <div class="unit-setting" v-if="baseUnitDetail">
        <p class="unit-info">
          <span><em>場所名：</em>
            <input type="text" v-model="baseUnitDetail.place_name"/>
          </span>
          <span><em>シリアルナンバー：</em>{{ baseUnitDetail.device_id }}</span>
        </p>

        <em>感度レベル</em><br/>
        <VueSlider class="slider" v-model="sensitivity" :min="0" :max="10" :duration="0"/>
        <p class="save">
          <button v-on:click="saveBaseUnitSetting">設定を保存する</button>
        </p>
        <p class="alert" v-if="saveSuccess === true">保存が完了しました</p>
        <p class="alert" v-if="saveSuccess === false">保存が失敗しました</p>
        <p class="note">
          設定が反映されるまで数分かかる場合があります。
        </p>
        <p class="note">
          感度：低（1～3）<br>
          小さなペット、ロボット掃除機、首振りする扇風機などを検知しません。ペットの活動量が多い場所に適しています。
        </p>
        <p class="note">
          中（4～7）<br>
          通常の見守り・監視に適した数値です。ご利用の環境や用途に合わせてここから感度を増減させてください。
        </p>
        <p class="note">
          高（8～10）<br>
          カーテンの揺らぎや集合住宅の場合お隣の動きも検出してしまう可能性があります。
        </p>

      </div>

      <div class="others-unit">
        <div class="another" v-for="extensionUnit in extensionUnits" :key="extensionUnit.id">
          <p>
            場所名：{{ extensionUnit.room_name }}<br/>
            シリアルナンバー：{{ extensionUnit.device_id }}
          </p>
          <button v-on:click="moveToExtensionUnitConfig(extensionUnit.id)">設定</button>
        </div>
      </div>
    </div>
  </main>
  <Footer />
</template>

<script>
import Header from '../Header'
import Footer from '../Footer'
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/default.css'
import { apiGet, apiPost } from '../../api'

export default {
  name: 'DeviceConfigBaseUnit',
  components: {
    Header,
    Footer,
    VueSlider,
  },
  data () {
    return {
      baseUnitId: this.$route.query.id,
      baseUnitDetail: null,
      extensionUnits: [],
      facilityName: null,
      baseUnitsOfFacility: null,
      selectingBaseUnitId: this.$route.query.id,
      sensitivity: 0,
      saveSuccess: null,
    }
  },
  watch: {
    '$route': function(){
        this.baseUnitId = this.$route.query.id
        this.selectingBaseUnitId = this.$route.query.id
        this.loadData()
    }
  },
  mounted() {
    this.loadData()
  },
  methods: {
    loadData: async function() {
      this.getBaseUnit()
      this.getExtensionUnits()
      this.getSensitivity()
    },
    getBaseUnit: async function() {
      try {
        const response = await apiGet(`/api/base_unit?id=${this.baseUnitId}`, this.$router.app.$cookies)
        this.baseUnitDetail = response.data
        this.facilityName = response.data.facility_name
        this.baseUnitsOfFacility = response.data.base_units_of_facility
      } catch(err) {
        console.error(err)
      }
    },
    getExtensionUnits: async function() {
      try {
        const response = await apiGet(`/api/extension_unit?base_unit_id=${this.baseUnitId}`, this.$router.app.$cookies)
        this.extensionUnits = response.data
      } catch (err) {
        console.error(err)
      }
    },
    getSensitivity: async function() {
      try {
        const response = await apiGet(`/api/device_config?base_unit_id=${this.baseUnitId}`, this.$router.app.$cookies)
        this.sensitivity = Math.floor(response.data.operationInfo.overallSensitivity / 10)
      } catch(err) {
        console.error(err)
      }
    },
    saveBaseUnitSetting: async function() {
      this.savePlaceName()
      this.saveSensitivity()
    },
    savePlaceName: async function() {
      try {
        const payload = {
          id: this.baseUnitId,
          facility_id: this.baseUnitDetail.facility_id,
          place_name: this.baseUnitDetail.place_name,
        }
        await apiPost(`/api/base_unit`, payload, this.$router.app.$cookies)
        this.getBaseUnit()
      } catch(err) {
        console.error(err)
      }
    },
    saveSensitivity: async function() {
      try {
        const payload = {
          base_unit_id: this.baseUnitId,
          sensitivity: this.sensitivity * 10
        }
        const response = await apiPost(`/api/device_config/sensitivity`, payload, this.$router.app.$cookies)
        this.saveSuccess = response.data
      } catch(err) {
        console.error(err)
      }
    },
    moveToDashBoard: function(baseUnitId) {
      this.$router.push(`/place_detail?base_unit_id=${baseUnitId}`)
    },
    moveToExtensionUnitConfig: function(extensionUnitId) {
      this.$router.push(`/device_config/extension_unit?id=${extensionUnitId}`)
    },
  }
}
</script>

<style scoped>
  @media all and (max-width: 580px) {
    .cont {
      font-size: 3.8vw;
    }
    .cont .unit-setting em {
      font-style: normal;
      font-weight: normal;
      color: rgb(180,120,60);
    }
    .cont .unit-setting .unit-info {
      padding-bottom: 1em;
    }
    .cont .unit-setting .unit-info span {
      display: block;
      padding-bottom: .4em;
    }
    .cont .save {
      padding: 1em 0 2em;
      text-align: center;
    }
    .cont .others-unit {
      margin: 5vw 0 0;
      padding: 5vw 0 0;
      border-top: rgb(60, 125, 220) dashed 1px;
    }
    .cont .others-unit .another {
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
      padding-bottom: 1em;
    }
    .cont .others-unit .another p {
      width: 70%;
    }
    .cont .others-unit .another button {
      width: 25%;
    }
    .cont .note {
      padding-bottom: .8em;
    }
  }
  @media all and (min-width: 580px) {
    .cont {
      font-size: 1.6rem;
    }
    .cont .unit-setting {
      width: 700px;
      margin: 0 auto;
    }
    .cont .unit-setting em {
      font-style: normal;
      font-weight: normal;
      color: rgb(180,120,60);
    }
    .cont .unit-setting .unit-info {
      padding-bottom: 1em;
    }
    .cont .unit-setting .unit-info span {
      padding-right: 2em;
    }
    .cont .save {
      padding: 2em 0 1em;
      text-align: center;
    }
    .cont .others-unit {
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
      margin: 40px 0 0;
      padding: 40px 0 0;
      border-top: rgb(60, 125, 220) dashed 1px;
    }
    .cont .others-unit .another {
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
      width: 46%;
      padding-bottom: 30px;
    }
    .cont .others-unit .another p {
      width: 70%;
    }
    .cont .others-unit .another button {
      width: 25%;
    }
    .cont .note {
      padding-bottom: .8em;
    }
    .cont .alert {
      padding-bottom: 2em;
    }
  }
  .slider {
    width: 100% !important;
  }
</style>
