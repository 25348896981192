<template>
  <Header />
    <main>
      <div class="cont">
        <BackButton/>
        <h1>ヘルプ</h1>
        <section class="app">
          <h2>アプリについて</h2>
          <div class="note icon">
            <h3>一覧画面アイコンの意味</h3>
            活動状況は活動のレベルに応じてアイコンの色の濃淡が変化します。<br>
            また、観測機器に近いほど動きの検出も大きくなります。
            <dl>
              <dt><img src="@/assets/img/icon-act-l04.svg" alt="検知レベル5" /></dt>
              <dd>
                <em>検知レベル5</em>:移動しているなど活動の度合いが大きい状態など。
              </dd>
            </dl>
            <dl>
              <dt><img src="@/assets/img/icon-act-l04.svg" alt="検知レベル4" /></dt>
              <dd>
                <em>検知レベル4</em>:大きく動いている状態など。
              </dd>
            </dl>
            <dl>
              <dt><img src="@/assets/img/icon-act-l03.svg" alt="検知レベル3" /></dt>
              <dd>
                <em>検知レベル3</em>:腕など体の一部を動かしている状態など。
              </dd>
            </dl>
            <dl>
              <dt><img src="@/assets/img/icon-act-l02.svg" alt="検知レベル2" /></dt>
              <dd>
                <em>検知レベル2</em>:座って作業しているなど動きの少ない状態など。
              </dd>
            </dl>
            <dl>
              <dt><img src="@/assets/img/icon-act-l01.svg" alt="検知レベル1" /></dt>
              <dd>
                <em>検知レベル1</em>:安静にしているか、極めて動作が小さい状態など。
              </dd>
            </dl>
            <dl>
              <dt><img src="@/assets/img/icon-alert-red.svg" alt="要確認" /></dt>
              <dd>
                <em>要確認</em>:見守りモードで監視対象の動きが検出されない状況など。<br>
                <em>異常検知</em>:外出モードで室内に異常な動きが検出された状況など。
              </dd>
            </dl>
            <dl>
              <dt><img src="@/assets/img/icon-safe.svg" alt="検知なし" /></dt>
              <dd>
                <em>検知なし</em>:外出モードで室内の異常な動きを検出していない状況など。
              </dd>
            </dl>
            <dl>
              <dt>&nbsp;</dt>
              <dd>
                <em>信号なし</em>:機器からの信号がない状態。機器の異常の可能性があるので、電源を入れ直すなどの対処を行ってください。
              </dd>
            </dl>
          </div>
          <div class="note">
            <h3>推奨環境</h3>
            <p>Google ChromeやMicrosoft Edge、Mozilla Firefoxなどモダンブラウザの最新バージョンでご利用ください。</p>
          </div>
          <div class="note">
            <h3>推奨モニターサイズについて</h3>
            <p>PCで操作する場合はXGA	1024×768以上の解像度でご利用下さい。</p>
          </div>
          <div class="note">
            <h3>活動状況の取得について</h3>
            <p>リアルタイムの活動状況監視は1回の監視につき60秒、1日の監視合計時間は300秒までです。1日の合計時間が300秒を超えるとリアルタイムの監視は1回あたり3秒に制限されます。</p>
          </div>
        </section>
        <section class="hard">
          <h2>機器について</h2>
          <div class="note">
            <h3>オフラインになった場合</h3>
            <p>電源アダプターを本体から掘り外し、5秒後に再度電源アダプターを接続してください。<br>復旧しない場合は<a href="/inquiry">サポートまでお問い合わせ</a>下さい。</p>
          </div>
          <div class="note">
            <h3>機器の設置場所について</h3>
            <p>WaveSenseはWi-Fiの電波を使用するので金属板で囲まれた場所は避けて下さい。</p>
          </div>
        </section>
      </div>
  </main>
  <Footer />
</template>

<script>
import Header from './Header'
import Footer from './Footer'

export default {
  name: 'Help',
  components: {
    Header,
    Footer
  },
}
</script>

<style scoped>
@media all and (max-width: 480px) {
  .cont h2 {
    font-size: 4.4vw;
    padding-bottom: .5em;
  }
  .cont section .note {
    font-size: 3.8vw;
    margin-bottom: 2em;
    padding-bottom: 2em;
    border-bottom: rgb(140, 155, 180) dashed 1px;
  }
  .cont section .note h3 {
    font-size: 4.2vw;
  }
  .cont .app .note.icon dl {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
  }
  .cont .app .note.icon dl dt {
    width: 20%;
    margin-top: 4vw;
  }
  .cont .app .note.icon dl dd {
    width: 78%;
    padding: 4vw 0 0;
  }
  .cont .app .note.icon dl dd em {
    font-style: normal;
    font-weight: 600;
  }
}
@media all and (min-width: 480px) {
  .cont h2 {
    font-size: 1.4vw;
    padding-bottom: 1em;
  }
  .cont section .note {
    font-size: 1vw;
    margin-bottom: 2em;
    padding-bottom: 2em;
  }
  .cont section .note h3 {
    font-size: 1.2vw;
  }
  .cont .app .note.icon dl {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
    padding: 1em 0;
  }
  .cont .app .note.icon dl dt {
    width: 6%;
  }
  .cont .app .note.icon dl dd {
    width: 93%;
  }
  .cont .app .note.icon dl dd em {
    font-style: normal;
    font-weight: 600;
  }
}
</style>
