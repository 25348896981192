<template>
  <main>
    <Header />
    <div class="cont">
      <div class="page-tit">
        <h1>会社名編集</h1>
        <p class="nav-links">
          <span class="link" v-on:click="backToTop">会社・施設一覧に戻る</span>
        </p>
      </div>

      <div class="box">
        <div class="exp">
          <p>追加する会社名を入力し、新規登録を押してください。<br>
          会社名を変更する場合は各会社名の横の編集ボタンを押してください。<br>
          会社の削除を行う場合は運営に<a href="/inquiry">お問い合わせ</a>ください。</p>
        </div>
      </div>

      <table class="entries">
        <thead>
          <th>会社名</th>
          <th>会社名を編集</th>
          <th>施設を編集</th>
        </thead>
        <tbody>
          <tr v-for="company in companies" :key="company.id" :class="getCompanyClass(company.id)">
            <td>{{ company.name }}</td>
            <td v-on:click="editCompany(company)"><span class="linktxt">編集</span></td>
            <td v-on:click="moveToEditFacility(company)"><span class="linktxt">追加・編集</span></td>
          </tr>
        </tbody>
      </table>

      <div class="box" v-if="editingCompany === null">
        <div class="entry">
          <label>会社名</label>
          <input v-model="inputCompanyName" type="text" placeholder="会社名" />&nbsp;
          <button v-on:click="addCompany">新規登録</button>
          <p class="alert" v-if="saveSuccess === true">保存が完了しました</p>
          <p class="alert" v-if="saveSuccess === false">保存が失敗しました</p>
        </div>
      </div>

      <div class="box" v-else>
        <div class="entry">
          <label>会社名</label>
          <input v-model="editingCompanyName" type="text" placeholder="会社名" />&nbsp;
          <button v-on:click="saveCompany">編集を保存</button>
          <p class="alert" v-if="saveSuccess === true">保存が完了しました</p>
          <p class="alert" v-if="saveSuccess === false">保存が失敗しました</p>
        </div>
        <div class="exp">
          <label><a v-on:click="stopEditing">編集モードをやめる</a></label><br/>
          <label>編集する会社名が表示されているので変更後、</label><br/>
          <label>編集ボタンを押してください。</label>
        </div>
      </div>


    </div>
  </main>
  <Footer />
</template>

<script>
import Header from './Header'
import Footer from './Footer'
import { apiGet, apiPost } from '../api'

export default {
  name: 'EditCompany',
  components: {
    Header,
    Footer
  },
  data: function() {
    return {
      companies: [],
      editingCompany: null,
      editingCompanyName: '',
      inputCompanyName: '',
      saveSuccess: null
    }
  },
  mounted() {
    if (!this.$router.app.userDetail.is_super_admin) {
      this.$router.push(`/`)
    }
    this.loadCompanies()
  },
  methods: {
    loadCompanies: async function() {
      const response = await apiGet('/api/company', this.$router.app.$cookies)
      this.companies = response.data
    },
    backToTop: function() {
      this.$router.push(`/top`)
    },
    addCompany: async function() {
      const response = await apiPost('/api/company/create', {
        name: this.inputCompanyName
      },this.$router.app.$cookies)
      this.inputCompanyName = ''

      if (response.data) {
        this.loadCompanies()
        this.saveSuccess = response.data
      }
    },
    editCompany: function(company) {
      this.editingCompany = company
      this.editingCompanyName = company.name
      this.saveSuccess = null
    },
    stopEditing: function() {
      this.editingCompany = null
      this.editingCompanyName = ''
      this.saveSuccess = null
    },
    saveCompany: async function() {
      const response = await apiPost('/api/company/save', {
        id: this.editingCompany.id,
        name: this.editingCompanyName
      },this.$router.app.$cookies)

      this.saveSuccess = response.data
      if (response.data) {
        this.loadCompanies()
      }
    },
    moveToEditFacility: function(company) {
      this.$router.push(`/edit_facility?company_id=${company.id}`)
    },
    getCompanyClass: function(companyId) {
      if (this.editingCompany === null) {
        return ''
      }
      return this.editingCompany.id === companyId ? 'editing' : ''
    }
  }
}
</script>

<style scoped>
.editing {
  background-color: rgb(240, 200, 200);
}
.linktxt {
  color: rgb(50,140,255);
  transition: all  0.3s ease;
}
.linktxt:hover {
  color: rgb(25, 90, 170);
}
@media all and (max-width: 580px) {
  .cont input {
    font-size: 3.8vw;
    padding: .4em .2em;
  }
  .cont div p {
    font-size: 3.8vw;
  }
  .cont .exp {
    padding: 1.6em 0;
  }
  .cont .box label {
    display: block;
    font-size: 3.8vw;
    padding-right: .6em;
  }
  .cont table {
    font-size: 3.8vw;
    width: 100%;
  }
  .cont table th {
    font-weight: normal;
    border: rgb(130,180,255) solid 1px;
    background: rgb(215,240,255);
    text-align: center;
    padding: .5em;
    width: 22%;
  }
  .cont table th:first-child {
    width: 50%;
  }
  .cont table th:nth-last-of-type(1),
  .cont table th:nth-last-of-type(2) {
    width: 15%;
  }
  .cont table td {
    border: rgb(130,180,255) solid 1px;
    padding: .5em;
    background: rgb(255,255,255);
  }
  .cont table td:nth-last-of-type(1),
  .cont table td:nth-last-of-type(2) {
    text-align: center;
  }
}
@media all and (min-width: 580px) {
  .cont input {
    font-size: 1.6rem;
    padding: .4em .2em;
    width: 450px;
  }
  .cont div p {
    font-size: 1.6rem;
  }
  .cont .exp {
    padding: 1.6em 0;
  }
  .cont .box {
    padding-bottom: 20px;
  }
  .cont .box label {
    font-size: 1.6rem;
    padding-right: .6em;
  }
  .cont table {
    font-size: 1.6rem;
    width: 100%;
    margin: 0 0 40px;
  }
  .cont table th {
    font-weight: normal;
    border: rgb(130,180,255) solid 1px;
    background: rgb(215,240,255);
    text-align: center;
    padding: .5em;
    width: 22%;
  }
  .cont table th:first-child {
    width: 50%;
  }
  .cont table th:nth-last-of-type(1),
  .cont table th:nth-last-of-type(2) {
    width: 15%;
  }
  .cont table td {
    border: rgb(130,180,255) solid 1px;
    padding: .5em;
  }
  .cont table td:nth-last-of-type(1),
  .cont table td:nth-last-of-type(2) {
    text-align: center;
  }
  .entry .alert {
    padding: 1em 0 0;
  }
}
</style>
