<template>
  <main>
    <Header />
    <div class="cont">
      <div class="BU-choice">
        <select v-model="selectingBaseUnitId">
          <option
            v-for="baseUnit in baseUnitsOfFacility"
            :key="baseUnit.id"
            :value="baseUnit.id"
          >{{ baseUnit.place_name }}</option>
        </select>&nbsp;
        <button v-on:click="moveToDashBoard(selectingBaseUnitId)">この場所に移動</button>
      </div>
      <div class="page-tit">
        <h1>日別スケジュールコピー: <span v-if="facilityName">{{ facilityName }}</span></h1>
        <p class="nav-links">
          <span class="link" v-on:click="moveToScheduleTop">スケジュール設定に戻る</span>
        </p>
      </div>

      <div class="source">
        <table>
          <caption>コピー元 : {{ dayName }}</caption>
          <thead>
            <th class="time">開始時間</th>
            <th class="sps">&nbsp;</th>
            <th class="time">終了時間</th>
            <th class="mode">モード</th>
            <th class="back-link">&nbsp;</th>
          </thead>
          <tbody>
            <td>{{ startTime }}</td>
            <td>〜</td>
            <td>{{ endTime }}</td>
            <td>{{ renderScheduleMode(mode) }}モード</td>
            <td><span class="link" v-on:click="moveToScheduleSetting(day)">{{ dayName }}の設定に戻る</span></td>
          </tbody>
        </table>
        <p>コピーする曜日にチェックしてください。</p>
        <p>スケジュールがすでに設定されている場合は、上書きされるのでご注意ください。</p>
      </div>

      <div class="week" v-if="scheduleList">
        <div v-for="(schedulesInTheDay, index) in scheduleList" :key="index">
          <div class="day" v-if="index !== day">
            <strong>
              <label><input type="checkbox" :value="index" v-model="copyDays" /> {{ dayOfTheWeek[index] }}</label>
            </strong>
            <div>
              <p v-for="schedule in schedulesInTheDay" :key="schedule.start_time">
                <span>{{ schedule.start_time }}〜{{ schedule.end_time }}</span>
                <span :class="scheduleClass(schedule.mode)">{{ renderScheduleMode(schedule.mode) }}モード</span>
              </p>
            </div>
          </div>
        </div>
        <div class="btn">
          <button v-on:click="executeCopy">コピーする</button>
          <p class="alert" v-if="failureMessage">{{ failureMessage }}</p>
        </div>
      </div>
    </div>
  </main>
  <Footer />
</template>

<script>
import Header from '../Header'
import Footer from '../Footer'
import { apiGet, apiPost } from '../../api'
import { dayOfTheWeek, deviceConfig } from '../../constants'

export default {
  name: 'ScheduleCopy',
  components: {
    Header,
    Footer,
  },
  data () {
    return {
      baseUnitId: this.$route.query.base_unit_id,
      baseUnitsOfFacility: null,
      facilityName: null,
      selectingBaseUnitId: this.$route.query.base_unit_id,
      scheduleList: null,
      day: parseInt(this.$route.query.day),
      dayOfTheWeek: dayOfTheWeek,
      dayName: dayOfTheWeek[this.$route.query.day],
      startTime: this.$route.query.start_time,
      endTime: this.$route.query.end_time,
      mode: parseInt(this.$route.query.mode),
      copyDays: [],
      reloadTimeMsec: 1000,
      failureMessage: null,
    }
  },
  mounted() {
    this.loadData()
  },
  methods: {
    loadData: async function() {
      this.getBaseUnit()
      this.getSchedule()
    },
    getBaseUnit: async function() {
      try {
        const response = await apiGet(`/api/base_unit?id=${this.baseUnitId}`, this.$router.app.$cookies)
        this.baseUnitDetail = response.data
        this.facilityName = response.data.facility_name
        this.baseUnitsOfFacility = response.data.base_units_of_facility
      } catch(err) {
        console.error(err)
      }
    },
    getSchedule: async function() {
      try {
        const response = await apiGet(`/api/schedule?base_unit_id=${this.baseUnitId}`, this.$router.app.$cookies)
        this.scheduleList = response.data
      } catch(err) {
        console.error(err)
      }
    },
    moveToScheduleTop: function() {
      this.$router.push(`/schedule/top?base_unit_id=${this.baseUnitId}`)
    },
    moveToDashBoard: function(baseUnitId) {
      this.$router.push(`/place_detail?base_unit_id=${baseUnitId}`)
    },
    moveToScheduleSetting: function(day) {
      this.$router.push(`/schedule/setting?base_unit_id=${this.baseUnitId}&day=${day}`)
    },
    renderScheduleMode: function(mode) {
      return mode === deviceConfig.mode.atHome ? deviceConfig.modeName.atHome
        : mode === deviceConfig.mode.monitoring ? deviceConfig.modeName.monitoring
        : mode === deviceConfig.mode.goOut ? deviceConfig.modeName.goOut : ""
    },
    scheduleClass: function(mode) {
      return mode === deviceConfig.mode.atHome ? "free"
        : mode === deviceConfig.mode.monitoring ? "care"
        : mode === deviceConfig.mode.goOut ? "set" : ""
    },
    executeCopy: async function() {
      if (this.copyDays.length === 0) {
        this.failureMessage = "コピーしたい曜日にチェックを入力してください。"
        return
      }

      const confirmResult = window.confirm("このスケジュールを本当にコピーしてよろしいですか？")
      if (!confirmResult) {
        return
      }

      const payload = {
        base_unit_id: this.baseUnitId,
        copyDays: this.copyDays,
        start_time: this.startTime,
        end_time: this.endTime,
        mode: this.mode,
      }
      const response = await apiPost(`/api/schedule/copy`, payload, this.$router.app.$cookies)
      if (response.data) {
        setTimeout(() => {this.moveToScheduleSetting(this.day)}, this.reloadTimeMsec)
      } else {
        this.failureMessage = "設定に失敗しました。"
      }
    },
  }
}
</script>

<style scoped>
.editing {
  background-color: rgb(240, 200, 200);
}
@media all and (max-width: 580px) {
  .source {
    font-size: 3.8vw;
    padding-bottom: 4vw;
  }
  .source table {
    margin: 0 auto 2vw;
    border: rgb(200, 220, 245) solid 1px;
  }
  .source table caption {
    font-size: 4vw;
    font-weight: bold;
  }
  .source table th {
    text-align: center;
    padding: .5em 0;
    background: rgb(200, 220, 245);
    font-weight: normal;
    white-space: nowrap;
  }
  .source table th.time {
    width: 18%;
  }
  .source table th.mode {
    width: 20%
  }
  .source table th.back-link {
    width: 30%;
  }
  .source table th.sps {
    width: 4%;
  }
  .source table td {
    text-align: center;
    padding: .5em 0;
    background: rgb(255,255,255);
  }
  .source table td span {
    display: inline-block;
    padding: .5em 1em;
    color: rgb(70, 150, 255);
    cursor: pointer;
    transition: all  0.3s ease;
  }
  .source table td span:hover {
    color: rgb(0, 80, 190);
  }
  .source p {
    margin: 0 auto .5em;
  }
  .week .day {
    font-size: 3.8vw;
    margin: .5em auto 1em;
    display: flex;
    flex-flow: row wrap;
    background: rgb(255,255,255);
  }
  .week .day strong {
    font-weight: normal;
    display: inline-block;
    padding: .5em 2em .5em .5em;
  }
  .week .day strong label {
    color: rgb(70, 150, 255);
    cursor: pointer;
    transition: all  0.3s ease;
  }
  .week .day div {
    padding-top: .5em;
  }
  .week .day div p {
    padding-bottom: .5em;
  }
  .week .day div p span {
    padding-right: 1em;
  }
  .week .btn,
  .week p {
    text-align: center;
  }
  .week .btn {
    padding: 1em 0 2em;
  }
}
@media all and (min-width: 580px) {
  .source {
    font-size: 1.6rem;
    padding-bottom: 15px;
  }
  .source table {
    width: 600px;
    margin: 0 auto 15px;
    border: rgb(200, 220, 245) solid 1px;
  }
  .source table caption {
    font-size: 1.8rem;
    font-weight: bold;
  }
  .source table th {
    text-align: center;
    padding: .5em;
    background: rgb(200, 220, 245);
    font-weight: normal;
  }
  .source table td {
    text-align: center;
    padding: .5em;
  }
  .source table td span {
    display: inline-block;
    padding: .5em 1em;
    color: rgb(70, 150, 255);
    cursor: pointer;
    transition: all  0.3s ease;
  }
  .source table td span:hover {
    color: rgb(0, 80, 190);
  }
  .source p {
    width: 600px;
    margin: 0 auto .5em;
  }
  .week .day {
    font-size: 1.6rem;
    width: 600px;
    margin: .5em auto .5em;
    display: flex;
    flex-flow: row wrap;
    border-bottom: rgb(200,200,200) solid 1px;
  }
  .week .day strong {
    font-weight: normal;
    display: inline-block;
    padding: .5em 2em .5em .5em;
  }
  .week .day strong label {
    color: rgb(70, 150, 255);
    cursor: pointer;
    transition: all  0.3s ease;
  }
  .week .day div {
    padding-top: .5em;
  }
  .week .day div p {
    padding-bottom: .5em;
  }
  .week .day div p span {
    padding-right: 1em;
  }
  .week .btn,
  .week p {
    text-align: center;
  }
  .week .btn {
    padding: 20px 0 0;
  }
}
</style>

