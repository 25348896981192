<template>
  <main>
    <Header />
    <div class="cont">
      <div class="page-tit">
        <h1>施設編集（{{ company.name }}）</h1>
        <p class="nav-links">
          <span class="link" v-on:click="backToTop">会社・施設一覧に戻る</span>
          <span class="link" v-on:click="backToEditCompany">会社名編集に戻る</span>
        </p>
      </div>

      <div class="box" v-if="editingFacility === null">
        <div class="entry">
          <p><input class="name" v-model="inputFacilityName" type="text" placeholder="施設名" /></p>
          <p><input class="zipcode" v-model="inputFacilityPostcode" type="text" placeholder="郵便番号" />
          <input class="address" v-model="inputFacilityAddress" type="text" placeholder="住所" /></p>
          <p><input class="phone" v-model="inputFacilityTel" type="text" placeholder="電話番号" />
          <span><button v-on:click="addFacility">新規登録</button></span></p>
        </div>

        <p class="alert" v-if="saveSuccess === true">登録が完了しました</p>
        <p class="alert" v-if="saveSuccess === false">登録が失敗しました</p>

        <div class="exp">
          <p>追加する施設名を入力し、新規登録を押してください。<br/>
          施設名を変更する場合は各施設名の横の編集ボタンを押してください。<br/>
          施設の削除を行う場合は運営に<a href="/inquiry">お問い合わせ</a>ください。</p>
        </div>
      </div>
      <div class="box" v-else>
        <div class="entry">
          <p><input class="name" v-model="editingFacility.name" type="text" placeholder="施設名" /></p>
          <p><input class="zipcode" v-model="editingFacility.postcode" type="text" placeholder="郵便番号" />
          <input class="address" v-model="editingFacility.address" type="text" placeholder="住所" /></p>
          <p><input class="phone" v-model="editingFacility.tel" type="text" placeholder="電話番号" />
          <span><button v-on:click="saveFacility">編集を保存</button></span></p>
        </div>
        <p class="alert" v-if="saveSuccess === true">保存が完了しました</p>
        <p class="alert" v-if="saveSuccess === false">保存が失敗しました</p>

        <div class="exp">
          <p><a v-on:click="stopEditing">編集モードをやめる</a></p>
        </div>
      </div>

      <table>
        <thead>
          <th>施設名</th>
          <th>施設住所</th>
          <th><span>施設名</span><span>を編集</span></th>
        </thead>
        <tbody>
          <tr v-for="facility in facilities" :key="facility.id" :class="getFacilityClass(facility.id)">
            <td>{{ facility.name }}</td>
            <td>
              {{ facility.postcode }}<br>
              {{ facility.address }}<br>
              {{ facility.tel }}
            </td>
            <td v-on:click="editFacility(facility)"><span class="linktxt">編集</span></td>
          </tr>
        </tbody>
      </table>
    </div>
  </main>
  <Footer />
</template>

<script>
import Header from './Header'
import Footer from './Footer'
import { apiGet, apiPost } from '../api'
import { convertToHankaku } from '../stringUtil'

export default {
  name: 'EditCompany',
  components: {
    Header,
    Footer
  },
  data: function() {
    return {
      company: {},
      facilities: [],
      editingFacility: null,
      inputFacilityName: '',
      inputFacilityPostcode: '',
      inputFacilityAddress: '',
      inputFacilityTel: '',
      saveSuccess: null,
    }
  },
  mounted() {
    this.loadCompany()
    this.loadFacilities()
  },
  methods: {
    loadCompany: async function() {
      const response = await apiGet(`/api/company?id=${this.$route.query.company_id}`, this.$router.app.$cookies)
      this.company = response.data
    },
    loadFacilities: async function() {
      const response = await apiGet(`/api/facility?company_id=${this.$route.query.company_id}`, this.$router.app.$cookies)
      this.facilities = response.data
    },
    backToTop: function() {
      this.$router.push('/top')
    },
    backToEditCompany: function() {
      this.$router.push('/edit_company')
    },
    addFacility: async function() {
      if (this.inputFacilityName === '') {
        window.alert('施設名を入力してください')
        return
      }

      const response = await apiPost('/api/facility/create', {
        company_id: this.$route.query.company_id,
        name: this.inputFacilityName,
        postcode: this.inputFacilityPostcode,
        address: this.inputFacilityAddress,
        tel: this.inputFacilityTel,
      },this.$router.app.$cookies)

      if (response.data) {
        this.loadFacilities()
        this.saveSuccess = true
      } else {
        this.saveSuccess = false
      }
    },
    editFacility: function(facility) {
      this.editingFacility = structuredClone(facility)
      this.saveSuccess = null
    },
    stopEditing: function() {
      this.editingFacility = null
      this.saveSuccess = null
    },
    saveFacility: async function() {
      if (this.editingFacility.name === '') {
        window.alert('施設名を入力してください')
        return
      }

      this.editingFacility.name = convertToHankaku(this.editingFacility.name)
      this.editingFacility.postcode = convertToHankaku(this.editingFacility.postcode)
      const onlyNumPattern = /^\d{7}$/g
      if (this.editingFacility.postcode.match(onlyNumPattern)) {
        this.editingFacility.postcode = this.editingFacility.postcode.slice(0, 3) + '-' + this.editingFacility.postcode.slice(3)
      }
      this.editingFacility.address = convertToHankaku(this.editingFacility.address)
      this.editingFacility.tel = convertToHankaku(this.editingFacility.tel)

      const response = await apiPost('/api/facility/save', {
        id: this.editingFacility.id,
        name: this.editingFacility.name,
        postcode: this.editingFacility.postcode,
        address: this.editingFacility.address,
        tel: this.editingFacility.tel
      },this.$router.app.$cookies)

      if (response.data) {
        this.loadFacilities()
        this.saveSuccess = true
      } else {
        this.saveSuccess = false
      }
    },
    getFacilityClass: function(facilityId) {
      if (this.editingFacility === null) {
        return ''
      }
      return this.editingFacility.id === facilityId ? 'editing' : ''
    }
  }
}
</script>

<style scoped>
.editing {
  background-color: rgb(240, 200, 200);
}
.linktxt {
  color: rgb(50,140,255);
  transition: all  0.3s ease;
}
.linktxt:hover {
  color: rgb(25, 90, 170);
}
@media all and (max-width: 580px) {
  .cont input {
    font-size: 3.8vw;
    padding: .2em;
    box-sizing: border-box;
  }
  .cont div p {
    font-size: 3.8vw;
  }
  .cont .entry p {
    padding-bottom: .8em;
  }
  .cont .entry p input {
    width: 100%;
    margin-bottom: .4em;
  }
  .cont .entry p:last-child span {
    display: block;
    text-align: center;
    padding: 4vw 0;
  }
  .cont .exp {
    padding: 1em 0;
  }
  .cont .box label {
    display: block;
    font-size:3.8vw;
    padding-right: .6em;
  }
  .cont table {
    font-size: 3.8vw;
    width: 100%;
    background: rgb(255,255,255);
  }
  .cont table th {
    font-weight: normal;
    border: rgb(130,180,255) solid 1px;
    background: rgb(215,240,255);
    text-align: center;
    padding: .5em;
  }
  .cont table th:first-child {
    width: auto;
  }
  .cont table th span {
    display: block;
    width: 3em;
    margin: auto;
    text-align: center;
  }
  .cont table td {
    border: rgb(130,180,255) solid 1px;
    padding: .5em;
  }
  .cont table td:last-child {
    text-align: center;
  }
}
@media all and (min-width: 580px) {
  .cont input {
    font-size: 1.6rem;
    padding: .2em;
  }
  .cont div p {
    font-size: 1.6rem;
  }
  .cont .entry p {
    padding-bottom: .5em;
  }
  .cont .entry p .name {
    width: calc(100% - .6em);
  }
  .cont .entry p .zipcode {
    width: 9em;
  }
  .cont .entry p .address {
    width: 80%
  }
  .cont .entry p .phone {
    width: 14em;
  }
  .cont .entry p:nth-last-of-type(-n + 2) {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
  }
  .cont .exp {
    padding: 1.6em 0;
  }
  .cont .box label {
    font-size: 1.6rem;
    padding-right: .6em;
  }
  .cont table {
    font-size: 1.6rem;
    width: 100%;
  }
  .cont table th {
    font-weight: normal;
    border: rgb(130,180,255) solid 1px;
    background: rgb(215,240,255);
    text-align: center;
    padding: .5em;
    width: 22%;
  }
  .cont table th:first-child {
    width: auto;
  }
  .cont table th:last-child {
    width: 15%;
  }
  .cont table td {
    border: rgb(130,180,255) solid 1px;
    padding: .5em;
  }
  .cont table td:last-child {
    text-align: center;
  }
}
</style>
