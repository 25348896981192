<template>
  <main class="idx">
    <header>
      <div class="hcont">
        <h1>WaveSense</h1>
      </div>
    </header>
    <div class="idx-login">
      <dl>
        <dt><img src="@/assets/img/logo-mark.svg" alt="AI Care" /></dt>
        <dd>
          <img src="@/assets/img/sl-logo-blue.svg" />
          <div class="form-item">
            <label for="code">メールに記載されている認証コードを入力してください</label>
            <input
              id="code"
              placeholder="認証コード"
              autocomplete="off"
              type="text"
              v-model="code"
            >
          </div>
          <div class="form-item">
            <label for="password">新しいパスワード</label>
            <input
              id="password"
              placeholder="新しいパスワード(8文字以上)"
              autocomplete="off"
              type="password"
              v-model="password"
            >
          </div>
          <div class="form-item">
            <button class="button" @click="resetPassword()">パスワードをリセット</button>
          </div>
          <div class="form-item">
            <a href="/">TOPへ戻る</a>
          </div>
        </dd>
      </dl>
    </div>
  </main>
  <Footer />
</template>

<script>
import axios from 'axios'
import Footer from './Footer'

export default {
  name: 'ResetPassword',
  components: {
    Footer,
  },
  data () {
    return {
      password: '',
      code: '',
    }
  },
  methods: {
    resetPassword: function() {
      axios.post('/api/auth/reset_password', {
        email: this.$route.query.email,
        password: this.password,
        code: this.code
      }).then((res) => {
        if (res.data.success) {
          location.href = `/login?reset_password_success=1`
        } else {
          alert("リセットに失敗しました。")
        }
      })
    }
  }
}
</script>

<style scoped>
.form-item {
  margin: 0 auto;
  text-align: center;
}

label {
  display: block;
}
button {
  margin: 1em;
}
.hcont h1 {
  color: rgb(70, 150, 255);
}
@media all and (max-width: 580px) {
  .idx-login {
    text-align: center;
    padding: 20vw 0;
  }
  .idx-login dl dt {
    width: 40vw;
    margin: auto;
    padding-bottom: 2vw;
  }
  .idx-login dl dd {
    width: 90vw;
    margin: auto;
    font-size: 4vw;
    text-align: center;
  }
  .idx-login dl dd img {
    width: 50vw;
    padding-bottom: 4vw;
  }
  .idx-login dl dd div {
    padding: 1em 0 0;
  }
  .idx-login dl dd .form-item {
    text-align: center;
  }
  .idx-login dl dd .message span {
    display: block;
    text-align: center;
    padding: .5em;
    background: rgb(255, 230, 230);
    border-radius: 7px;
  }
  .idx-login dl dd input {
    width: 100%;
    margin: auto;
  }
  .idx-login .form-item input {
    font-size: 4vw;
    padding: .2em 0;
  }
  input {
    width: 50%;
    padding: .5em;
    font: inherit;
  }
}


@media all and (min-width: 580px) {
  .idx-login {
    text-align: center;
    padding: 4vw 0;
  }
  .idx-login dl dt {
    width: 170px;
    margin: auto;
    padding-bottom: 2vw;
  }
  .idx-login dl dd {
    width: 500px;
    margin: auto;
    font-size: 1vw;
    text-align: center;
  }
  .idx-login dl dd img {
    width: 290px;
    padding-bottom: 40px;
  }
  .idx-login dl dd div {
    padding: 1em 0 0;
  }
  .idx-login dl dd .form-item {
    text-align: center;
  }
  .idx-login dl dd .message span {
    display: block;
    text-align: center;
    padding: .5em;
    background: rgb(255, 230, 230);
    border-radius: 7px;
  }
  .idx-login dl dd input {
    width: 100%;
    margin: auto;
  }
  .idx-login .form-item input {
    font-size: 1.2vw;
    padding: .2em 0;
  }
  input {
    width: 50%;
    padding: .5em;
    font: inherit;
  }

}
</style>
