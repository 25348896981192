<template>
  <Header />
  <main>
    <div class="cont">
      <BackButton/>
      <h1>マスター画面TOP</h1>
      <section>
        <button v-on:click="showCompanyMaster">会社マスタ</button>
      </section>
    </div>
  </main>
  <Footer />
</template>

<script>
import Header from '../Header'
import Footer from '../Footer'

export default {
  name: 'AdminTop',
  components: {
    Header,
    Footer
  },
  methods: {
    showCompanyMaster: function() {
      location.href = '/admin/company_master'
    },
  }
}
</script>

<style scoped>
  @media all and (max-width: 580px) {
    .cont section {
      padding: 14vw 0;
      display: flex;
      flex-flow: row wrap;
      justify-content: center;
    }
  }

  @media all and (min-width: 580px) {
    .cont section {
      padding: 50px 0;
      display: flex;
      flex-flow: row wrap;
      justify-content: center;
    }
  }
</style>
