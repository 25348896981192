<template>
  <Header />
  <main>
    <div class="cont">
      <BackButton/>
      <h1>ログダウンロード</h1>

      <div class="days">
        <em>データ取得日時（7日前まで）</em>
        <div class="form-items">
          <VueDatePicker
            locale="ja"
            format="yyyy-MM-dd"
            :auto-apply="true"
            :min-date="minDate"
            :max-date="timeRangeEnd || new Date()"
            :enable-time-picker="false"
            v-model="timeRangeStart"
          ></VueDatePicker>
          <span>〜</span>
          <VueDatePicker
            locale="ja"
            format="yyyy-MM-dd"
            :auto-apply="true"
            :min-date="timeRangeStart"
            :max-date="new Date()"
            :enable-time-picker="false"
            v-model="timeRangeEnd"
          ></VueDatePicker>
        </div>
      </div>

      <ul class="places">
        <li v-for="extensionUnit in extensionUnits" :key="extensionUnit.id">
          <label><input type="checkbox" :value="extensionUnit.device_id" v-model="checkedDeviceIdList"/>{{ extensionUnit.room_name }}</label>
        </li>
      </ul>

      <div class="enter">
        <button v-on:click="download">ダウンロード</button>
      </div>
    </div>
  </main>
  <Footer />
</template>

<script>
import Header from '../Header'
import Footer from '../Footer'
import { apiGet } from '../../api'
import JSZip from 'jszip'
import VueDatePicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'

export default {
  name: 'LogDownload',
  components: {
    Header,
    Footer,
    VueDatePicker
  },
  data() {
    let minDate = new Date()
    minDate.setDate(minDate.getDate() - 7)

    return {
      searchPlaceName: '',
      timeRangeStart: minDate,
      timeRangeEnd: new Date(),
      extensionUnits: [],
      checkedDeviceIdList: [],
      minDate: minDate,
      roomNameMap: {},
    }
  },
  mounted() {
    this.getExtensionUnits()
  },
  methods: {
    getExtensionUnits: async function() {
      try {
        const _this = this
        const response = await apiGet(`/api/extension_unit?base_unit_id=${this.$route.query.base_unit_id}`, this.$router.app.$cookies)
        this.extensionUnits = response.data
        this.extensionUnits.forEach(unit => {
          _this.checkedDeviceIdList.push(unit.device_id)
          _this.roomNameMap[unit.device_id] = unit.room_name
        })

      } catch (err) {
        console.error(err)
      }
    },
    download: async function() {
      try {
        const checkedDeviceIdListString = this.checkedDeviceIdList.join(',')

        let apiUrl = `/api/log/download?base_unit_id=${this.$route.query.base_unit_id}&device_id=${checkedDeviceIdListString}`
        if (this.timeRangeStart !== null) {
          this.timeRangeStart.setHours(0,0,0,0)
          const startUnixTime = this.timeRangeStart.getTime() / 1000
          apiUrl += `&time_start=${startUnixTime}`
        }
        if (this.timeRangeEnd !== null) {
          this.timeRangeEnd.setHours(23,59,59,0)
          const endUnixTime = this.timeRangeEnd.getTime() / 1000
          apiUrl += `&time_end=${endUnixTime}`
        }

        const response = await apiGet(apiUrl, this.$router.app.$cookies)
        if (response.data === null) {
          return
        }

        const tsvFiles = []

        this.checkedDeviceIdList.forEach((deviceId) => {
          const roomName = this.roomNameMap[deviceId]
          let tsvContent = ''
          const columns = ['time', 'activity_level']
          columns.forEach((column, index) => {
            tsvContent += column
            if (index != columns.length - 1) {
              tsvContent += '\t'
            }
          })
          tsvContent += '\n'

          if (roomName in response.data) {
            response.data[roomName].forEach(row => {
              tsvContent += row['time'] + '\t'
              tsvContent += row['activity_level']
              tsvContent += '\n'
            })
          }

          const tsvBlob = new Blob([tsvContent], { type: 'text/tsv' })
          tsvFiles.push({ fileName: `${roomName}_log.tsv`, data: tsvBlob })
        })

        const zipBlob = await this.createZipFile(tsvFiles)
        this.downloadZipFile(zipBlob, 'log.zip')
      } catch (error) {
        console.log(error)
      }
    },
    createZipFile: async function(csvFiles) {
      const zip = new JSZip()
      csvFiles.forEach((file) => {
        zip.file(file.fileName, file.data)
      })
      const zipBlob = await zip.generateAsync({ type: 'blob' })
      return zipBlob
    },
    downloadZipFile: function(zipBlob, fileName) {
      const url = window.URL.createObjectURL(zipBlob)
      const a = document.createElement('a')
      a.href = url
      a.download = fileName
      a.click()
      window.URL.revokeObjectURL(url)
    }
  }
}
</script>

<style scoped>
@media all and (max-width: 580px) {
  .days {
    padding: 0 0 1em;
  }
  .days em {
    display: block;
    font-style: normal;
    font-size: 4vw;
    text-align: center;
    padding: 1em 0 .5em;
  }
  .days .form-items {
    font-size: 4vw !important;
  }
  .days .form-items div {
    font-size: 4vw !important;
  }
  .days .form-items span {
    display: block;
    text-align: center;
    padding: 0 .5em;
  }
  .places {
    font-size: 4vw;
    padding: 1em 0 1em;
  }
  .places li {
    padding: 0 0 1em;
  }
  .places li label {
    cursor: pointer;
    padding: .2em;
  }
  .places li label:hover {
    background: rgb(220, 230, 250);
    border-radius: 3px;
  }
  .enter {
    text-align: center;
    padding: 0 0 3em;
  }
}

@media all and (min-width: 580px) {
  .days {
    padding: 0 0 1em;
  }
  .days em {
    display: block;
    font-style: normal;
    font-size: 18px;
    padding: 0 0 .5em;
  }
  .days .form-items {
    display: flex;
    flex-flow: row wrap;
    font-size: 16px !important;
  }
  .days .form-items div {
    display: inline;
    width: 12em;
    font-size: 16px !important;
  }
  .days .form-items span {
    display: inline-block;
    padding: 0 .5em;
  }
  .places {
    font-size: 16px;
    padding: .5em 0 1em;
  }
  .places li {
    padding: 0 0 .5em;
  }
  .places li label {
    cursor: pointer;
    padding: .2em;
  }
  .places li label:hover {
    background: rgb(220, 230, 250);
    border-radius: 3px;
  }
  .enter {
    text-align: center;
    padding: 1em 0 2em;
  }
}
</style>
