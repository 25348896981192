<template>
  <main>
    <Header />
    <div class="cont">
      <div class="page-tit">
        <h1>親機情報{{ registeredAtString }}</h1>
        <p class="nav-links">
          <span class="link" v-on:click="backToTop">会社・施設一覧に戻る</span>
          <span v-if="edittingBaseUnit" class="link" v-on:click="editStop">親機情報画面に戻る</span>
          <span v-else class="link" v-on:click="editStart">親機情報を編集する</span>
        </p>
      </div>

      <div v-if="baseUnit">
        <div v-if="edittingBaseUnit" class="notes">
          <dl>
            <dt>会社名</dt>
            <dd>
              <p>{{ baseUnit.company_name }}</p>
            </dd>
          </dl>
          <dl>
            <dt>施設名</dt>
            <dd>
              <select v-model="edittingBaseUnit.facility_id">
                <option
                  v-for="facility in facilities"
                  :key="facility.id"
                  :value="facility.id"
                >{{ facility.name }}</option>
              </select>
            </dd>
          </dl>
          <dl>
            <dt>場所</dt>
            <dd>
              <input type="text" v-model="edittingBaseUnit.place_name">
            </dd>
          </dl>
          <dl>
            <dt>国</dt>
            <dd>
              <input type="text" v-model="edittingBaseUnit.country_name">
            </dd>
          </dl>
          <dl>
            <dt>郵便番号</dt>
            <dd>
              <input type="text" v-model="edittingBaseUnit.post_code">
            </dd>
          </dl>
          <dl>
            <dt>都道府県</dt>
            <dd>
              <input type="text" v-model="edittingBaseUnit.prefecture">
            </dd>
          </dl>
          <dl>
            <dt>住所</dt>
            <dd>
              <input type="text" v-model="edittingBaseUnit.address">
            </dd>
          </dl>
          <dl>
            <dt>備考</dt>
            <dd>
              <input type="text" v-model="edittingBaseUnit.note">
            </dd>
          </dl>
          <p class="save">
            <button v-on:click="saveBaseUnit">保存する</button>
          </p>
          <p class="alert" v-if="saveSuccess === true">保存が完了しました</p>
          <p class="alert" v-if="saveSuccess === false">保存が失敗しました</p>
        </div>

        <div v-else class="notes">
          <dl>
            <dt>会社名</dt>
            <dd>
              <p>{{ baseUnit.company_name }}</p>
            </dd>
          </dl>
          <dl>
            <dt>施設名</dt>
            <dd>
              <p>{{ baseUnit.facility_name }}</p>
            </dd>
          </dl>
          <dl>
            <dt>場所</dt>
            <dd>
              <p>{{ baseUnit.place_name }}</p>
            </dd>
          </dl>
          <dl>
            <dt>国</dt>
            <dd>
              <p>{{ baseUnit.country_name }}</p>
            </dd>
          </dl>
          <dl>
            <dt>郵便番号</dt>
            <dd>
              <p>{{ baseUnit.post_code }}</p>
            </dd>
          </dl>
          <dl>
            <dt>都道府県</dt>
            <dd>
              <p>{{ baseUnit.prefecture }}</p>
            </dd>
          </dl>
          <dl>
            <dt>住所</dt>
            <dd>
              <p>{{ baseUnit.address }}</p>
            </dd>
          </dl>
          <dl>
            <dt>備考</dt>
            <dd>
              <p>{{ baseUnit.note }}</p>
            </dd>
          </dl>
        </div>
      </div>
    </div>
  </main>
  <Footer />
</template>

<script>
import Header from './Header'
import Footer from './Footer'
import { apiGet, apiPost } from '../api'

export default {
  name: 'EditBaseUnit',
  components: {
    Header,
    Footer
  },
  data: function() {
    return {
      baseUnit: null,
      facilities: [],
      edittingBaseUnit: null,
      registeredAtString: null,
      saveSuccess: null
    }
  },
  mounted() {
    this.loadBaseUnit()
  },
  methods: {
    loadBaseUnit: async function() {
      const response = await apiGet(`/api/base_unit?id=${this.$route.query.id}`, this.$router.app.$cookies)
      this.baseUnit = response.data
      this.registeredAtString = this.renderRegisteredAt(this.baseUnit.registered_at)

      await this.loadFacilities(this.baseUnit.company_id)
    },
    loadFacilities: async function(companyId) {
      const response = await apiGet(`/api/facility?company_id=${companyId}`, this.$router.app.$cookies)
      this.facilities = response.data
    },
    backToTop: function() {
      this.$router.push(`/top`)
    },
    editStart: function() {
      this.edittingBaseUnit = {
        id: this.baseUnit.id,
        facility_id: this.baseUnit.facility_id,
        place_name: this.baseUnit.place_name,
        device_id: this.baseUnit.device_id,
        registered_at: this.baseUnit.registered_at,
        country_name: this.baseUnit.country_name,
        post_code: this.baseUnit.post_code,
        prefecture: this.baseUnit.prefecture,
        address: this.baseUnit.address,
        note: this.baseUnit.note
      }
    },
    editStop: function() {
      this.edittingBaseUnit = null
      this.saveSuccess = null
      this.loadBaseUnit()
    },
    renderRegisteredAt: function(timestamp) {
      if (!timestamp) {
        return ''
      }
      const date = new Date(timestamp * 1000)
      let year = date.getFullYear()
      let month = date.getMonth() + 1
      let day = date.getDate()
      return `（最新接続日:${year}/${month}/${day}）`
    },
    saveBaseUnit: async function() {
      const response = await apiPost(`/api/base_unit`, this.edittingBaseUnit, this.$router.app.$cookies)
      this.saveSuccess = response.data.success
    }
  }
}
</script>

<style scoped>
  @media all and (max-width: 580px) {
    .cont .notes dl {
      font-size: 3.8vw
    }
    .cont .notes dl dt {
      background: rgb(186, 210, 255);
      padding: .2em .5em;
    }
    .cont .notes dl dd {
      padding: .2em 0 1em;
    }
    .cont .notes em {
      display: block;
      font-weight: normal;
      font-style: normal;
      font-size: 1.6rem;
      padding-bottom: 2em;
    }
    .cont input {
      box-sizing: border-box;
      font-size: 3.8vw;
      padding: .2em .4em;
      width: 100%;
    }
    .cont div p {
      box-sizing: border-box;
      font-size: 3.8vw;
      padding: 0 .2em;
    }
    .cont .exp {
      padding: 1.6em 0;
    }
    .cont .box label {
      font-size: 1.6rem;
      padding-right: .6em;
    }
    .cont table {
      font-size: 1.6rem;
      width: 100%;
    }
    .cont table th {
      font-weight: normal;
      border: rgb(130,180,255) solid 1px;
      background: rgb(215,240,255);
      text-align: center;
      padding: .5em;
      width: 22%;
    }
    .cont table th:first-child {
      width: 50%;
    }
    .cont table th:nth-last-of-type(1),
    .cont table th:nth-last-of-type(2) {
      width: 15%;
    }
    .cont table td {
      border: rgb(130,180,255) solid 1px;
      padding: .5em;
    }
    .cont table td:nth-last-of-type(1),
    .cont table td:nth-last-of-type(2) {
      text-align: center;
    }
    .cont .radios {
      display: flex;
      flex-flow: row wrap;
      align-items: center;
      padding: 1em 0;
    }
    .cont .radios dt {
      font-size: 1.6rem;
      width: 6em;
    }
    .cont .radios dd {
      position: relative;
      font-size: 1.6rem;
    }
    .cont .radios dd input[type="radio"]{
      position:absolute;
      right:1000%;
    }
    .cont .radios dd label{
      display: inline-block;
      text-align: center;
      width: 3em;
      padding: .5em;
      margin: .5em;
      background:rgba(130,180,255,.2);
      border-radius: 2em;
      cursor:pointer;
      position: relative;
      z-index: 10;
    }
    .cont .radios #slider{
      position:absolute;
      left:0%;
      top:0;
      width: 3em;
      height: .5em;
      padding-bottom:10%;
      margin: 1em 0 0 1em;
      background: rgb(130,180,255);
      transition:transform 1s;
      border-radius:2em;
      animation-timing-function: ease-in-out;
      animation-duration:.3s;
      animation-fill-mode: forwards;
      transition: 0.2s left .05s ease-in-out;
      z-index: 1;
    }
    #input1:checked  ~ #slider{ animation-name: input1; left:0; }
    #input2:checked  ~ #slider{ animation-name: input2; left:50%; }

    @keyframes input1{ 30%, 70% { transform:scale(0.5); } }
    @keyframes input2{ 30%, 70% { transform:scale(0.5); } }

    .cont .meil-list em {
      display: block;
      font-size: 1.6rem;
      font-style: normal;
      padding: .5em 0 1em;
    }
    .cont .meil-list table th:nth-of-type(1) {
      width: 60%;
    }
    .cont .meil-list table th:nth-of-type(2) {
      width: auto;
    }
    .cont .notes .save {
      padding: 1em 0 1em;
      text-align: center;
    }
  }
  @media all and (min-width: 580px) {
    .cont .notes dl {
      display: flex;
      flex-flow: row wrap;
      padding-block: .6em;
    }
    .cont .notes dl dt {
      font-size: 1.6rem;
      width: 12em;
    }
    .cont .notes dl dd {
      width: calc(100% - 20em);
    }
    .cont .notes em {
      display: block;
      font-weight: normal;
      font-style: normal;
      font-size: 1.6rem;
      padding-bottom: 2em;
    }
    .cont input {
      font-size: 1.6rem;
      padding: .4em .2em;
      width: 96%;
    }
    .cont div p {
      font-size: 1.6rem;
    }
    .cont .exp {
      padding: 1.6em 0;
    }
    .cont .box label {
      font-size: 1.6rem;
      padding-right: .6em;
    }
    .cont table {
      font-size: 1.6rem;
      width: 100%;
    }
    .cont table th {
      font-weight: normal;
      border: rgb(130,180,255) solid 1px;
      background: rgb(215,240,255);
      text-align: center;
      padding: .5em;
      width: 22%;
    }
    .cont table th:first-child {
      width: 50%;
    }
    .cont table th:nth-last-of-type(1),
    .cont table th:nth-last-of-type(2) {
      width: 15%;
    }
    .cont table td {
      border: rgb(130,180,255) solid 1px;
      padding: .5em;
    }
    .cont table td:nth-last-of-type(1),
    .cont table td:nth-last-of-type(2) {
      text-align: center;
    }
    .cont .radios {
      display: flex;
      flex-flow: row wrap;
      align-items: center;
      padding: 1em 0;
    }
    .cont .radios dt {
      font-size: 1.6rem;
      width: 6em;
    }
    .cont .radios dd {
      position: relative;
      font-size: 1.6rem;
    }
    .cont .radios dd input[type="radio"]{
      position:absolute;
      right:1000%;
    }
    .cont .radios dd label{
      display: inline-block;
      text-align: center;
      width: 3em;
      padding: .5em;
      margin: .5em;
      background:rgba(130,180,255,.2);
      border-radius: 2em;
      cursor:pointer;
      position: relative;
      z-index: 10;
    }
    .cont .radios #slider{
      position:absolute;
      left:0%;
      top:0;
      width: 3em;
      height: .5em;
      padding-bottom:10%;
      margin: 1em 0 0 1em;
      background: rgb(130,180,255);
      transition:transform 1s;
      border-radius:2em;
      animation-timing-function: ease-in-out;
      animation-duration:.3s;
      animation-fill-mode: forwards;
      transition: 0.2s left .05s ease-in-out;
      z-index: 1;
    }
    #input1:checked  ~ #slider{ animation-name: input1; left:0; }
    #input2:checked  ~ #slider{ animation-name: input2; left:50%; }

    @keyframes input1{ 30%, 70% { transform:scale(0.5); } }
    @keyframes input2{ 30%, 70% { transform:scale(0.5); } }

    .cont .meil-list p span {
      padding-right: 1em;
    }
    .cont .meil-list em {
      display: block;
      font-size: 1.6rem;
      font-style: normal;
      padding: .5em 0 1em;
    }
    .cont .meil-list table th:nth-of-type(1) {
      width: 60%;
    }
    .cont .meil-list table th:nth-of-type(2) {
      width: auto;
    }
    .cont .notes .save {
      padding: 2em 0 1em;
      text-align: center;
    }
  }
</style>
