<template>
  <main>
    <Header />
    <div class="cont">
      <div class="BU-choice">
        <select v-model="selectingBaseUnitId">
          <option
            v-for="baseUnit in baseUnitsOfFacility"
            :key="baseUnit.id"
            :value="baseUnit.id"
          >{{ baseUnit.place_name }}</option>
        </select>&nbsp;
        <button v-on:click="moveToDashBoard(selectingBaseUnitId)">この場所に移動</button>
      </div>
      <div class="page-tit">
        <h1>スケジュール設定 : <span v-if="facilityName">{{ facilityName }}</span></h1>
        <p class="nav-links">
          <span class="link" v-on:click="moveToDashBoard(baseUnitId)">ダッシュボードに戻る</span>
        </p>
      </div>

      <div v-if="scheduleList">
        <div v-for="(schedulesInTheDay, index) in scheduleList" :key="index">
          <details class="accordion">
              <summary>{{ dayOfTheWeek[index] }}</summary>
              <div class="acd-cont">
                <div class="entry" v-for="schedule in schedulesInTheDay" :key="schedule.start_time">
                  <span>{{ schedule.start_time }}〜{{ schedule.end_time }}</span>
                  <span v-if="customModeNameList" :class="scheduleClass(schedule.mode)">{{ customModeNameList[schedule.mode] }}</span>
                </div>
                <div class="btn">
                  <button v-on:click="moveToScheduleSetting(index)">追加・編集</button>
                </div>
              </div>
          </details>
        </div>
      </div>


    </div>
  </main>
  <Footer />
</template>

<script>
import Header from '../Header'
import Footer from '../Footer'
import { apiGet } from '../../api'
import { dayOfTheWeek, deviceConfig } from '../../constants'

export default {
  name: 'ScheduleTop',
  components: {
    Header,
    Footer,
  },
  data () {
    return {
      baseUnitId: this.$route.query.base_unit_id,
      baseUnitsOfFacility: null,
      facilityName: null,
      selectingBaseUnitId: this.$route.query.base_unit_id,
      scheduleList: null,
      dayOfTheWeek: dayOfTheWeek,
      customModeNameList: null,
    }
  },
  mounted() {
    this.loadData()
  },
  methods: {
    loadData: async function() {
      this.getBaseUnit()
      this.getSchedule()
      this.getCustomModeName()
    },
    getBaseUnit: async function() {
      try {
        const response = await apiGet(`/api/base_unit?id=${this.baseUnitId}`, this.$router.app.$cookies)
        this.baseUnitDetail = response.data
        this.facilityName = response.data.facility_name
        this.baseUnitsOfFacility = response.data.base_units_of_facility
      } catch(err) {
        console.error(err)
      }
    },
    getSchedule: async function() {
      try {
        const response = await apiGet(`/api/schedule?base_unit_id=${this.baseUnitId}`, this.$router.app.$cookies)
        this.scheduleList = response.data
      } catch(err) {
        console.error(err)
      }
    },
    getCustomModeName: async function() {
      try {
        const response = await apiGet(`/api/place_setting?base_unit_id=${this.baseUnitId}`, this.$router.app.$cookies)
        this.customModeNameList = [
          response.data.mode_name_0 ? response.data.mode_name_0 : 'フリー',
          response.data.mode_name_1 ? response.data.mode_name_1 : 'ケア',
          response.data.mode_name_2 ? response.data.mode_name_2 : 'セット',
        ]
      } catch(err) {
        this.customModeNameList = ['フリー', 'ケア', 'セット']
      }
    },
    moveToDashBoard: function(baseUnitId) {
      this.$router.push(`/place_detail?base_unit_id=${baseUnitId}`)
    },
    moveToScheduleSetting: function(day) {
      this.$router.push(`/schedule/setting?base_unit_id=${this.baseUnitId}&day=${day}`)
    },
    scheduleClass: function(mode) {
      return mode === deviceConfig.mode.atHome ? "free"
        : mode === deviceConfig.mode.monitoring ? "care"
        : mode === deviceConfig.mode.goOut ? "set" : ""
    }
  }
}
</script>

<style scoped>
  @media all and (max-width: 580px) {
    .accordion {
      width: 100%;
      margin: 0 auto 4vw;
    }
    .accordion summary {
      font-size: 5vw;
      background: rgb(200, 220, 245);
      margin-bottom: 2vw;
      padding: .5em 1em;
      border-radius: 2vw;
    }
    .accordion .acd-cont .entry {
      font-size: 4vw;
      margin: 0;
      padding: .8em 1em;
      border-bottom: rgb(200,200,200) solid 1px;
      background: rgb(255,255,255);
    }
    .accordion .acd-cont .entry span {
      display: inline-block;
      width: 8em;
      padding-right: 1em;
    }
    .accordion .btn {
      text-align: center;
      padding: 6vw 0;
    }
  }
  @media all and (min-width: 580px) {
    .accordion {
      width: 700px;
      margin: 0 auto 15px;
    }
    .accordion summary {
      font-size: 2rem;
      background: rgb(200, 220, 245);
      margin-bottom: 10px;
      padding: .5em 2em;
      border-radius: 10px;
    }
    .accordion .acd-cont .entry {
      font-size: 1.6rem;
      margin: 0 0 .5em;
      padding: 0 2em .5em;
      border-bottom: rgb(200,200,200) solid 1px;
    }
    .accordion .acd-cont .entry span {
      display: inline-block;
      width: 8em;
      padding-right: 1em;
    }
    .accordion .btn {
      text-align: center;
      padding: 10px 0;
    }
  }
</style>
