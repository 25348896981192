<template>
  <div class="modal-overlay"></div>
  <section class="detail-wrap">
    <div class="detail-cont">
      <div class="detail-tit">
        <h2>リアルタイムモーション</h2>
        <button v-on:click="closeDetail">閉じる</button>
      </div>

      <div class="movement">
        <div class="marquee">
          <strong
          v-for="roomActiveString in roomActiveStrings"
          :key="roomActiveString">{{ roomActiveString }}</strong>
        </div>
      </div>
      <div class="btns">
        <button v-on:click="changeExtensionUnit(-1)">全て</button>
        <button
          v-for="extensionUnit in extensionUnits"
          :key="extensionUnit.id"
          :class="getButtonClass(extensionUnit)"
          v-on:click="changeExtensionUnit(extensionUnit.id)"
          :style="getButtonStyle(extensionUnit)">
          {{ extensionUnit.room_name }}
        </button>
      </div>
      <div class="chartarea">
        <ActivityChart :data="activityChartData" :startTime="startTime" :extensionUnitId="currentExtensionUnitId"/>
      </div>
      <div class="closebtn">
        <p class="note">親機のリアルタイムデータは表示できません</p>
        <p class="now">{{ dateString }}</p>
      </div>
    </div>
  </section>
</template>

<script>
import { apiGet, apiPost } from '../../api'
import ActivityChart from './ActivityChart'
import { deviceConfig } from '../../constants'

const lineBorderColors = [
  'rgba(60, 179, 113, 1.0)',
  'rgba(160, 79, 113, 1.0)',
  'rgba(65, 105, 225, 1.0)',
  'rgba(128, 128, 0, 1.0)',
  'rgba(255, 255, 0, 1.0)',
  'rgba(255, 0, 255, 1.0)',
  'rgba(0, 255, 255, 1.0)',
  'rgba(0, 255, 0, 1.0)',
  'rgba(255, 0, 0, 1.0)',
  'rgba(0, 0, 255, 1.0)',
  'rgba(0, 128, 0, 1.0)',
  'rgba(128, 0, 128, 1.0)',
  'rgba(0, 0, 128, 1.0)',
  'rgba(0, 128, 128, 1.0)',
  'rgba(128, 0, 0, 1.0)',
]
const lineBackgroundColors = [
  'rgba(60, 179, 113, 0.2)',
  'rgba(160, 79, 113, 0.2)',
  'rgba(65, 105, 225, 0.2)',
  'rgba(128, 128, 0, 0.2)',
  'rgba(255, 255, 0, 0.2)',
  'rgba(255, 0, 255, 0.2)',
  'rgba(0, 255, 255, 0.2)',
  'rgba(0, 255, 0, 0.2)',
  'rgba(255, 0, 0, 0.2)',
  'rgba(0, 0, 255, 0.2)',
  'rgba(0, 128, 0, 0.2)',
  'rgba(128, 0, 128, 0.2)',
  'rgba(0, 0, 128, 0.2)',
  'rgba(0, 128, 128, 0.2)',
  'rgba(128, 0, 0, 0.2)',
]

export default {
  name: 'PlaceDetail',
  components: {
    ActivityChart,
  },
  data () {
    const date = new Date()
    return {
      dateString: date.toLocaleDateString(),
      currentExtensionUnitId: -1,
      realTimeData: [],
      untilLimitSeconds: null,
      activityChartData: {
        datasets: []
      },
      roomActiveStrings: [],
      activeExtensionUnitIdList: [],
      startTime: new Date(),
      isClosed: false,
      activityLevelThreshold1: deviceConfig.defaultActivityLevelThreshold[0],
      activityLevelThreshold2: deviceConfig.defaultActivityLevelThreshold[1],
      activityLevelThreshold3: deviceConfig.defaultActivityLevelThreshold[2],
    }
  },
  props: {
    baseUnitId: {
      type: Number,
      required: true
    },
    extensionUnits: {
      type: Array,
      required: true
    }
  },
  emits: ['closeDetail'],
  async mounted() {
    this.getPlaceSetting()
    await this.startFetchingRealtimeData()
  },
  methods: {
    getPlaceSetting: async function() {
      try {
        const response = await apiGet(`/api/place_setting?base_unit_id=${this.baseUnitId}`, this.$router.app.$cookies)
        this.activityLevelThreshold1 = response.data.activity_level_threshold_1 ? response.data.activity_level_threshold_1 : deviceConfig.defaultActivityLevelThreshold[0]
        this.activityLevelThreshold2 = response.data.activity_level_threshold_2 ? response.data.activity_level_threshold_2 : deviceConfig.defaultActivityLevelThreshold[1]
        this.activityLevelThreshold3 = response.data.activity_level_threshold_3 ? response.data.activity_level_threshold_3 : deviceConfig.defaultActivityLevelThreshold[2]
      } catch(err) {
        this.activityLevelThreshold1 = deviceConfig.defaultActivityLevelThreshold[0]
        this.activityLevelThreshold2 = deviceConfig.defaultActivityLevelThreshold[1]
        this.activityLevelThreshold3 = deviceConfig.defaultActivityLevelThreshold[2]
      }
    },
    closeDetail() {
      this.$emit("closeDetail")
      this.isClosed = true
    },
    changeExtensionUnit(unitId) {
      this.currentExtensionUnitId = unitId
    },
    startFetchingRealtimeData: async function() {
      const _this = this

      const heartbeatTimeInterval = 5000
      const heartbeatCountMax = 12
      let heartbeatCount = 1
      this.heartbeatRealtimeData()
      const heartbeatIntervalId = setInterval(function() {
        if (heartbeatCount > heartbeatCountMax || _this.isClosed) {
          clearInterval(heartbeatIntervalId)
          return
        }
        _this.heartbeatRealtimeData()
        heartbeatCount += 1
      }, heartbeatTimeInterval)

      const loadRealtimeDataTimeIntarval = 1000
      this.loadRealtimeData()
      const intervalId = setInterval(function() {
        if (_this.isClosed) {
          clearInterval(intervalId)
          _this.isLoading = false
          return
        }
        _this.loadRealtimeData()
      }, loadRealtimeDataTimeIntarval)
    },
    heartbeatRealtimeData: async function() {
      try {
        await apiPost('/api/real_time_data/heartbeat',{
          base_unit_id: this.baseUnitId
        }, this.$router.app.$cookies)
      } catch (error) {
        console.error(error.value)
      }
    },
    loadRealtimeData: async function() {
      try {
        const response = await apiGet(`/api/real_time_data?base_unit_id=${this.baseUnitId}`, this.$router.app.$cookies)
        this.realTimeData = response.data.data
        this.untilLimitSeconds = response.data.until_limit_seconds
        this.renderRealTimeData()
      } catch (error) {
        console.error(error)
      }
    },
    renderRealTimeData: function() {
      const datasets = []
      let unitColorIndex = -1

      this.maxActiveLevelByRoom = {}
      this.extensionUnits.forEach((extensionUnit) => {
        this.maxActiveLevelByRoom[extensionUnit.id] = {
          roomName: extensionUnit.room_name,
          level: 0
        }
        unitColorIndex += 1
        extensionUnit.color = lineBorderColors[unitColorIndex]

        if (this.currentExtensionUnitId !== -1 && this.currentExtensionUnitId !== extensionUnit.id) {
          return
        }

        const activityLineData = this.realTimeData.flatMap(data => {
          if (data.device_id != extensionUnit.device_id) {
            return null
          }

          const x = new Date(data.timestamp * 1000)
          if (x > this.startTime && data.statistics > this.maxActiveLevelByRoom[extensionUnit.id].level) {
            this.maxActiveLevelByRoom[extensionUnit.id].level = data.statistics
          }
          return {
            x: x,
            y: Math.max(data.statistics, 0)
          }
        }).filter(Boolean)
        if (activityLineData.length === 0) {
          return
        }

        datasets.push({
          type: 'line',
            data: activityLineData,
            borderColor: lineBorderColors[unitColorIndex],
            backgroundColor: lineBackgroundColors[unitColorIndex],
        })
      })

      this.activityChartData = {
        datasets: datasets
      }

      this.roomActiveStrings = []
      this.activeExtensionUnitIdList = []
      Object.keys(this.maxActiveLevelByRoom).forEach(id => {
        if (this.maxActiveLevelByRoom[id].level > this.activityLevelThreshold3) {
          this.roomActiveStrings.push(`${this.maxActiveLevelByRoom[id].roomName}で大きな動きがあります`)
          this.activeExtensionUnitIdList.push(Number(id))
        } else if (this.maxActiveLevelByRoom[id].level > this.activityLevelThreshold2) {
          this.roomActiveStrings.push(`${this.maxActiveLevelByRoom[id].roomName}で動きがあります`)
          this.activeExtensionUnitIdList.push(Number(id))
        } else if (this.maxActiveLevelByRoom[id].level > this.activityLevelThreshold1) {
          this.roomActiveStrings.push(`${this.maxActiveLevelByRoom[id].roomName}で少し動きがあります`)
        }
      })
    },
    getButtonClass: function(extensionUnit) {
      return this.activeExtensionUnitIdList.includes(extensionUnit.id) ? 'active' : ''
    },
    getButtonStyle: function(extensionUnit) {
      return {
        background: extensionUnit.color
      }
    }
  }
}
</script>

<style scoped>
  @media all and (max-width: 580px) {
    .detail-tit {
      padding-bottom: 6vw;
    }
    .detail-tit p {
      width: 100%;
      font-size: 3.4vw;
    }
    .btns {
      display: flex;
      flex-flow: row wrap;
    }
    .btns button {
      position: relative;
      font-size: 3.8vw;
      width: 30%;
      margin: 0 2vw 2vw 0;
      padding: .5em 1em;
      word-break: break-all;
      text-shadow: rgba(250,250,250, 1) 0em 0em .2em;
    }
    .btns button.active::after {
      position: absolute;
      top: 2vw;
      left: 0;
      content: '';
      width: 4vw;
      height: 4vw;
      background: url(@/assets/img/alert.svg) center no-repeat;
      background-size: contain;
    }
    .movement {
      font-size: 3vw;
      display: flex;
      white-space: nowrap;
      overflow: hidden;
      width: 100%;
      height: 2em;
      margin: 0 0 4vw;
      padding: .2em 0;
      gap: 1rem;
      background: rgb(255, 240, 210);
    }
    .marquee {
      transform: translateX(100%);
      animation: marquee 15s linear infinite;
    }
    .movement .marquee strong {
      padding: 0 1em;
    }
  }
  @media all and (min-width: 580px) {
    .detail-tit p {
      font-size: 1.6rem;
    }
    .btns button {
      position: relative;
      margin: 0 10px 10px 0;
      word-break: break-all;
      text-shadow: rgba(0,0,0, 1) 1px 1px 4px;
    }
    .btns button.active {
      border: rgb(255, 104, 3) solid 4px;
    }
    .btns button.active::after {
      position: absolute;
      top: -10px;
      left: 0;
      content: '';
      width: 20px;
      height: 20px;
      background: url(@/assets/img/alert.svg) center no-repeat;
      background-size: contain;
    }
    .chartarea {
      height: 50vh;
    }
    .chartarea canvas {
      width: 100%;
      margin: auto;
    }
    .movement {
      font-size: 20px;
      display: flex;
      white-space: nowrap;
      overflow: hidden;
      width: 100%;
      height: 2em;
      margin: 0 0 15px;
      padding: .2em 0;
      gap: 1rem;
      background: rgb(255, 240, 210);
      box-sizing: border-box;
    }
    .marquee {
      display: block;
      width: 100%;
      animation: marquee 15s linear infinite;
    }
    .movement .marquee strong {
      padding: 0 1em;
    }
  }
  @keyframes marquee {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(-100%);
    }
  }
</style>
