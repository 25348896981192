<template>
  <Header />
  <main>
    <div class="cont">
      <BackButton/>
      <h1>会社マスタ</h1>

      <section>
        <div class="form-items">
          <p>
            <label for="searchCompanyName">運営会社名</label><br>
            <input
              id="searchCompanyName"
              placeholder="運営会社名"
              autocomplete="off"
              type="text"
              v-model="searchCompanyName"
            >
          </p>
          <p>
            <label for="searchFacilityName">施設名</label><br>
            <input
              id="searchFacilityName"
              placeholder="施設名"
              autocomplete="off"
              type="text"
              v-model="searchFacilityName"
            >
          </p>

          <label><input type="radio" id="searchCompanyStatusOption1" :value="companyStatus.active" v-model="searchCompanyStatus" />
          契約中</label>
          <label><input type="radio" id="searchCompanyStatusOption2" :value="companyStatus.cancel" v-model="searchCompanyStatus" />
          契約破棄</label>
          <label><input type="radio" id="searchCompanyStatusOption3" :value="companyStatus.inactive" v-model="searchCompanyStatus" />
          中断</label>
          <button v-on:click="searchCompany">検索</button>
        </div>

        <dl class="result" v-for="company in hitCompanies" :key="company.id">
          <dt>{{ company.name }}</dt>
          <dd><em class="linktxt" v-on:click="editCompany(company.id)">編集</em></dd>
        </dl>

        <div class="new"><button v-on:click="editCompany()">新規作成</button></div>
      </section>
    </div>
  </main>
  <Footer />
</template>

<script>
import Header from '../Header'
import Footer from '../Footer'
import { apiGet } from '../../api'
import { companyStatus } from '../../constants'

export default {
  name: 'CompanyMaster',
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      searchCompanyName: '',
      searchFacilityName: '',
      searchCompanyStatus: companyStatus.active,
      hitCompanies: [],
      companyStatus: companyStatus
    }
  },
  methods: {
    searchCompany: async function() {
      var query = `status=${this.searchCompanyStatus}`
      if (this.searchCompanyName !== '') {
        query += `&name=${this.searchCompanyName}`
      }
      if (this.searchFacilityName !== '') {
        query += `&facility_name=${this.searchFacilityName}`
      }
      const response = await apiGet(`/api/admin/company/search?${query}`, this.$router.app.$cookies)
      this.hitCompanies = response.data
    },
    editCompany: function (companyId) {
      if (companyId) {
        location.href = `/admin/edit_company?id=${companyId}`
      } else {
        location.href = `/admin/edit_company`
      }
    }
  }
}
</script>

<style scoped>
  @media all and (max-width: 580px) {
    .cont section {
      padding: 10vw 0;
    }
    .cont section .form-items {
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
      align-items: center;
      font-size: 3.8vw;
      padding: 0 0 1.4em;
    }
    .cont section .form-items p {
      width: 96vw;
      margin: auto;
      padding: 0 0 1em;
    }
    .cont section .form-items input[type="text"] {
      box-sizing: border-box;
      font-size: 3.8vw;
      width: 100%;
      padding: .4em;
    }
    .cont section .form-items label {
      display: inline-block;
      padding-right: 1em;
    }
    .cont section .result {
      padding: 0;
      font-size: 3.8vw;
      background: rgb(255,255,255);
      border-bottom: rgb(200,230,255) solid 1px;
    }
    .cont section .result dt {
      padding: .5em 1em 0;
    }
    .cont section .result dd {
      text-align: right;
    }
    .cont section .result em {
      display: inline-block;
      font-style: normal;
      padding: .5em 1em;
    }
    .cont section .new {
      padding: 30px 0 0;
      text-align: center;
    }
  }

  @media all and (min-width: 580px) {
    .cont section {
      padding: 20px 0;
    }
    .cont section .form-items {
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
      align-items: flex-end;
      font-size: 16px;
      padding: 0 0 20px;
    }
    .cont section .form-items input[type="text"] {
      font-size: 16px;
      width: 16em;
    }
    .cont section .result {
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
      padding: 5px 0 5px;
      font-size: 16px;
      border-bottom: rgb(200,230,255) solid 1px;
    }
    .cont section .result:nth-of-type(2n) {
      background: rgb(226, 240, 250);
    }
    .cont section .result dt {
      min-width: 20em;
      padding: .5em 1em;
    }
    .cont section .result em {
      display: inline-block;
      font-style: normal;
      padding: .5em 1em;
    }
    .cont section .new {
      padding: 30px 0 0;
      text-align: center;
    }
  }
</style>
