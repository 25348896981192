<template>
  <main>
    <Header />
    <div class="cont">
      <div class="page-tit">
        <h1>日別スケジュール設定 : <span>{{ dayName }}</span></h1>
        <p class="nav-links">
          <span class="link" v-on:click="moveToScheduleTop">スケジュール設定に戻る</span>
        </p>
      </div>

      <div class="set">
        <div class="panel">
          <p v-if="editingSchedule" class="modestate"><em class="alert">赤枠を編集中</em><span v-on:click="stopEditing" class="linktxt">編集モードをやめる</span></p>
          <span><b>開始時間</b><input type="time" v-model="selectingStartTime"/></span>
          <span><b>〜 終了時間</b><input type="time" v-model="selectingEndTime"/></span>
          <span><b>モード</b>
            <select v-model="selectingMode" v-if="customModeNameList" >
              <option :value="deviceConfigMode.monitoring">{{ customModeNameList[deviceConfigMode.monitoring] }}</option>
              <option :value="deviceConfigMode.goOut">{{ customModeNameList[deviceConfigMode.goOut] }}</option>
            </select>
          </span>
          <span>
            <button v-if="editingSchedule" v-on:click="saveSchedule">変更する</button>
            <button v-else v-on:click="addSchedule">追加する</button>
          </span>
        </div>
        <div v-if="resultMessage" class="complete"><p class="alert">{{ resultMessage }}</p></div>
        <p>※設定内容の変更には数秒程度かかる場合があります。すぐに変わらない場合はしばらくしてからご確認ください。</p>
      </div>

      <div class="entry-list" v-if="scheduleList">
        <ul v-for="schedule in scheduleList" :key="schedule.start_time" :class="getEditing(schedule.start_time)">
          <li><span>{{ renderScheduleTime(schedule) }}</span></li>
          <li v-if="customModeNameList" :class="scheduleClass(schedule.mode)"><span>{{ customModeNameList[schedule.mode] }}</span></li>
          <li v-on:click="editSchedule(schedule)"><em>編集</em></li>
          <li v-on:click="moveToScheduleCopy(schedule)"><em>別の曜日にコピー</em></li>
          <li v-on:click="deleteSchedule(schedule)"><em>削除</em></li>
        </ul>
      </div>
    </div>
  </main>
  <Footer />
</template>

<script>
import Header from '../Header'
import Footer from '../Footer'
import { apiGet, apiPost } from '../../api'
import { dayOfTheWeek, deviceConfig } from '../../constants'

export default {
  name: 'ScheduleSetting',
  components: {
    Header,
    Footer,
  },
  data () {
    return {
      baseUnitId: this.$route.query.base_unit_id,
      dayName: dayOfTheWeek[this.$route.query.day],
      deviceConfigMode: deviceConfig.mode,
      deviceConfigModeName: deviceConfig.modeName,
      scheduleList: null,
      selectingMode: 1,
      selectingStartTime: null,
      selectingEndTime: null,
      editingSchedule: null,
      reloadTimeMsec: 1000,
      resultMessage: null,
      customModeNameList: null,
    }
  },
  mounted() {
    this.loadData()
  },
  methods: {
    loadData: async function() {
      this.getBaseUnit()
      this.getSchedule()
      this.getCustomModeName()
    },
    getBaseUnit: async function() {
      try {
        const response = await apiGet(`/api/base_unit?id=${this.baseUnitId}`, this.$router.app.$cookies)
        this.baseUnitDetail = response.data
        this.facilityName = response.data.facility_name
        this.baseUnitsOfFacility = response.data.base_units_of_facility
      } catch(err) {
        console.error(err)
      }
    },
    getSchedule: async function() {
      try {
        const response = await apiGet(`/api/schedule?base_unit_id=${this.baseUnitId}`, this.$router.app.$cookies)
        this.scheduleList = response.data[this.$route.query.day]
      } catch(err) {
        console.error(err)
      }
    },
    getCustomModeName: async function() {
      try {
        const response = await apiGet(`/api/place_setting?base_unit_id=${this.baseUnitId}`, this.$router.app.$cookies)
        this.customModeNameList = [
          response.data.mode_name_0 ? response.data.mode_name_0 : 'フリー',
          response.data.mode_name_1 ? response.data.mode_name_1 : 'ケア',
          response.data.mode_name_2 ? response.data.mode_name_2 : 'セット',
        ]
      } catch(err) {
        this.customModeNameList = ['フリー', 'ケア', 'セット']
      }
    },
    moveToScheduleTop: function() {
      this.$router.push(`/schedule/top?base_unit_id=${this.baseUnitId}`)
    },
    moveToScheduleCopy: function(schedule) {
      this.$router.push(`/schedule/copy?base_unit_id=${this.baseUnitId}&day=${this.$route.query.day}&start_time=${schedule.start_time}&end_time=${schedule.end_time}&mode=${schedule.mode}`)
    },
    addSchedule: async function() {
      if (!this.checkInput()) {
        this.resultMessage = "開始時間は終了時間よりも前にしてください。"
        return
      }

      const payload = {
        base_unit_id: this.baseUnitId,
        mode: this.selectingMode,
        day: this.$route.query.day,
        start_time: this.selectingStartTime,
        end_time: this.selectingEndTime,
      }
      const response = await apiPost(`/api/schedule/add`, payload, this.$router.app.$cookies)
      if (response.data) {
        setTimeout( this.getSchedule, this.reloadTimeMsec)
        this.resultMessage = "スケジュールを追加しました。"
        this.selectingStartTime = null
        this.selectingEndTime = null
        this.selectingMode = 1
      } else {
        this.resultMessage = "追加に失敗しました。"
      }
    },
    editSchedule: function(schedule) {
      if (this.editingSchedule !== schedule) {
        this.selectingStartTime = schedule.start_time
        this.selectingEndTime = schedule.end_time
        this.selectingMode = schedule.mode
        this.editingSchedule = schedule
      } else {
        this.editingSchedule = null
      }
    },
    deleteSchedule: async function(schedule) {
      const confirmResult = window.confirm("このスケジュールを本当に削除してよろしいですか？")
      if (!confirmResult) {
        return
      }

      const payload = {
        base_unit_id: this.baseUnitId,
        day: this.$route.query.day,
        start_time: schedule.start_time,
        end_time: schedule.end_time
      }
      const response = await apiPost(`/api/schedule/delete`, payload, this.$router.app.$cookies)
      if (response.data) {
        setTimeout( this.getSchedule, this.reloadTimeMsec)
        this.resultMessage = "スケジュールを削除しました。"
      } else {
        this.resultMessage = "削除に失敗しました。"
      }
    },
    saveSchedule: async function() {
      if (!this.checkInput()) {
        this.failureMessage = "開始時間は終了時間よりも前にしてください。"
        return
      }

      const payload = {
        base_unit_id: this.baseUnitId,
        mode: this.selectingMode,
        day: this.$route.query.day,
        start_time: this.selectingStartTime,
        end_time: this.selectingEndTime,
        old_start_time: this.editingSchedule.start_time
      }
      const response = await apiPost(`/api/schedule/edit`, payload, this.$router.app.$cookies)
      if (response.data) {
        this.editingSchedule = null
        setTimeout( this.getSchedule, this.reloadTimeMsec)
        this.resultMessage = "スケジュールを保存しました。"
        this.selectingStartTime = null
        this.selectingEndTime = null
        this.selectingMode = 1
      } else {
        this.resultMessage = "保存に失敗しました。"
      }
    },
    renderScheduleTime: function(schedule) {
      return `${schedule.start_time}〜${schedule.end_time}`
    },
    renderScheduleMode: function(mode) {
      return mode === deviceConfig.mode.atHome ? deviceConfig.modeName.atHome
        : mode === deviceConfig.mode.monitoring ? deviceConfig.modeName.monitoring
        : mode === deviceConfig.mode.goOut ? deviceConfig.modeName.goOut : ""
    },
    scheduleClass: function(mode) {
      return mode === deviceConfig.mode.atHome ? "free"
        : mode === deviceConfig.mode.monitoring ? "care"
        : mode === deviceConfig.mode.goOut ? "set" : ""
    },
    getEditing: function(startTime) {
      if (this.editingSchedule === null) {
        return ''
      }
      return this.editingSchedule.start_time === startTime ? 'editing' : ''
    },
    stopEditing: function() {
      this.editingSchedule = null
      this.selectingStartTime = null
      this.selectingEndTime = null
      this.selectingMode = 1
      this.resultMessage = null
    },
    checkInput: function() {
      return this.selectingStartTime < this.selectingEndTime
    }
  }
}
</script>

<style scoped>
.editing {
  background-color: rgb(240, 200, 200);
}
@media all and (max-width: 580px) {
  .set {
    font-size: 3.8vw;
    padding-bottom: 1em;
  }
  .set .panel .modestate {
    text-align: center;
  }
  .set .panel .modestate em {
    font-style: normal;
    padding-right: 1em;
  }
  .set .panel {
    padding-bottom: 1em;
    text-align: center;
  }
  .set .panel span {
    display: inline-block;
    padding: 0 1em 1em 0;
  }
  .set .panel span b {
    font-weight: normal;
    padding-right: .5em;
  }
  .set input,
  .set select {
    font-size: 3.8vw;
  }
  .entry-list {
    font-size: 3.8vw;
    padding-bottom: 1em;
  }
  .entry-list ul {
    margin-bottom: 4vw;
    padding: .5em 0;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
    border-radius: 2vw;
    background: rgb(255,255,255);
  }
  .entry-list ul.editing {
    background-color: rgb(240, 200, 200);
  }
  .entry-list ul li {
    padding: 0 .5em
  }
  .entry-list ul li:nth-of-type(3) {
  }
  .entry-list ul li span {
    display: inline-block;
    width: 42vw;
    text-align: center;
    padding: 1em 0;
  }
  .entry-list ul li em {
    display: inline-block;
    padding: .5em 1em;
    font-style: normal;
    color: rgb(70, 150, 255);
    cursor: pointer;
    transition: all  0.3s ease;
  }
  .entry-list ul li em:hover {
    color: rgb(0, 80, 190);
  }
  .complete {
    padding: 0 0 1.5em;
  }
}
@media all and (min-width: 580px) {
  .set {
    font-size: 1.6rem;
    padding-bottom: 2em;
  }
  .set .panel .modestate {
    padding-bottom: .5em;
  }
  .set .panel .modestate em {
    font-style: normal;
    padding-right: 1em;
  }
  .set .panel {
    padding-bottom: 1em;
  }
  .set .panel span {
    display: inline-block;
    padding-right: 1em;
  }
  .set .panel span b {
    font-weight: normal;
    padding-right: .5em;
  }
  .set input {
    font-size: 2rem;
  }
  .set select {
    font-size: 1.6rem;
  }
  .entry-list {
    font-size: 1.6rem;
    padding-bottom: 1em;
  }
  .entry-list ul {
    width: 700px;
    margin: auto;
    padding: .5em;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
    border-radius: 10px;
  }
  .entry-list ul li {
    padding: 0 .5em
  }
  .entry-list ul li:nth-of-type(3) {
  }
  .entry-list ul li span {
    display: inline-block;
    width: 8em;
  }
  .entry-list ul li em {
    display: inline-block;
    padding: .5em 1em;
    font-style: normal;
    color: rgb(70, 150, 255);
    cursor: pointer;
    transition: all  0.3s ease;
  }
  .entry-list ul li em:hover {
    color: rgb(0, 80, 190);
  }
  .complete {
    padding: 0 0 1.5em;
  }
}
</style>
