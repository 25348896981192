<template>
  <Header />
  <main>
    <div class="cont">
      <BackButton/>
      <h1>会社編集</h1>

      <section>
        <dl>
          <dt>会社名</dt>
          <dd>
            <input
              id="companyName"
              placeholder="会社名"
              autocomplete="off"
              type="text"
              v-model="company.name"
            >
          </dd>
        </dl>
        <dl>
          <dt>会社名（ふりがな）</dt>
          <dd>
            <input
              id="companyNameHiragana"
              placeholder="会社名（ふりがな）"
              autocomplete="off"
              type="text"
              v-model="company.name_hiragana"
            >
          </dd>
        </dl>
        <dl>
          <dt>所在地</dt>
          <dd>
            <p>
              <input
                id="companyPostalCode"
                placeholder="郵便番号"
                autocomplete="off"
                type="text"
                size="12"
                v-model="company.postal_code"
              >
            </p>
            <p>
              <input
                id="companyAddress"
                placeholder="住所"
                autocomplete="off"
                type="text"
                size="30"
                v-model="company.address"
              >
            </p>
          </dd>
        </dl>
        <dl>
          <dt>所在地（ふりがな）</dt>
          <dd>
            <input
              id="companyAddressHiragana"
              placeholder="会社所在地（ふりがな）"
              autocomplete="off"
              type="text"
              v-model="company.address_hiragana"
            >
          </dd>
        </dl>
        <dl>
          <dt>電話番号</dt>
          <dd>
            <input
              id="companyPhoneNumber"
              placeholder="電話番号"
              autocomplete="off"
              type="text"
              v-model="company.phone_number"
            >
          </dd>
        </dl>
        <dl v-for="(emergencyContact, index) in emergencyContacts" :key="index">
          <dt>緊急連絡先{{ index+1 }}</dt>
          <dd>
            <input
              :id="'emergencyContactName' + index"
              placeholder="氏名"
              autocomplete="off"
              type="text"
              v-model="emergencyContact.name"
            >
            <input
              :id="'emergencyContactNameHiragana' + index"
              placeholder="氏名(ふりがな)"
              autocomplete="off"
              type="text"
              v-model="emergencyContact.name_hiragana"
            >
            <input
              :id="'emergencyContactNameHiragana' + index"
              placeholder="電話番号"
              autocomplete="off"
              type="text"
              v-model="emergencyContact.phone_number"
            >
          </dd>
        </dl>
        <dl>
          <dt>ステータス</dt>
          <dd>
            <select v-model="company.status">
              <option
                v-for="item in companyStatusItems"
                :value="item.id"
                :key="item.id"
                :selected="company.status === item.id"
              >
                {{ item.label }}
              </option>
            </select>
          </dd>
        </dl>
        <dl v-if="company.id">
          <dt>会社ID:</dt>
          <dd>
            {{ company.id }}
          </dd>
        </dl>
        <dl>
          <dt>備考</dt>
          <dd>
            <textarea
              placeholder="備考"
              v-model="company.remarks"
            ></textarea>
          </dd>
        </dl>
        <div class="enter"><button v-on:click="saveCompany()">内容を保存</button></div>
      </section>
    </div>
  </main>
  <Footer />
</template>

<script>
import Header from '../Header'
import Footer from '../Footer'
import { apiGet, apiPost } from '../../api'
import { companyStatus, userStatus } from '../../constants'

export default {
  name: 'EditCompanyMaster',
  components: {
    Header,
    Footer
  },
  data() {
    return {
      emergencyContactCount: 3,
      company: {},
      companyStatusItems: [
        { id: companyStatus.active, label: '契約中' },
        { id: companyStatus.cancel, label: '契約破棄' },
        { id: companyStatus.inactive, label: '中断'},
      ],
      emergencyContacts: [],
      companyAdminUsers: [],
      companyAdminUserStatusItems: [
        { id: userStatus.active, label: '利用中' },
        { id: userStatus.inactive, label: '利用停止' },
      ],
      newCompanyAdminUser: {
        email: '',
        name: '',
        status: userStatus.active,
      },
      addAdminUserError: null,
    }
  },
  mounted() {
    this.loadCompany()
  },
  methods: {
    loadCompany: async function() {
      if (typeof(this.$route.query.id) === 'undefined') {
        this.company = {
          address: '',
          address_hiragana: '',
          name: '',
          name_hiragana: '',
          phone_number: '',
          postal_code: '',
          remarks: '',
          status: companyStatus.active
        }
        return
      }

      try {
        const response = await apiGet(`/api/admin/company?id=${this.$route.query.id}`, this.$router.app.$cookies)
        this.company = response.data.company
      } catch (error) {
        console.error(error)
      }
    },
    saveCompany: async function() {
      const body = {
        company: this.company,
      }
      const response = await apiPost('/api/admin/company', body, this.$router.app.$cookies)
      if (response.data.success) {
        location.href = `/admin/edit_company?id=${response.data.company_id}`
      }
    },
  }
}
</script>

<style scoped>
  @media all and (max-width: 580px) {
    .cont section dl {
      box-sizing: border-box;
      font-size: 3.8vw;
      background: rgb(255,255,255);
      border-bottom: rgb(200,230,255) solid 1px;
      padding: 0;
    }
    .cont section dl dt {
      background: rgb(210, 235, 255);
      padding: .4em;
    }
    .cont section dl dd {
      padding: .4em;
    }
    .cont section dl select,
    .cont section dl input,
    .cont section dl textarea {
      box-sizing: border-box;
      font-size: 3.8vw;
      width: 100%;
    }
    .cont section dl dd p {
      padding: 0 0 .5em;
    }
    .cont section dl dd p:last-child {
      padding: 0;
    }
    .cont section .enter {
      padding: 6vw 0 0;
      text-align: center;
    }
  }

  @media all and (min-width: 580px) {
    .cont section dl {
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
      font-size: 16px;
      border-bottom: rgb(200,230,255) solid 1px;
      padding: .5em 1em;
    }
    .cont section dl:nth-of-type(2n) {
      background: rgb(226, 240, 250);
    }
    .cont section dl dt {
      width: 18%;
    }
    .cont section dl dd {
      width: 81%;
    }
    .cont section dl select,
    .cont section dl input,
    .cont section dl textarea {
      font-size: 16px;
    }
    .cont section dl dd p {
      padding: 0 0 .5em;
    }
    .cont section dl dd p:last-child {
      padding: 0;
    }
    .cont section .enter {
      padding: 30px 0 0;
      text-align: center;
    }
  }
</style>

