<template>
  <main>
    <Header />
    <div class="cont">
      <div class="btns">
        <button v-if="$router.app.userDetail.is_super_admin" v-on:click="moveToEditCompany">会社名編集</button>
        <button v-on:click="moveToEditAccount">アカウント</button>
      </div>
      <section v-for="company in companies" v-bind:key="company.id" class="main-list">
        <h2>{{ company.name }}</h2>
        <div class="list-wrap">
          <ul class="tit">
            <li class="openclose">&nbsp;</li>
            <li class="baseunit">施設名（設置数）</li>
            <li class="state">ケアモード<br>検知状態</li>
            <li class="state">セットモード<br>検知状態</li>
            <li class="log"><span>過去</span>ログ</li>
          </ul>
          <ul v-for="facility in company.facilities" v-bind:key="facility.id" class="data">
            <li class="openclose">
              <div v-if="expandingFacilityIdList.includes(facility.id)">
                <label v-on:click="toggleExpandingFacility(facility.id)"><img src="@/assets/img/icon-close.svg" alt="とじる" /></label>
              </div>
              <div v-else>
                <label v-on:click="toggleExpandingFacility(facility.id)"><img src="@/assets/img/icon-open.svg" alt="ひらく" /></label>
              </div>
            </li>
            <li class="baseunit" v-on:click="showFacilityDetail(facility)">
              <p class="linktxt">{{ facility.name }}（{{ facility.base_units.length }}）</p>
            </li>
            <li class="state mode-monitoring">
              <p :class="getStateClass(facility.monitoring_min_activity_level, facility.no_activity_alert)">
                <label>検知状態</label>
              </p>
            </li>
            <li class="state mode-go-out">
              <p :class="getStateClass(facility.go_out_max_activity_level)">
                <label>検知状態</label>
              </p>
            </li>
            <li class="log" v-on:click="showAlertLog(facility.name, facility.id)">
              表示
            </li>
            <li v-if="expandingFacilityIdList.includes(facility.id)" class="moreinfo">
              <ul class="tit">
                <li class="m-base">場所名（モード変更）</li>
                <li class="m-modelnum">型番</li>
                <li class="m-state">検知状態</li>
                <li class="m-mode">モード</li>
                <li class="m-extension">子機数</li>
                <li class="link-edit">情報を見る</li>
              </ul>
              <ul v-for="baseUnit in facility.base_units" v-bind:key="baseUnit.id" class="m-data" :class="getModeClass(baseUnit.mode)">
                <li class="m-base">
                  <label v-on:click="moveToDetail(baseUnit.id)">{{ baseUnit.place_name }}</label>
                </li>
                <li class="m-modelnum">
                  {{ baseUnit.device_id }}
                </li>
                <li class="m-state">
                  <p :class="getBaseUnitStateClass(baseUnit)"></p>
                </li>
                <li class="m-mode">
                  {{ renderMode(baseUnit.mode) }}
                </li>
                <li class="m-extension">
                  {{ baseUnit.extension_units.length }}台
                </li>
                <li class="link-edit">
                  <span v-on:click="moveToEditBaseUnit(baseUnit.id)">情報確認</span>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </section>

      <div v-if="alertLogFacilityId">
        <div class="modal-overlay"></div>
        <section class="log-list detail-wrap">
          <div class="log-tit">
            <h2>{{ alertLogFacilityName }}</h2>
            <em class="close-btn"><button v-on:click="closeAlertLog">閉じる</button></em>
          </div>
          <p class="note"><b>表示は最大で100件まで。</b>それ以上の情報を取得される場合はお問い合わせください</p>
          <ul class="list">
            <li v-for="alertLog in alertLogs" v-bind:key="alertLog.id">{{ renderAlertLog(alertLog) }}</li>
          </ul>
        </section>
      </div>

      <div v-if="facilityDetail">
        <div class="modal-overlay"></div>
        <section class="address-data detail-wrap">
          <div class="address-tit">
            <h2>施設所在地</h2>
            <em class="close-btn"><button v-on:click="closeFacilityDetail">閉じる</button></em>
          </div>
          <div class="data">
            <p>{{ facilityDetail.name }}</p>
            <p><span>〒</span>{{ facilityDetail.postcode }}</p>
            <p>{{ facilityDetail.address }}</p>
            <p><span>Tel : </span>{{ facilityDetail.tel }}</p>
          </div>
        </section>
      </div>

    </div>
  </main>
  <Footer />
</template>

<script>
import Header from './Header'
import Footer from './Footer'
import { apiGet } from '../api'
import { deviceConfig } from '@/constants'

export default {
  name: 'Top',
  components: {
    Header,
    Footer
  },
  data: function() {
    return {
      companies: [],
      facilityDetail: null,
      expandingFacilityIdList: [],
      alertLogFacilityId: null,
      alertLogFacilityName: null,
      alertLogs: []
    }
  },
  mounted() {
    this.loadCompanies()
  },
  methods: {
    loadCompanies: async function() {
      const response = await apiGet('/api/company/monitor', this.$router.app.$cookies)
      this.companies = response.data
      this.companies.forEach(company => {
        company.facilities.forEach(facility => {
          this.expandingFacilityIdList.push(facility.id)
        })
      })
    },
    toggleExpandingFacility: function(facilityId) {
      if (this.expandingFacilityIdList.includes(facilityId)){
        this.expandingFacilityIdList.remove
        this.expandingFacilityIdList = this.expandingFacilityIdList.filter(item => item !== facilityId)
      } else {
        this.expandingFacilityIdList.push(facilityId)
      }
    },
    moveToDetail: function(baseUnitId) {
      this.$router.push(`/place_detail?base_unit_id=${baseUnitId}`)
    },
    moveToEditCompany: function() {
      this.$router.push(`/edit_company`)
    },
    moveToEditAccount: function() {
      this.$router.push(`/edit_account`)
    },
    moveToEditBaseUnit: function(baseUnitId) {
      this.$router.push(`/edit_base_unit?id=${baseUnitId}`)
    },
    renderMode: function(mode) {
      return mode == deviceConfig.mode.atHome ? 'フリー'
        : mode == deviceConfig.mode.monitoring ? 'ケア'
        : mode == deviceConfig.mode.goOut ? 'セット'
        : ''
    },
    getStateClass: function(activityLevel, noActivityAlert=false) {
      var result = this.getActiveLevelClass(activityLevel)
      if (noActivityAlert) {
        result += " no-activity-alert"
      }
      return result
    },
    getActiveLevelClass: function(activityLevel) {
      if (typeof(activityLevel) !== "number") {
        return 'activity-level-null'
      }

      if (activityLevel > 80) {
        return 'activity-level-very-high'
      } else if (activityLevel > 60) {
        return 'activity-level-high'
      } else if (activityLevel > 40) {
        return 'activity-level-medium'
      } else if (activityLevel > 30) {
        return 'activity-level-low'
      } else {
        return 'activity-level-very-low'
      }
    },
    getBaseUnitStateClass: function(baseUnit) {
      if (baseUnit.mode === deviceConfig.mode.monitoring) {
        return this.getStateClass(baseUnit.min_activity_level, baseUnit.no_activity_alert)
      } else {
        return this.getStateClass(baseUnit.max_activity_level)
      }
    },
    getModeClass: function(mode) {
      return mode == deviceConfig.mode.atHome ? 'mode-at-home'
        : mode == deviceConfig.mode.monitoring ? 'mode-monitoring'
        : mode == deviceConfig.mode.goOut ? 'mode-go-out'
        : 'mode-unknown'
    },
    showAlertLog: async function(facilityName, facilityId) {
      const response = await apiGet(`/api/facility/alert_email_log?facility_id=${facilityId}`, this.$router.app.$cookies)
      this.alertLogFacilityId = facilityId
      this.alertLogFacilityName = facilityName
      this.alertLogs = response.data
    },
    closeAlertLog: function() {
      this.alertLogFacilityId = null
      this.alertLogFacilityName = null
      this.alertLogs = []
    },
    renderAlertLog: function(alertLog) {
      const timeString = new Date(alertLog.timestamp * 1000).toLocaleString()
      const placeName = alertLog.place_name ? alertLog.place_name : ''
      const roomName = alertLog.room_name ? alertLog.room_name : ''
      if (alertLog.log_type === 'SET_MODE_ALERT') {
        return `${timeString} ${placeName} ${roomName} セットモードにて検知あり`
      } else if (alertLog.log_type === 'CARE_MODE_ALERT') {
        return `${timeString} ${placeName} ${roomName} ケアモードにて48時間動作無し`
      } else {
        return ''
      }
    },
    renderDate: function(timestamp) {
      if (!timestamp) {
        return ''
      }
      const date = new Date(timestamp * 1000)
      let year = date.getFullYear()
      let month = date.getMonth() + 1
      let day = date.getDate()
      return `${year}/${month}/${day}`
    },
    showFacilityDetail: function(facility) {
      this.facilityDetail = facility
    },
    closeFacilityDetail: function() {
      this.facilityDetail = null
    }
  }
}
</script>

<style scoped>
  @media all and (max-width: 580px) {
    .cont .btns {
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
    }
    .main-list {
      padding: 40px 0 0;
      overflow-x: scroll;
    }
    .main-list div.list-wrap {
      width: 180vw;
    }
    .main-list div.list-wrap::after {
      content: '← 横にスクロール →';
      display: block;
      width: 96vw;
      text-align: center;
      font-size: 3vw;
      padding: .5em 0;
    }
    .main-list h2 {
      font-size: 20px;
      font-weight: 300;
      color: rgb(180,120,60);
    }
    .main-list ul {
      display: flex;
      flex-flow: row wrap;
      font-size: 3.8vw;
      width: 180vw;
      background: rgb(255,255,255);
    }
    .main-list ul.tit {
      border-top: rgb(130,180,255) solid 1px;
      background: rgb(215,240,255);
    }
    .main-list ul li {
      padding: 1vw;
      text-align: center;
      display: flex;
      flex-flow: row wrap;
      justify-content: center;
      align-items: center;
    }
    .main-list ul .openclose {
      width: 10vw;
      border-left: rgb(130,180,255) solid 1px;
      border-right: rgb(130,180,255) solid 1px;
      border-bottom: rgb(130,180,255) solid 1px;
    }
    .main-list ul .openclose img {
      display: block;
      width: 7vw;
      height: 7vw;
      cursor: pointer;
      opacity: .6;
      transition: all  0.3s ease;
    }
    .main-list ul .openclose img:hover {
      opacity: 1;
    }
    .main-list ul .baseunit {
      width: 82vw;
      border-right: rgb(130,180,255) solid 1px;
      border-bottom: rgb(130,180,255) solid 1px;
    }
    .main-list .data .baseunit {
      justify-content: flex-start;
    }
    .main-list ul .state {
      width: 36vw;
      border-right: rgb(130,180,255) solid 1px;
      border-bottom: rgb(130,180,255) solid 1px;
    }
    .main-list ul.data {
      border-top: rgb(130,180,255) solid 1px;
      margin-top: 4vw;
    }
    .main-list ul.data .state {
      padding: 0;
    }
    .main-list ul .howmany {
      width: 30vw;
      border-right: rgb(130,180,255) solid 1px;
      border-bottom: rgb(130,180,255) solid 1px;
    }
    .main-list ul .linkdate {
      width: 33vw;
      border-right: rgb(130,180,255) solid 1px;
      border-bottom: rgb(130,180,255) solid 1px;
    }
    .main-list ul .log {
      width: 16vw;
      border-right: rgb(130,180,255) solid 1px;
      border-bottom: rgb(130,180,255) solid 1px;
    }
    .main-list ul.data .log {
      color: rgb(50,140,255);
    }
    .main-list ul .moreinfo {
      width: 100%;
      border-left: rgb(130,180,255) solid 1px;
      border-right: rgb(130,180,255) solid 1px;
      border-bottom: rgb(130,180,255) solid 1px;
      background: rgb(225, 235, 245);
    }
    .moreinfo ul {
      border-left: rgb(130,180,255) solid 1px;
      background: rgb(255, 255, 255);
    }
    .moreinfo ul li {
      border-right: rgb(130,180,255) solid 1px;
      border-bottom: rgb(130,180,255) solid 1px;
      padding: .5em .4em;
    }
    .moreinfo ul.tit {
      background: rgb(245, 250, 255);
    }
    .moreinfo .m-base {
      width: 32%;
    }
    .moreinfo .m-base label {
      display: block;
      width: 100%;
      text-align: left;
      cursor: pointer;
      background: url(@/assets/img/icon-click.svg) right bottom no-repeat;
      background-size: 20px 20px;
      color: rgb(50,140,255);
      transition: all  0.3s ease;
    }
    .moreinfo .m-base label:hover {
      color: rgb(25, 90, 170);
    }
    .moreinfo .m-modelnum {
      width: 16%;
    }
    .moreinfo .m-state {
      width: 20%;
      padding: 0;
    }
    .moreinfo .m-mode {
      width: 9%;
    }
    .moreinfo .m-extension {
      width: 9%;
    }
    .moreinfo .m-linkdate {
      width: 13%;
    }
    .moreinfo .link-edit {
      width: 14%;
    }
    .moreinfo .link-edit span {
      color: rgb(50,140,255);
      transition: all  0.3s ease;
      cursor: pointer;
    }
    .moreinfo .link-edit span:hover {
      color: rgb(25, 90, 170);
    }
    /* モードアイコン制御 */
    ul.data .mode-at-home p,
    ul.data .mode-monitoring p,
    ul.data .mode-go-out p {
      position: relative;
      width: 100%;
      height: 100%;
    }
    ul.data .mode-at-home p label,
    ul.data .mode-monitoring p label,
    ul.data .mode-go-out p label {
      display: none;
    }
    ul.data .mode-at-home p::before,
    ul.data .mode-monitoring p::before,
    ul.data .mode-go-out p::before {
      position: absolute;
      display: block;
      height: 100%;
      top: 0;
      left: 0;
      display: flex;
      padding-left: 2em;
      align-items: center;
    }
    ul.data .mode-monitoring p.activity-level-very-low::before {
      content: '検知レベル1';
      color: rgb(255,120,70);
      background: url(@/assets/img/icon-alert.svg) left .3em center no-repeat;
      background-size: 1.4em;
    }
    ul.data .mode-at-home p.activity-level-very-low::before {
      content: '検知レベル1';
      background: url(@/assets/img/icon-act-l01.svg) left .3em center no-repeat;
      background-size: 1.4em;
    }
    ul.data .mode-at-home p.activity-level-low::before,
    ul.data .mode-monitoring p.activity-level-low::before {
      content: '検知レベル2';
      background: url(@/assets/img/icon-act-l02.svg) left .3em center no-repeat;
      background-size: 1.4em;
    }
    ul.data .mode-at-home p.activity-level-medium::before,
    ul.data .mode-monitoring p.activity-level-medium::before {
      content: '検知レベル3';
      background: url(@/assets/img/icon-act-l03.svg) left .3em center no-repeat;
      background-size: 1.4em;
    }
    ul.data .mode-at-home p.activity-level-high::before,
    ul.data .mode-monitoring p.activity-level-high::before {
      content: '検知レベル4';
      background: url(@/assets/img/icon-act-l04.svg) left .3em center no-repeat;
      background-size: 1.4em;
    }
    ul.data .mode-at-home p.activity-level-very-high::before,
    ul.data .mode-monitoring p.activity-level-very-high::before {
      content: '検知レベル5';
      background: url(@/assets/img/icon-act-l05.svg) left .3em center no-repeat;
      background-size: 1.4em;
    }
    ul.data .mode-monitoring p.no-activity-alert::before {
      content: '要確認！';
      color: rgb(255,120,70);
      font-weight: bold;
      background: url(@/assets/img/icon-alert-red.svg) left .3em center no-repeat;
      background-size: 1.4em;
    }
    ul.data .mode-at-home p.activity-level-null::before,
    ul.data .mode-monitoring p.activity-level-null::before,
    ul.data .mode-go-out p.activity-level-null::before {
      content: '信号なし';
    }
    ul.data .mode-go-out p.activity-level-very-low::before {
      content: '検知なし';
      background: url(@/assets/img/icon-safe.svg) left .3em center no-repeat;
      background-size: 1.4em;
    }
    ul.data .mode-go-out p.activity-level-low::before,
    ul.data .mode-go-out p.activity-level-medium::before,
    ul.data .mode-go-out p.activity-level-high::before,
    ul.data .mode-go-out p.activity-level-very-high::before {
      content: '異常検知';
      color: rgb(255,120,70);
      font-weight: bold;
      background: url(@/assets/img/icon-alert.svg) left 5px center no-repeat;
      background-size: 30px;
    }
    ul.data .mode-monitoring p.activity-level-very-low,
    ul.data .mode-go-out p.activity-level-very-low,
    ul.data .mode-go-out p.activity-level-low,
    ul.data .mode-go-out p.activity-level-medium,
    ul.data .mode-go-out p.activity-level-high,
    ul.data .mode-go-out p.activity-level-very-high {
      padding: 0;
    }
    .log-list,
    .address-data {
      padding: 2em;
    }
    .log-list .log-tit,
    .address-data .address-tit {
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
      padding-bottom: 1em;
    }
    .log-list p {
      font-size: 3.8vw;
      padding-bottom: .5em;
    }
    .log-list p.note {
      padding-bottom: 1em;
    }
    .log-list ul {
      font-size: 3.8vw;
      overflow-y: scroll;
      max-height: 60vh;
    }
    .log-list ul li {
      padding: 0 0 .5em;
    }
    .address-data .data p {
      font-size: 3.8vw;
      padding-bottom: .5em;
    }
  }


  @media all and (min-width: 580px) {
    .cont .btns {
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
    }
    .main-list {
      padding: 40px 0 0;
    }
    .main-list h2 {
      font-size: 20px;
      font-weight: 300;
      color: rgb(180,120,60);
    }
    .main-list ul {
      display: flex;
      flex-flow: row wrap;
      font-size: 16px;
    }
    .main-list ul.tit {
      border-top: rgb(130,180,255) solid 1px;
      background: rgb(215,240,255);
    }
    .main-list ul li {
      padding: 10px;
      text-align: center;
      display: flex;
      flex-flow: row wrap;
      justify-content: center;
      align-items: center;
    }
    .main-list ul .openclose {
      width: 50px;
      border-left: rgb(130,180,255) solid 1px;
      border-right: rgb(130,180,255) solid 1px;
      border-bottom: rgb(130,180,255) solid 1px;
    }
    .main-list ul .openclose img {
      display: block;
      width: 30px;
      height: 30px;
      cursor: pointer;
      opacity: .6;
      transition: all  0.3s ease;
    }
    .main-list ul .openclose img:hover {
      opacity: 1;
    }
    .main-list ul .baseunit {
      width: 518px;
      border-right: rgb(130,180,255) solid 1px;
      border-bottom: rgb(130,180,255) solid 1px;
    }
    .main-list .data .baseunit {
      justify-content: flex-start;
    }
    .main-list ul .state {
      width: 150px;
      border-right: rgb(130,180,255) solid 1px;
      border-bottom: rgb(130,180,255) solid 1px;
    }
    .main-list ul.data {
      border-top: rgb(130,180,255) solid 1px;
      margin-top: 15px;
    }
    .main-list ul.data .state {
      padding: 0;
    }
    .main-list ul .howmany {
      width: 140px;
      border-right: rgb(130,180,255) solid 1px;
      border-bottom: rgb(130,180,255) solid 1px;
    }
    .main-list ul .linkdate {
      width: 130px;
      border-right: rgb(130,180,255) solid 1px;
      border-bottom: rgb(130,180,255) solid 1px;
    }
    .main-list ul .log {
      width: 70px;
      border-right: rgb(130,180,255) solid 1px;
      border-bottom: rgb(130,180,255) solid 1px;
    }
    .main-list ul.data .log {
      color: rgb(50,140,255);
      cursor: pointer;
      transition: .3s;
    }
    .main-list ul.data .log:hover {
      color: rgb(25, 90, 170);
    }
    .main-list ul .moreinfo {
      width: 100%;
      border-left: rgb(130,180,255) solid 1px;
      border-right: rgb(130,180,255) solid 1px;
      border-bottom: rgb(130,180,255) solid 1px;
      background: rgb(225, 235, 245);
    }
    .moreinfo ul {
      border-left: rgb(130,180,255) solid 1px;
      background: rgb(255, 255, 255);
    }
    .moreinfo ul li {
      border-right: rgb(130,180,255) solid 1px;
      border-bottom: rgb(130,180,255) solid 1px;
    }
    .moreinfo ul.tit {
      background: rgb(245, 250, 255);
    }
    .moreinfo .m-base {
      width: 350px;
    }
    .moreinfo .m-base label {
      display: block;
      width: 100%;
      text-align: left;
      cursor: pointer;
      background: url(@/assets/img/icon-click.svg) right bottom no-repeat;
      background-size: 20px 20px;
      color: rgb(50,140,255);
      transition: all  0.3s ease;
    }
    .moreinfo .m-base label:hover {
      color: rgb(25, 90, 170);
    }
    .moreinfo .m-modelnum {
      width: 145px;
    }
    .moreinfo .m-state {
      width: 150px;
      padding: 0;
    }
    .moreinfo .m-mode {
      width: 75px;
    }
    .moreinfo .m-extension {
      width: 85px;
    }
    .moreinfo .m-linkdate {
      width: 110px;
    }
    .moreinfo .link-edit {
      width: 110px;
    }
    .moreinfo .link-edit span {
      color: rgb(50,140,255);
      transition: all  0.3s ease;
      cursor: pointer;
    }
    .moreinfo .link-edit span:hover {
      color: rgb(25, 90, 170);
    }
    /* モードアイコン制御 */
    ul.data .mode-at-home p,
    ul.data .mode-monitoring p,
    ul.data .mode-go-out p {
      position: relative;
      width: 100%;
      height: 100%;
    }
    ul.data .mode-at-home p label,
    ul.data .mode-monitoring p label,
    ul.data .mode-go-out p label {
      display: none;
    }
    ul.data .mode-at-home p::before,
    ul.data .mode-monitoring p::before,
    ul.data .mode-go-out p::before {
      position: absolute;
      display: block;
      height: 100%;
      top: 0;
      left: 0;
      display: flex;
      padding-left: 40px;
      align-items: center;
    }
    ul.data .mode-monitoring p.activity-level-very-low::before {
      content: '検知レベル1';
      color: rgb(255,120,70);
      background: url(@/assets/img/icon-alert.svg) left 5px center no-repeat;
      background-size: 30px;
    }
    ul.data .mode-at-home p.activity-level-very-low::before {
      content: '検知レベル1';
      background: url(@/assets/img/icon-act-l01.svg) left 5px center no-repeat;
      background-size: 30px;
    }
    ul.data .mode-at-home p.activity-level-low::before,
    ul.data .mode-monitoring p.activity-level-low::before {
      content: '検知レベル2';
      background: url(@/assets/img/icon-act-l02.svg) left 5px center no-repeat;
      background-size: 30px;
    }
    ul.data .mode-at-home p.activity-level-medium::before,
    ul.data .mode-monitoring p.activity-level-medium::before {
      content: '検知レベル3';
      background: url(@/assets/img/icon-act-l03.svg) left 5px center no-repeat;
      background-size: 30px;
    }
    ul.data .mode-at-home p.activity-level-high::before,
    ul.data .mode-monitoring p.activity-level-high::before {
      content: '検知レベル4';
      background: url(@/assets/img/icon-act-l04.svg) left 5px center no-repeat;
      background-size: 30px;
    }
    ul.data .mode-at-home p.activity-level-very-high::before,
    ul.data .mode-monitoring p.activity-level-very-high::before {
      content: '検知レベル5';
      background: url(@/assets/img/icon-act-l05.svg) left 5px center no-repeat;
      background-size: 30px;
    }
    ul.data .mode-monitoring p.no-activity-alert::before {
      content: '要確認！';
      color: rgb(255,120,70);
      font-weight: bold;
      background: url(@/assets/img/icon-alert-red.svg) left 5px center no-repeat;
      background-size: 30px;
    }
    ul.data .mode-at-home p.activity-level-null::before,
    ul.data .mode-monitoring p.activity-level-null::before
    ul.data .mode-go-out p.activity-level-null::before {
      content: '信号なし';
    }
    ul.data .mode-go-out p.activity-level-very-low::before {
      content: '検知なし';
      background: url(@/assets/img/icon-safe.svg) left 5px center no-repeat;
      background-size: 30px;
    }
    ul.data .mode-go-out p.activity-level-low::before,
    ul.data .mode-go-out p.activity-level-medium::before,
    ul.data .mode-go-out p.activity-level-high::before,
    ul.data .mode-go-out p.activity-level-very-high::before {
      content: '異常検知';
      color: rgb(255,120,70);
      font-weight: bold;
      background: url(@/assets/img/icon-alert.svg) left 5px center no-repeat;
      background-size: 30px;
    }
    ul.data .mode-monitoring p.activity-level-very-low,
    ul.data .mode-go-out p.activity-level-very-low,
    ul.data .mode-go-out p.activity-level-low,
    ul.data .mode-go-out p.activity-level-medium,
    ul.data .mode-go-out p.activity-level-high,
    ul.data .mode-go-out p.activity-level-very-high {
      padding: 0;
    }
    .log-list,
    .address-data {
      padding: 20px;
    }
    .log-list .log-tit,
    .address-data .address-tit {
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
    }
    .log-list p {
      font-size: 16px;
      padding-bottom: .5em;
    }
    .log-list p.note {
      padding-bottom: 1em;
    }
    .log-list ul {
      font-size: 1.6rem;
      overflow-y: scroll;
      max-height: 50vh;
    }
    .log-list ul li {
      padding: 0 0 .5em;
    }
    .address-data .data {
      display: flex;
      flex-flow: row wrap;
      font-size: 1.6rem;
      padding: 1em 0;
    }
    .address-data .data p {
      padding-right: 1.6em;
    }
  }
</style>
