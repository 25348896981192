<template>
  <main class="idx">
    <header>
      <div class="hcont">
        <h1>WaveSense</h1>
      </div>
    </header>
    <div class="idx-login">
      <dl>
        <dt><img src="@/assets/img/logo-mark.svg" alt="AI Care" /></dt>
        <dd>
          <img src="@/assets/img/sl-logo-blue.svg" />
          <div class="message">
            <span v-if="this.$route.query.reset_password_success">パスワードがリセットされました</span>
            <span v-if="showLoginError">メールアドレスかパスワードが間違っています</span>
          </div>
          <div class="form-item">
            <label for="email">メールアドレス</label>
            <input id="email" placeholder="メールアドレス" autocomplete="off" type="text" v-model="email">
          </div>
          <div class="form-item">
            <label for="password">パスワード</label>
            <input id="password" placeholder="パスワード" autocomplete="off" type="password" v-model="password">
          </div>
          <div class="form-item">
            <button class="button" @click="handle()">ログイン</button>
          </div>
          <div class="form-item">
            <button class="button" @click="forgotPassword()">パスワードを忘れた</button>
          </div>
        </dd>
      </dl>
    </div>
  </main>
  <Footer />
</template>

<script>
import axios from 'axios'
import { apiGet } from '../api'
import Footer from './Footer'

export default {
  name: 'Login',
  components: {
    Footer,
  },
  data () {
    return {
      email: '',
      password: '',
      showLoginError: false,
    }
  },
  async mounted () {
    if (this.$router.app.$cookies.get('AccessToken') === null) {
      return
    }
    const userDetailResponse = await apiGet('/api/user_detail/me', this.$router.app.$cookies)
    if (!userDetailResponse.data) {
      return
    }
    this.redirectToTop(userDetailResponse.data.is_admin)
  },
  methods: {
    handle: async function() {
      try {
        const loginResponse = await axios.post('/api/auth/login', {
          email: this.email,
          password: this.password,
        })

        if (loginResponse.data.success) {
          this.$router.app.$cookies.config(60 * 60 * 24 * 30,'')
          this.$router.app.$cookies.set('AccessToken', loginResponse.data.accessToken)
          this.$router.app.$cookies.set('RefreshToken', loginResponse.data.refreshToken)
          if ('redirect' in this.$route.query) {
            location.href = this.$route.query.redirect
          } else {
            const userDetailResponse = await apiGet('/api/user_detail/me', this.$router.app.$cookies)
            this.redirectToTop(userDetailResponse.data.is_master)
          }
        } else {
          this.showLoginError = true
        }
      } catch(err) {
        console.error(err)
      }
    },
    forgotPassword: function() {
      location.href = '/forgot_password'
    },
    redirectToTop: function(isMaster) {
      if (isMaster) {
        location.href = '/admin'
      } else {
        location.href = '/top'
      }
    }
  }
}
</script>

<style scoped>
.form-item {
  margin: 0 auto;
  text-align: center;
}

label {
  display: block;
}
button {
  margin: 1em;
}
.hcont h1 {
  color: rgb(70, 150, 255);
}
@media all and (max-width: 580px) {
  .idx-login {
    text-align: center;
    padding: 20vw 0;
  }
  .idx-login dl dt {
    width: 40vw;
    margin: auto;
    padding-bottom: 2vw;
  }
  .idx-login dl dd {
    width: 90vw;
    margin: auto;
    font-size: 4vw;
    text-align: center;
  }
  .idx-login dl dd img {
    width: 50vw;
    padding-bottom: 4vw;
  }
  .idx-login dl dd div {
    padding: 1em 0 0;
  }
  .idx-login dl dd .form-item {
    text-align: center;
  }
  .idx-login dl dd .message span {
    display: block;
    text-align: center;
    padding: .5em;
    background: rgb(255, 230, 230);
    border-radius: 7px;
  }
  .idx-login dl dd input {
    width: 100%;
    margin: auto;
  }
  .idx-login .form-item input {
    font-size: 4vw;
    padding: .2em 0;
  }
  input {
    width: 50%;
    padding: .5em;
    font: inherit;
  }
}

@media all and (min-width: 580px) {
  .idx-login {
    text-align: center;
    padding: 4vw 0;
  }
  .idx-login dl dt {
    width: 170px;
    margin: auto;
    padding-bottom: 2vw;
  }
  .idx-login dl dd {
    width: 500px;
    margin: auto;
    font-size: 1vw;
    text-align: center;
  }
  .idx-login dl dd img {
    width: 290px;
    padding-bottom: 40px;
  }
  .idx-login dl dd div {
    padding: 1em 0 0;
  }
  .idx-login dl dd .form-item {
    text-align: center;
  }
  .idx-login dl dd .message span {
    display: block;
    text-align: center;
    padding: .5em;
    background: rgb(255, 230, 230);
    border-radius: 7px;
  }
  .idx-login dl dd input {
    width: 100%;
    margin: auto;
  }
  .idx-login .form-item input {
    font-size: 1.2vw;
    padding: .2em 0;
  }
  input {
    width: 50%;
    padding: .5em;
    font: inherit;
  }

}
</style>
